import { useCallback } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PracticeClaimsTable, {
  PracticeClaimsTableProps,
} from './PracticeClaimsTable';
import PracticeClaimsTableV2 from './PracticeClaimsTableV2';

const bodyStyle = StyleSheet.create({
  section: {
    padding: 40,
  },
  tableMarginBottom: {
    marginBottom: 48,
  },
});

type BodyProps = {
  claimTables: PracticeClaimsTableProps[];
};

const Body = ({ claimTables, version }: BodyProps & { version?: number }) => {
  const renderPracticeClaimsTable = useCallback(() => {
    if (version === 2) {
      return claimTables.map((t, i) => (
        <PracticeClaimsTableV2
          version={version}
          {...t}
          style={
            i < claimTables.length - 1 ? bodyStyle.tableMarginBottom : undefined
          }
        />
      ));
    }
    return claimTables.map((t, i) => (
      <PracticeClaimsTable
        version={version}
        {...t}
        style={
          i < claimTables.length - 1 ? bodyStyle.tableMarginBottom : undefined
        }
      />
    ));
  }, [claimTables, version]);

  return <View style={bodyStyle.section}>{renderPracticeClaimsTable()}</View>;
};

export default Body;
