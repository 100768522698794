import { gql } from '@apollo/client';

export const AppointmentsPerDateQuery = gql`
  query AppointmentsPerDateQuery(
    $practiceId: uuid!
    $fromDate: date!
    $toDate: date!
  ) {
    appointments: appointments_per_date(
      where: {
        practiceId: { _eq: $practiceId }
        date: { _gte: $fromDate, _lt: $toDate }
      }
    ) {
      date
    }
  }
`;

export const AllAppointmentsByDateQuery = gql`
  query AllAppointmentsByDateQuery(
    $searchQuery: String!
    $statuses: [String!]
    $from: timestamptz
    $to: timestamptz
    $types: [String!]
  ) {
    appointments(
      where: {
        status: { _in: $statuses }
        patient: {
          patientData: {
            _or: [
              { firstName: { _ilike: $searchQuery } }
              { lastName: { _ilike: $searchQuery } }
            ]
          }
        }
        _and: [{ time: { _gte: $from } }, { time: { _lte: $to } }]
      }
      limit: 1000
      offset: 0
      order_by: { time: asc }
    ) {
      id
      status
      time
      metadata
      patient {
        id
        mrn
        patientData {
          id
          firstName
          lastName
          sex
          birthdate
        }
        insurances(where: { primary: { _eq: true } }) {
          id
          verificationStatus
        }
        todaysVaccinations: vaccinations(
          where: { givenAt: { _gte: $from, _lte: $to } }
        ) {
          vaccine {
            name
          }
          inventory {
            lot
          }
        }
        vaccinations: vaccinations(
          where: { vaccine: { types: { _has_keys_any: $types } } }
          order_by: { givenAt: asc }
        ) {
          id
          givenAt
          vaccine {
            id
            types
          }
        }
      }
    }
  }
`;

export const AppointmentsQuery = gql`
  query AppointmentsQuery(
    $searchQuery: String!
    $statuses: [String]
    $from: timestamptz
    $to: timestamptz
    $limit: Int!
    $offset: Int!
  ) {
    aggregating: appointments_aggregate(
      where: {
        status: { _in: $statuses }
        patient: {
          patientData: {
            _or: [
              { firstName: { _ilike: $searchQuery } }
              { lastName: { _ilike: $searchQuery } }
            ]
          }
        }
        _and: [{ time: { _gte: $from } }, { time: { _lte: $to } }]
      }
    ) {
      aggregate {
        count
      }
    }

    appointments(
      where: {
        status: { _in: $statuses }
        patient: {
          patientData: {
            _or: [
              { firstName: { _ilike: $searchQuery } }
              { lastName: { _ilike: $searchQuery } }
            ]
          }
        }
        _and: [{ time: { _gte: $from } }, { time: { _lte: $to } }]
      }
      limit: $limit
      offset: $offset
      order_by: { time: asc }
    ) {
      id
      status
      time
      patient {
        id
        mrn
        patientData {
          id
          firstName
          lastName
          sex
          birthdate
        }
      }
    }
  }
`;

export const PracticePatientAppointmentsQuery = gql`
  query PracticePatientAppointmentsQuery($practicePatientId: uuid!) {
    appointments(
      where: {
        patient: { id: { _eq: $practicePatientId } }
        # parentId: { _is_null: true }
      }
      order_by: { time: desc }
    ) {
      id
      time
      status
      # childrenAppointments(order_by: { time: desc }) {
      #   id
      #   time
      #   status
      # }
    }
  }
`;

export const PracticePatientFutureAppointmentsQuery = gql`
  query PracticePatientFutureAppointmentsQuery($practicePatientId: uuid!) {
    appointments(
      where: {
        practicePatientId: { _eq: $practicePatientId }
        time: { _gte: "today" }
      }
      limit: 1
    ) {
      id
      time
      status
    }
  }
`;

export const InsertAppointmentMutation = gql`
  mutation InsertAppointmentMutation(
    $practicePatientId: uuid!
    $time: timestamptz!
    $metadata: jsonb
  ) {
    appointment: insert_appointments_one(
      object: {
        practicePatientId: $practicePatientId
        time: $time
        metadata: $metadata
      }
    ) {
      id
      time
      status
      childrenAppointments(order_by: { time: desc }) {
        id
        time
        status
      }
    }
  }
`;

export const UpdateAppointmentMutation = gql`
  mutation UpdateAppointmentMutation(
    $id: uuid!
    $time: timestamptz
    $status: String
    $metadata: jsonb
  ) {
    appointment: update_appointments_by_pk(
      _set: { time: $time, status: $status, metadata: $metadata }
      pk_columns: { id: $id }
    ) {
      id
      time
      status
    }
  }
`;

export const UpdateAppointmentStateMutation = gql`
  mutation UpdateAppointmentStateMutation($id: uuid!, $status: String!) {
    appointment: update_appointments_by_pk(
      _set: { status: $status }
      pk_columns: { id: $id }
    ) {
      id
      status
    }
  }
`;

export const DeleteAppointmentMutation = gql`
  mutation DeleteAppointmentMutation($id: uuid!) {
    delete_appointments_by_pk(id: $id) {
      id
    }
  }
`;
