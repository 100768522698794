import { gql } from '@apollo/client';

export const ClaimMapping = gql`
  fragment ClaimMapping on billing_claim_mappings {
    id
    enabled
    cpid
    type
  }
`;

export const BillingStrategyMappingsQuery = gql`
  query BillingStrategyMappingsQuery(
    $practiceId: uuid!
    $strategy: String
    $limit: Int!
    $offset: Int!
    $criteria: [billing_claim_mappings_bool_exp!]
  ) {
    aggregating: billing_claim_mappings_aggregate(
      where: {
        strategy: { _eq: $strategy }
        practiceId: { _eq: $practiceId }
        type: { _eq: npi_taxid }
        _and: $criteria
      }
    ) {
      aggregate {
        count
      }
    }
    billingStrategies: billing_claim_mappings(
      where: {
        practiceId: { _eq: $practiceId }
        strategy: { _eq: $strategy }
        type: { _eq: npi_taxid }
        _and: $criteria
      }
      limit: $limit
      offset: $offset
      order_by: [
        { practiceAccount: { account: { firstName: asc } } }
        { cpid: asc }
        { enabled: desc_nulls_first }
        { createdAt: desc }
      ]
    ) {
      id
      practiceAccount {
        account {
          firstName
          lastName
        }
      }
      payerId
      taxId
      npi
      cpid
      strategy
      insuranceCompanyId
      providerId
      insuranceCompany {
        name
        payerId
      }
      enabled
    }
  }
`;

export const InsuranceMappingByCompanyId = (
  forceInsuranceCompanyId: boolean = false
) => gql`
  query InsuranceMappingByCompanyId(
    $insuranceCompanyId: uuid!
    $practiceId: uuid!
  ) {
    insurances: mapping_insurances(
      where: {
        insuranceCompanyId: { _eq: $insuranceCompanyId }
        practiceId: { _eq: $practiceId }
      }
      order_by: { createdAt: desc }
      limit: 1
    ) {
      insuranceCompanyForBilling {
        id
        name
        payerId
      }
      box31(args:{force_insurance_company_id:${forceInsuranceCompanyId}}) {
        ...ClaimMapping
      }
      ndcDossage(args:{force_insurance_company_id:${forceInsuranceCompanyId}}) {
        ...ClaimMapping
      }
      vfcWithInventoryAmount(args:{force_insurance_company_id:${forceInsuranceCompanyId}}) {
        ...ClaimMapping
      }
      decoupledCpts(args:{force_insurance_company_id:${forceInsuranceCompanyId}}) {
        ...ClaimMapping
      }
    }
  }
    fragment ClaimMapping on billing_claim_mappings {
        id
        enabled
        cpid
        type
      }
`;

export const GetBillingStrategyIndividualBillingRenderingProvider = gql`
  query GetBillingStrategyIndividualBillingRenderingProvider(
    $criteria: billing_claim_mappings_bool_exp = {}
  ) {
    mappings: billing_claim_mappings(where: $criteria) {
      id
      enabled
      type
    }
  }
`;
