import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Confirm,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
  Input,
  Popup,
  Message,
  Form,
  Modal,
  Button,
  Dropdown,
  Table,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import {
  PrimaryInsuranceQuery,
  InsertInsurancesForPatientFromExtensionMutation,
  UpdatePrimaryInsuranceVfc,
  InsertInsuranceMutation,
  InsurancesQuery,
  PrimaryInsurancesByPatientCount,
} from '@bluefox/graphql/insurances';

import {
  VfcConfirmedMutation,
  SaveConsentForIisMutation,
  PracticePatientProfileQuery,
} from '@bluefox/graphql/patients';

import {
  InsertVacciationsMutation,
  VaccinationsByTypeQuery,
  VaccinationsByPatientQuery,
  GivenVaccinationsQuery,
  VaccinationsByPracticePatientQuery,
  VaccinationDatesByPracticeQuery,
} from '@bluefox/graphql/vaccinations';

import {
  Insurance,
  VfcCriteria,
  VFC_OPTIONS,
  InsuranceRelationShips,
} from '@bluefox/models/Insurances';

import {
  ScannableItem,
  ScannableItemsContextProvider,
  useScannableItems,
} from '@bluefox/store/scannableItems';
import { RoutineTypes, VACCINE_TYPES } from '@bluefox/store/vaccines';

import DateTimePicker from '@bluefox/ui/DateTimePicker';
import GS1GunScanner from '@bluefox/ui/GS1GunScanner';

import ScannedItems from '@components/ScannedItems';
import ProviderPicker from '@bluefox/ui/ProviderPicker';

import {
  usePractice,
  useSession,
  useApplicationState,
} from '@bluefox/contexts';
import {
  Vaccination,
  VaccinationSites,
  GivenVaccination,
  VaccinationRoutes,
  VaccinationInput,
} from '@bluefox/models/Vaccination';

import moment from 'moment-timezone';
import { Link, useHistory } from 'react-router-dom';
import { usePracticePatient } from '@bluefox/contexts/PracticePatientProvider';
import { getAge } from '@bluefox/lib/getAge';
import { DateFormats } from '@bluefox/models/Dates';
import PatientEligibilityScreeningRecordPdf from '@components/celebrationPediatrics/PatientEligibilityScreeningRecordPdf';
import { Vaccine } from '@bluefox/models/Vaccine';
import { Inventory } from '@bluefox/models/Inventory';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { PracticeSettings } from '@bluefox/models/Practice';
import { VFC_ENROLEMENT_YEARS_LESS_THAN } from '@bluefox/constants';
import { UserTitles } from '@bluefox/models/Users';
import { humanizeText } from '@bluefox/lib/humanize';
import InsurancesForm from '@bluefox/ui/InsurancesForm';
import { vaccinationSiteParser } from '@bluefox/lib/vaccinationSiteParser';
import InsuranceCompanyPicker from '@components/InsuranceCompanyPicker';

interface SettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

export type ScannedVacinationConsentPDF = {
  vaccine: Vaccine | undefined;
  inventory?: Inventory | undefined;
  givenAt: Date;
  providerId: string | undefined;
  site: VaccinationSites | null | undefined;
  route: VaccinationRoutes | null | undefined;
};

export type ScannedVaccinationsConsentPDF = {
  vaccinations: ScannedVacinationConsentPDF[];
};

interface InsurancesData {
  insurancesData: {
    insurances: Insurance[];
    isVfcConfirmed: boolean;
  };
}

interface GivenVaccinationData {
  vaccinations: GivenVaccination[];
}

interface InsuranceId {
  id: string;
}

interface InsurancesCount {
  insurances: InsuranceId[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface ScannerProps {
  onFinish: () => void;
  getTotalEntries: (entries: number) => void;
  setIsFinished: (finished: boolean) => void;
  pageBlurred: boolean;
  onInsuranceSave?: () => void;
  onInsuranceVfcConditionChange?: (vfc: boolean) => void;
}

type StyledMessageProps = {
  margin?: string;
};

const validPrescribers = [
  UserTitles.MD,
  UserTitles.DO,
  UserTitles.PA,
  UserTitles.NP,
];

const Wrapper = (props: ScannerProps) => {
  return (
    <ScannableItemsContextProvider>
      <Scanner {...props} />
    </ScannableItemsContextProvider>
  );
};

const convertToVaccination = ({
  practicePatientId,
  providerId,
  prescriberId,
  givenAt,
  item: {
    code,
    inventory,
    vaccine,
    vaccinationSite,
    vaccinationRoute,
    vaccinationVisDate,
    vaccinationDose,
    warnings,
    associatedItems,
  },
}: {
  practicePatientId?: string;
  providerId?: string;
  prescriberId?: string;
  givenAt: Date;
  item: ScannableItem;
}): VaccinationInput => {
  const [inventoryEntry] = inventory || [];

  const vaccineId =
    (inventoryEntry && inventoryEntry.vaccineId) || vaccine?.id || undefined;

  const inventoryId = (inventoryEntry && inventoryEntry.id) || undefined;

  const secondaryVaccinations = !!associatedItems
    ? {
        data: Object.values(associatedItems).map((i) =>
          convertToVaccination({
            practicePatientId,
            providerId,
            prescriberId,
            givenAt,
            item: i,
          })
        ),
      }
    : undefined;

  return {
    practicePatientId,
    vaccineId,
    inventoryId,
    barcode: code,
    givenAt,
    visDate: vaccinationVisDate || givenAt,
    providerId,
    ...(prescriberId ? { prescribedBy: prescriberId } : {}),
    site:
      vaccinationRoute === VaccinationRoutes.PO ||
      vaccinationRoute === VaccinationRoutes.IN
        ? null
        : vaccinationSite,
    route: vaccinationRoute,
    dose: vaccinationDose,
    secondaryVaccinations,
    ...(warnings && warnings.length ? { warnings } : {}),
  };
};

const Scanner = ({
  onFinish,
  getTotalEntries,
  setIsFinished,
  pageBlurred,
  onInsuranceVfcConditionChange,
  onInsuranceSave,
}: ScannerProps) => {
  const { isEmbedded } = useApplicationState();
  const { account } = useSession();

  const practice = usePractice();
  const practicePatient = usePracticePatient();
  const patient = practicePatient?.patientData;
  const practicePatientId = practicePatient?.id;

  const [scanning, setScanning] = useState(false);
  const [finished, setFinished] = useState(false);
  const [disableFinishBtn, setDisableFinishBtn] = useState(false);
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<string>();
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<string>();
  const [insuranceMemberId, setInsuranceMemberId] = useState<string>();

  const [conflictingInventory, setConflictingInventory] = useState(false);
  const [conflictingVfc, setConflictingVfc] = useState(false);
  const [hasDuplicated, setHasDuplicated] = useState(false);
  const [existExpiredVaccine, setExistExpiredVaccine] = useState(false);
  const [vaccineWithoutVisDate, setVaccineWithoutVisDate] = useState(false);

  const [showVfcConfirm, setShowVfcConfirm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  const [givenAt, setGivenAt] = useState<Date>(new Date());
  const [providerId, setProviderId] = useState<string | undefined>(
    account?.title !== 'Other' ? account?.id : undefined
  );

  const [providerFullName, setProviderFullName] = useState<string>();

  const isValidPrescriber = useMemo(() => {
    if (account?.profile?.permissionAs) {
      return account?.profile?.permissionAs?.prescriber;
    }

    return validPrescribers.includes(account?.title as UserTitles);
  }, [account?.profile?.permissionAs, account?.title]);

  const [prescriberId, setPrescriberId] = useState<string | undefined>(
    isValidPrescriber ? account?.id : undefined
  );

  const [vfc, setVfc] = useState<boolean>(false);
  const patientDob = patient ? new Date(patient.birthdate) : undefined;

  const [vfcChecked, setVfcChecked] = useState(false);
  const [openVfcConfirm, setOpenVfcConfirm] = useState(false);
  const [testValue, setTestValue] = useState('');

  const [showConfirmConsentForIis, setShowConfirmConsentForIis] =
    useState(false);
  const [openVfcCriteriaModal, setOpenVfcCriteriaModal] = useState(false);
  const [selectedVfcCriteria, setSelectedVfcCriteria] = useState<
    VfcCriteria | undefined
  >();
  const [isVfc, setIsVfc] = useState(false);

  const patientAge = getAge(patientDob);
  const minVaxDate = moment(patientDob, DateFormats.DATE)
    .add(1, 'days')
    .toDate();

  const { data: practiceSettings } = useQuery<SettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practice.id,
      },
    }
  );

  const { data: insuranceQueryData } = useQuery<InsurancesData>(
    PrimaryInsuranceQuery,
    {
      variables: {
        practicePatientId,
      },
      skip: !practicePatientId,
      onCompleted: (data) => {
        if (!data.insurancesData.insurances.length && patientAge < 19) {
          setSelectedVfcCriteria(VfcCriteria.uninsured);
        } else {
          setSelectedVfcCriteria(
            data.insurancesData.insurances[0]?.vfcCriteria
          );
        }
      },
    }
  );

  const [getGivenVaccinations, { data: givenVaccinations }] =
    useLazyQuery<GivenVaccinationData>(GivenVaccinationsQuery);

  useEffect(() => {
    // use the most recent insurance
    const mostRecentInsurance =
      insuranceQueryData?.insurancesData?.insurances?.[0];

    // If the patient has an insurance, use the vfcEligible from the insurance
    if (mostRecentInsurance) {
      setVfc(mostRecentInsurance.vfcEligible || false);
    } else {
      // if the patient don't have any insurance and they are is less than 19 years old is vfc eligible. Otherwise, the patient is private.
      setVfc(patientAge < VFC_ENROLEMENT_YEARS_LESS_THAN);
    }
  }, [insuranceQueryData?.insurancesData?.insurances, patientAge]);

  const {
    entries,
    entriesRecord,
    addEntry,
    cleanEntries,
    loading,
    setDefaultVaccinationSite,
    setDefaultVaccinationRoute,
    setDefaultVaccinationVisDate,
    changeVaccinationVisDate,
  } = useScannableItems();

  useEffect(() => {
    const months =
      patient && patient.birthdate
        ? moment().diff(patient.birthdate, 'months', true)
        : 0;

    setDefaultVaccinationRoute(VaccinationRoutes.IM);

    setDefaultVaccinationSite(
      months > 36 ? VaccinationSites.rightArm : VaccinationSites.rightThigh
    );

    setDefaultVaccinationVisDate(new Date());
  }, [
    patient,
    setDefaultVaccinationRoute,
    setDefaultVaccinationSite,
    setDefaultVaccinationVisDate,
  ]);

  useEffect(() => {
    entries.forEach((entry) => {
      changeVaccinationVisDate(entry._id, givenAt);
    });
  }, [givenAt]);

  const [insertVaccinations, insertVaccinationsResult] = useMutation<{
    vaccinations: { affected_rows: number; returning: Vaccination[] };
  }>(InsertVacciationsMutation);

  const [confirmVfc, { called: confirmVfcCalled }] =
    useMutation(VfcConfirmedMutation);

  const [saveVfcForPatientFromExtension] = useMutation(
    InsertInsurancesForPatientFromExtensionMutation
  );

  const [savePrimaryInsuranceVfc] = useMutation(UpdatePrimaryInsuranceVfc);

  const [saveConsentForIis] = useMutation(SaveConsentForIisMutation);

  const [saveInsurance] = useMutation<Insurance>(
    InsertInsuranceMutation(
      practicePatient?.insurances.some((insurance) => insurance.primary)
    )
  );

  const [
    getPrimaryInsurance,
    { data: insurancesDataPerUser, refetch: refetchCount },
  ] = useLazyQuery<InsurancesCount>(PrimaryInsurancesByPatientCount);

  useEffect(() => {
    if (!openVfcCriteriaModal) return;

    getPrimaryInsurance({
      variables: {
        practicePatientId,
      },
    });
  }, [openVfcCriteriaModal]);

  const primaryInsuranceId = insurancesDataPerUser?.insurances.map((i) => i.id);

  const checkForDuplicatedTypes = (
    items: (ScannableItem | GivenVaccination)[]
  ) => {
    const types = items.reduce<string[]>(
      (acc, i) => [...acc, ...(i.vaccine?.types ?? [])],
      []
    );

    return types.some((type, index) => types.indexOf(type) !== index);
  };

  const handleSaveInsurance = async () => {
    if (
      selectedVfcCriteria ===
        insuranceQueryData?.insurancesData.insurances[0]?.vfcCriteria &&
      insuranceCompanyId ===
        insuranceQueryData?.insurancesData.insurances[0]?.company?.id &&
      insuranceMemberId ===
        insuranceQueryData?.insurancesData.insurances[0]?.memberId &&
      openVfcCriteriaModal ===
        insuranceQueryData?.insurancesData.insurances[0]?.vfcEligible
    ) {
      confirmVfc({ variables: { practicePatientId } });
      setVfcChecked(true);
      setOpenVfcConfirm(false);
      return;
    }

    try {
      await saveInsurance({
        variables: {
          practicePatientId,
          vfcEligible: true,
          vfcCriteria: selectedVfcCriteria,
          active: true,
          isVfcConfirmed: true,
          insuranceCompanyId: insuranceCompanyId,
          memberId: insuranceMemberId,
          relationship: InsuranceRelationShips.Self,
          oldPrimaryId: primaryInsuranceId,
        },
        refetchQueries: [
          {
            query: InsurancesQuery,
            variables: {
              practicePatientId,
            },
          },
          {
            query: PrimaryInsurancesByPatientCount,
            variables: {
              practicePatientId,
            },
          },
        ],
      });
      setOpenVfcConfirm(false);
    } catch (error) {
      toast({
        title: 'Error on saving insurance',
        type: 'error',
        time: 5000,
      });
    }
  };

  const handleSavePrimaryInsuranceVfc = async (
    insuranceId: string,
    vfcEligible: boolean,
    vfcCriteria: string | null,
    insuranceCompanyId?: string,
    insuranceMemberId?: string
  ) => {
    try {
      await savePrimaryInsuranceVfc({
        variables: {
          insuranceId,
          vfcEligible,
          vfcCriteria,
          insuranceCompanyId,
          insuranceMemberId,
        },
        refetchQueries: [
          {
            query: InsurancesQuery,
            variables: {
              practicePatientId,
            },
          },
        ],
      });
      setVfcChecked(true);
      setOpenVfcConfirm(false);

      toast({
        title: 'Insurance updated successfully.',
        type: 'success',
        time: 3000,
      });
    } catch (error) {
      toast({
        title: `Error updating insurance. ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  const handleSaveVfcForPatientFromExtension = (
    practicePatientId: string,
    vfcEligible: boolean,
    vfcCriteria: string | null
  ) => {
    saveVfcForPatientFromExtension({
      variables: {
        practicePatientId,
        vfcEligible,
        vfcCriteria,
      },
    }).then(() => {
      setVfcChecked(true);
      setOpenVfcConfirm(false);
    });
  };

  const handleConfirmVfc = (practicePatientId: string, isSelfPay = false) => {
    confirmVfc({
      variables: {
        practicePatientId,
        isSelfPay,
      },
      refetchQueries: [
        {
          query: PracticePatientProfileQuery,
          variables: {
            id: practicePatientId,
          },
        },
      ],
    }).then(() => {
      setVfcChecked(true);
      setOpenVfcConfirm(false);
    });
  };

  const handleSaveConsentForIis = (
    practicePatientId: string,
    consentForIis: boolean
  ) => {
    saveConsentForIis({
      variables: {
        practicePatientId,
        consentForIis,
      },
      refetchQueries: [
        {
          query: PracticePatientProfileQuery,
          variables: {
            id: practicePatientId,
          },
        },
      ],
    }).then(() => {
      toast({
        title: 'Consent for IIS saved successfully.',
        type: 'success',
        time: 3000,
      });
      setShowConfirmConsentForIis(false);
    });
  };

  const handleFinishOnClick = () => {
    if (practicePatient?.consentForIis === null && patientAge >= 19) {
      setShowConfirmConsentForIis(true);
      return;
    }

    if (providerId && prescriberId && givenAt && !vaccineWithoutVisDate) {
      setDisableFinishBtn(true);
    }

    if (!!entries.length) {
      if (!providerId) {
        toast({
          title: 'Please select an authorized provider.',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      if (!prescriberId) {
        toast({
          title: 'Please select an authorized prescriber.',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      if (!givenAt) {
        toast({
          title: 'Please select a valid Service Date',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      if (vaccineWithoutVisDate) {
        toast({
          title: 'Please make sure that all vaccinations have VIS Date',
          type: 'warning',
          time: 3000,
        });
        return;
      }

      if (conflictingVfc) {
        setShowVfcConfirm(true);
        return;
      }
    }

    finish();
  };

  const finish = useCallback(() => {
    setShowVfcConfirm(false);
    const vaccinations = entries.map((item) => {
      return convertToVaccination({
        practicePatientId,
        providerId,
        prescriberId,
        givenAt,
        item,
      });
    });

    insertVaccinations({
      variables: {
        vaccinations,
      },
      refetchQueries: [
        {
          query: VaccinationsByPatientQuery,
          variables: {
            practicePatientId,
          },
        },
        {
          query: VaccinationsByPracticePatientQuery,
          variables: {
            practicePatientId,
          },
        },
        {
          query: VaccinationsByTypeQuery,
          variables: {
            practicePatientId,
            types: RoutineTypes,
          },
        },
        {
          query: VaccinationsByTypeQuery,
          variables: {
            practicePatientId,
            types: VACCINE_TYPES,
          },
        },
        {
          query: VaccinationDatesByPracticeQuery,
          variables: {
            practiceId: practice.id,
          },
        },
      ],
    })
      .then(() => {
        setFinished(true);
        onFinish();
        setDisableFinishBtn(false);
      })
      .catch((error) => {
        setFinished(false);
        toast({
          title:
            'Something went wrong on the scanning process, please check your inventory',
          type: 'error',
          description: error,
          time: 5000,
        });
      });
  }, [
    entries,
    givenAt,
    insertVaccinations,
    onFinish,
    practicePatientId,
    prescriberId,
    providerId,
  ]);

  const handleCancelClick = () => {
    cleanEntries();
    setShowCancelConfirm(false);
    setProviderId(account?.title !== 'Other' ? account?.id : undefined);
  };

  const handleRestartClick = () => {
    cleanEntries();
    setFinished(false);
    setProviderId(account?.title !== 'Other' ? account?.id : undefined);
  };

  const canFinish = !(
    finished ||
    scanning ||
    conflictingInventory ||
    hasDuplicated ||
    existExpiredVaccine ||
    !entries.length
  );

  const canCancel = scanning || !entries.length;

  useEffect(() => {
    if (
      insertVaccinationsResult.data &&
      insertVaccinationsResult.data.vaccinations.affected_rows > 0
    ) {
      setFinished(true);
      toast({
        title: 'Success',
        type: 'success',
        description: 'Immunization logged!',
        time: 5000,
      });
    }
  }, [insertVaccinationsResult.data]);

  useEffect(() => {
    const allEntries = Object.values(entriesRecord);
    setConflictingInventory(
      allEntries.some((e) => e.inventory && e.inventory.length > 1)
    );
    setConflictingVfc(
      allEntries.some((e) => e.inventory?.some((i) => i.vfc !== vfc))
    );
  }, [entriesRecord, vfc]);

  useEffect(() => {
    const allEntries = Object.values(entriesRecord);
    getTotalEntries(allEntries.length);

    const vaccineWithoutVisDate = allEntries.some(
      (entry) => !entry.vaccinationVisDate
    );

    setVaccineWithoutVisDate(vaccineWithoutVisDate);

    const duplicated = checkForDuplicatedTypes([
      ...entries,
      ...(givenVaccinations?.vaccinations ?? []),
    ]);

    setHasDuplicated(duplicated);
  }, [entries, entriesRecord, givenVaccinations, getTotalEntries]);

  useEffect(() => {
    if (!practicePatient || !givenAt) return;

    getGivenVaccinations({
      variables: {
        practicePatientId: practicePatient.id,
        givenAtFrom: moment(givenAt).startOf('day'),
        givenAtTo: moment(givenAt).endOf('day'),
      },
    });
  }, [practicePatient, givenAt, getGivenVaccinations, finished]);

  useEffect(() => {
    const allEntries = Object.values(entriesRecord);
    if (allEntries.length === 0 || !givenAt) {
      return;
    }

    const expired = allEntries.reduce((isExpired, entry) => {
      if (isExpired) {
        return true;
      }
      const parsedGivenAt = moment(givenAt).startOf('day');
      const parsedExp = moment(entry.gs1?.exp).startOf('day');
      const diff = parsedExp.diff(parsedGivenAt, 'days');
      return diff < -1;
    }, false);

    setExistExpiredVaccine(expired);
  }, [entriesRecord, givenAt]);

  useEffect(() => {
    setIsFinished(finished);
  }, [setIsFinished, finished]);

  useEffect(() => {
    if (!practicePatient || !confirmVfcCalled) return;
    setVfcChecked(!!practicePatient?.isVfcConfirmed);
  }, [practicePatient, confirmVfcCalled]);

  useEffect(() => {
    if (!practicePatient?.insurances.length) return;

    setInsuranceCompanyId(practicePatient.insurances[0].company?.id);
    setInsuranceMemberId(practicePatient.insurances[0].memberId);
  }, [practicePatient]);

  useEffect(() => {
    if (
      selectedVfcCriteria === VfcCriteria.uninsured ||
      selectedVfcCriteria === VfcCriteria.unknown
    ) {
      setInsuranceCompanyId(undefined);
      setInsuranceMemberId(undefined);
    }
  }, [selectedVfcCriteria]);

  // useEffect(() => {
  //   if (!insuranceQueryData) return;
  //   setSelectedVfcCriteria(
  //     insuranceQueryData.insurancesData.insurances[0].vfcCriteria
  //   );
  // }, [insuranceQueryData]);

  const gunScanner = (
    <GS1GunScanner
      style={{
        display: 'flex',
        width: '15rem',
        justifyContent: 'center',
        fontSize: '20px',
      }}
      testValue={testValue}
      disabled={finished}
      component={Menu.Item}
      isScanning={vfcChecked}
      renderOn={
        <>
          <Icon name={loading ? 'spinner' : 'pause'} loading={loading} />
          {loading ? 'Loading' : 'Scanning'}
        </>
      }
      renderReading={
        <>
          <Icon name="spinner" loading />
          Reading
        </>
      }
      renderOff={
        <>
          <Icon name="play" />
          Scan
        </>
      }
      onScanningChange={(s) => setScanning(s)}
      onGS1Scan={(_, value) => {
        if (!value) return;
        addEntry(value);
      }}
      onError={() =>
        toast({
          title: 'Unable to process code',
          description:
            "There was a problem trying to process the scanned QR, please make sure you're scanning the right one and try again.",
          color: 'red',
          time: 5000,
        })
      }
    />
  );

  const allEntries = Object.values(entriesRecord);

  return (
    <>
      <Grid.Column>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Menu compact>
            <input
              size={1}
              style={{
                position: 'absolute',
                opacity: 0,
              }}
              data-automation-id={`test-value`}
              value={testValue}
              onChange={(e) => {
                setTestValue(e.target.value);
              }}
            />
            <Menu.Menu position="right" style={{ height: '6rem' }}>
              {vfcChecked || practicePatient?.isVfcConfirmed ? (
                gunScanner
              ) : practicePatient?.patientData.fromExtension ? (
                <Popup
                  trigger={
                    <Menu.Item
                      data-automation-id={`patient-scan-button`}
                      style={{
                        display: 'flex',
                        width: '15rem',
                        justifyContent: 'center',
                        fontSize: '20px',
                      }}
                    >
                      <Icon name="play" />
                      Scan
                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                        }}
                        data-automation-id={`test-value`}
                        value={testValue}
                        onChange={(e) => {
                          setTestValue(e.target.value);
                        }}
                      />
                    </Menu.Item>
                  }
                  content={
                    <div>
                      <h3>Is this a VFC patient?</h3>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          size="tiny"
                          color="olive"
                          content="Yes"
                          onClick={() => {
                            handleConfirmVfc(
                              practicePatient ? practicePatient.id : ''
                            );
                            if (!practicePatient.insurances.length) {
                              handleSaveVfcForPatientFromExtension(
                                practicePatient ? practicePatient.id : '',
                                true,
                                'medicaid'
                              );
                            } else {
                              setOpenVfcCriteriaModal(true);
                              setOpenVfcConfirm(false);
                            }
                          }}
                        />
                        <Button
                          size="tiny"
                          content="No"
                          onClick={() => {
                            handleConfirmVfc(
                              practicePatient ? practicePatient.id : ''
                            );
                            if (!practicePatient.insurances.length) {
                              handleSaveVfcForPatientFromExtension(
                                practicePatient ? practicePatient.id : '',
                                false,
                                null
                              );
                            } else {
                              handleSavePrimaryInsuranceVfc(
                                practicePatient
                                  ? practicePatient.insurances[0].id
                                  : '',
                                false,
                                null,
                                insuranceCompanyId,
                                insuranceMemberId
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  }
                  on="click"
                  open={openVfcConfirm}
                  onClose={() => setOpenVfcConfirm(false)}
                  onOpen={() => setOpenVfcConfirm(true)}
                  position="left center"
                />
              ) : patientAge < 19 ||
                (patientAge >= 19 &&
                  !!practicePatient?.insurances.length &&
                  practicePatient.insurances[0].vfcEligible) ? (
                <Popup
                  trigger={
                    <Menu.Item
                      data-automation-id={`patient-scan-button`}
                      style={{
                        display: 'flex',
                        width: '15rem',
                        justifyContent: 'center',
                        fontSize: '20px',
                      }}
                    >
                      <Icon name="play" />
                      Scan
                    </Menu.Item>
                  }
                  content={
                    <div>
                      <h3>Is this a VFC patient?</h3>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          size="tiny"
                          color="olive"
                          content="Yes"
                          onClick={() => {
                            setIsVfc(true);
                            setOpenVfcCriteriaModal(true);
                            setOpenVfcConfirm(false);
                          }}
                        />
                        <Button
                          size="tiny"
                          content="No"
                          onClick={() => {
                            if (
                              patientAge < 19 &&
                              !practicePatient?.insurances.length
                            ) {
                              setOpenVfcConfirm(false);
                              setSelectedInsuranceId('_new');
                            } else {
                              handleConfirmVfc(
                                practicePatient ? practicePatient.id : ''
                              );
                              handleSavePrimaryInsuranceVfc(
                                practicePatient
                                  ? practicePatient.insurances[0].id
                                  : '',
                                false,
                                null,
                                insuranceCompanyId,
                                insuranceMemberId
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  }
                  on="click"
                  open={openVfcConfirm}
                  onClose={() => setOpenVfcConfirm(false)}
                  onOpen={() => setOpenVfcConfirm(true)}
                  position="left center"
                />
              ) : patientAge >= 19 && !practicePatient?.insurances.length ? (
                <Popup
                  trigger={
                    <Menu.Item
                      data-automation-id={`patient-scan-button`}
                      style={{
                        display: 'flex',
                        width: '15rem',
                        justifyContent: 'center',
                        fontSize: '20px',
                      }}
                    >
                      <Icon name="play" />
                      Scan
                    </Menu.Item>
                  }
                  content={
                    <div>
                      <h3>Is this a self pay patient?</h3>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          size="tiny"
                          color="olive"
                          content="Yes"
                          onClick={() => {
                            handleConfirmVfc(
                              practicePatient ? practicePatient.id : '',
                              true
                            );
                            setOpenVfcConfirm(false);
                          }}
                        />
                        <Button
                          size="tiny"
                          content="No"
                          onClick={() => {
                            setOpenVfcConfirm(false);
                            setSelectedInsuranceId('_new');
                          }}
                        />
                      </div>
                    </div>
                  }
                  on="click"
                  open={openVfcConfirm}
                  onClose={() => setOpenVfcConfirm(false)}
                  onOpen={() => setOpenVfcConfirm(true)}
                  position="left center"
                />
              ) : (
                gunScanner
              )}

              <StyledMenuItem
                data-automation-id={`patient-scan-finish-button`}
                name="finish"
                onClick={handleFinishOnClick}
                color={
                  !canFinish
                    ? undefined
                    : !providerId ||
                      !prescriberId ||
                      !givenAt ||
                      vaccineWithoutVisDate
                    ? 'grey'
                    : 'green'
                }
                disabled={!canFinish || disableFinishBtn}
              >
                {!!entries.length &&
                (conflictingInventory ||
                  !providerId ||
                  !prescriberId ||
                  hasDuplicated ||
                  !givenAt ||
                  vaccineWithoutVisDate ||
                  existExpiredVaccine) ? (
                  <Icon name="warning sign" />
                ) : (
                  <Icon name="check" />
                )}
                Finish
              </StyledMenuItem>
              {finished ? (
                <StyledMenuItem
                  style={{ borderLeft: '1px solid #dededf' }}
                  name="restart"
                  onClick={handleRestartClick}
                >
                  <Icon name="refresh" />
                  Restart
                </StyledMenuItem>
              ) : (
                <StyledMenuItem
                  data-automation-id={`patient-scan-cancel-button`}
                  style={{ borderLeft: '1px solid #dededf' }}
                  name="cancel"
                  color={canCancel ? undefined : 'red'}
                  disabled={canCancel}
                  onClick={() => setShowCancelConfirm(true)}
                >
                  <Icon name="cancel" />
                  Cancel
                </StyledMenuItem>
              )}
            </Menu.Menu>
          </Menu>
        </div>

        {practicePatient?.consentForIis === null && patientAge >= 19 && (
          <Modal
            closeIcon
            open={showConfirmConsentForIis}
            onOpen={() => setShowConfirmConsentForIis(false)}
            onClose={() => setShowConfirmConsentForIis(false)}
            size="tiny"
          >
            <Modal.Content>
              <Modal.Description>
                <Message
                  warning
                  icon="warning sign"
                  content="To continue please set patient's consent to share record with immunization
                registry. If consent is not set immunizations won't be saved."
                />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Don't give consent"
                labelPosition="right"
                icon="close"
                onClick={() =>
                  handleSaveConsentForIis(
                    practicePatient ? practicePatient.id : '',
                    false
                  )
                }
                negative
              />
              <Button
                content="Give consent"
                labelPosition="right"
                icon="checkmark"
                onClick={() =>
                  handleSaveConsentForIis(
                    practicePatient ? practicePatient.id : '',
                    true
                  )
                }
                positive
              />
            </Modal.Actions>
          </Modal>
        )}

        <Confirm
          data-automation-id={`patient-scan-vfc-confirm`}
          open={showVfcConfirm}
          header="Inventory selection"
          content={
            vfc
              ? 'The patient is VFC eligible, but the selected inventory is private. Continue?'
              : 'The patient is Non-VFC eligible, but the selected inventory is VFC. Continue?'
          }
          cancelButton="Change vial"
          confirmButton={vfc ? 'Use Private' : 'Use VFC'}
          size="tiny"
          onCancel={() => {
            setDisableFinishBtn(false);
            setShowVfcConfirm(false);
          }}
          onConfirm={finish}
        />

        <Confirm
          data-automation-id={`patient-scan-cancel-confirm`}
          open={showCancelConfirm}
          content="Are you sure you want to cancel the process?"
          cancelButton="No"
          confirmButton="Yes"
          size="mini"
          onCancel={() => setShowCancelConfirm(false)}
          onConfirm={handleCancelClick}
        />
        {finished && insertVaccinationsResult.data ? (
          <Segment>
            <Message success>
              <Icon name="check" />
              The following vaccinations were scanned successfully!
            </Message>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Lot</Table.HeaderCell>
                  <Table.HeaderCell>Expiration</Table.HeaderCell>
                  <Table.HeaderCell>Route</Table.HeaderCell>
                  <Table.HeaderCell>Site</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {allEntries.map((vaccination, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {vaccination.vaccine?.name ||
                          vaccination.gs1?.ndc ||
                          '-'}
                      </Table.Cell>
                      <Table.Cell>{vaccination.gs1?.lot || '-'}</Table.Cell>
                      <Table.Cell>
                        {vaccination.gs1
                          ? moment(vaccination.gs1?.exp).format('MM-DD-YYYY')
                          : '-'}
                      </Table.Cell>
                      <Table.Cell>{vaccination.vaccinationRoute}</Table.Cell>
                      <Table.Cell>
                        {vaccination.vaccinationRoute ===
                          VaccinationRoutes.IN ||
                        vaccination.vaccinationRoute === VaccinationRoutes.PO
                          ? 'N/A'
                          : vaccinationSiteParser(vaccination.vaccinationSite)}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Segment>
        ) : !!entries.length ? (
          <div style={{ marginTop: '1rem' }}>
            {/* <h2>Scanned vaccines</h2> */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form style={{ width: 'fit-content' }}>
                <Form.Group>
                  <Form.Field>
                    <label>Prescribed by:</label>
                    <Input
                      input={
                        <div style={{ zIndex: '20' }}>
                          <ProviderPicker
                            prescriber
                            placeholder="Select Prescriber"
                            defaultValue={
                              isValidPrescriber ? prescriberId : undefined
                            }
                            onChange={(prescriberId) =>
                              setPrescriberId(prescriberId)
                            }
                            isClereable={false}
                          />
                        </div>
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Given by:</label>
                    <Input
                      input={
                        <div style={{ zIndex: '20' }}>
                          <ProviderPicker
                            placeholder="Select Provider"
                            defaultValue={
                              account?.title === 'Other' ||
                              account?.title === null ||
                              (account?.profile?.permissionAs &&
                                !account?.profile?.permissionAs?.provider)
                                ? ''
                                : providerId
                            }
                            onChange={(providerId) => setProviderId(providerId)}
                            onProviderSelected={(provider) => {
                              setProviderFullName(
                                `${provider.account.firstName} ${provider.account.lastName}`
                              );
                            }}
                            isEmbedded={isEmbedded}
                            isClereable={false}
                          />
                        </div>
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Vaccination date:</label>
                    <Input
                      input={
                        <div style={{ zIndex: '20' }}>
                          <DateTimePicker
                            tz={practice.timezone}
                            selected={givenAt}
                            scrollableYearDropdown
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            maxDate={new Date()}
                            minDate={minVaxDate}
                            yearDropdownItemNumber={18}
                            onChange={(date) => setGivenAt(date as Date)}
                            // inputProps={{ style: { width: 114 } }}
                            inputProps={{
                              style: { width: '7.8rem', marginRight: '2.6rem' },
                            }}
                            dateFormat={DateFormats.DATE}
                          />
                        </div>
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <div
                style={{
                  alignItems: 'flex-end',
                  height: '60px',
                  display: 'flex',
                }}
              >
                {practiceSettings?.practice.settings.forms?.florida?.consentForm
                  ?.enabled && (
                  <PatientEligibilityScreeningRecordPdf
                    pageBlurred={pageBlurred}
                    practicePatient={practicePatient}
                    providerFullName={providerFullName}
                    vaccinations={allEntries.map(
                      ({
                        inventory,
                        vaccine,
                        vaccinationSite,
                        vaccinationRoute,
                      }): ScannedVacinationConsentPDF => {
                        return {
                          vaccine,
                          ...(inventory ? { inventory: inventory[0] } : {}),
                          givenAt,
                          providerId,
                          site:
                            vaccinationRoute === 'PO' ? null : vaccinationSite,
                          route: vaccinationRoute,
                        };
                      }
                    )}
                  />
                )}
              </div>
            </div>

            <ScannedItems
              givenAt={givenAt}
              patientAge={patientAge}
              allowRemove={!finished}
              vfc={vfc}
              givenVaccinations={givenVaccinations?.vaccinations ?? []}
            />
          </div>
        ) : (
          <Segment placeholder>
            <Header icon>
              <Icon name="qrcode" />
              Start scanning using the Scan Gun
            </Header>
          </Segment>
        )}
        {practicePatient?.consentForIis === null && patientAge >= 19 ? (
          <Message
            icon="warning sign"
            warning
            size="tiny"
            content={
              <>
                For Patients over 19 years old, consent is required to share
                their record with immunization registry. You can set the consent
                option from the{' '}
                <Link
                  to={`/${practice.handler}/patients/${practicePatientId}/info`}
                >
                  patient info window
                </Link>
                .
              </>
            }
          />
        ) : null}
        {!!entries.length &&
        patientAge >= 19 &&
        practicePatient?.consentForIis !== null ? (
          <Message
            positive
            negative={!practicePatient?.consentForIis}
            size="tiny"
            content={
              !practicePatient?.consentForIis ? (
                <p>
                  This patient has not given consent to share their record with
                  immunization registry. You can set the consent option from the{' '}
                  <Link
                    to={`/${practice.handler}/patients/${practicePatientId}/info`}
                  >
                    patient info window
                  </Link>
                  .
                </p>
              ) : (
                <p>
                  This patient has given consent to share their record with
                  immunization registry. You can set the consent option from the{' '}
                  <Link
                    to={`/${practice.handler}/patients/${practicePatientId}/info`}
                  >
                    patient info window
                  </Link>
                  .
                </p>
              )
            }
            icon={!practicePatient?.consentForIis ? 'warning circle' : 'check'}
          />
        ) : null}
      </Grid.Column>
      <Modal
        onClose={() => setOpenVfcCriteriaModal(false)}
        onOpen={() => setOpenVfcCriteriaModal(true)}
        open={openVfcCriteriaModal}
        size="mini"
        closeIcon
      >
        <Modal.Header>Select VFC criteria</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field required>
              <label>VFC Criteria</label>
              <Dropdown
                fluid
                selection
                value={selectedVfcCriteria}
                onChange={(e, data) =>
                  setSelectedVfcCriteria(data.value as VfcCriteria)
                }
                options={
                  practice.profile.address?.state === 'NY'
                    ? [
                        ...VFC_OPTIONS,
                        {
                          key: 'ch_plus_b',
                          value: 'ch_plus_b',
                          text: 'CHPlusB',
                        },
                      ]
                    : VFC_OPTIONS
                }
              />
            </Form.Field>
            {selectedVfcCriteria !== VfcCriteria.uninsured &&
            selectedVfcCriteria !== VfcCriteria.unknown ? (
              <>
                <Form.Field>
                  <label>Insurance Company</label>
                  <InsuranceCompanyPicker
                    value={insuranceCompanyId}
                    onChange={(value) => {
                      setInsuranceCompanyId(value as string);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Member Id</label>
                  <Input
                    fluid
                    placeholder="Member ID"
                    value={insuranceMemberId}
                    onChange={(_, { value }) => setInsuranceMemberId(value)}
                  />
                </Form.Field>
              </>
            ) : null}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => setOpenVfcCriteriaModal(false)}
          />
          <Button
            primary
            type="submit"
            content="Confirm"
            onClick={() => {
              if (patientAge < 19 && !practicePatient?.insurances.length) {
                handleSaveInsurance();
                setOpenVfcCriteriaModal(false);
                return;
              }

              handleSaveInsurance();
              handleConfirmVfc(practicePatient ? practicePatient.id : '');
              setOpenVfcCriteriaModal(false);
            }}
          />
        </Modal.Actions>
      </Modal>
      <InsurancesForm
        shouldMarkAsVfcConfirmed
        onSave={onInsuranceSave}
        onModalClose={() => {
          setSelectedInsuranceId(undefined);
        }}
        onVfcChange={onInsuranceVfcConditionChange}
        propPracticePatientId={practicePatientId}
        propPracticeId={practice.id}
        propInsuranceId={selectedInsuranceId}
        selectedVfcCriteria={selectedVfcCriteria}
        vfcChecked={isVfc}
      />
    </>
  );
};

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  justify-content: center;
  width: 15rem;
  font-size: 20px;
  color: ${(props) => props.color} !important;
  background-color: #f2f2f2 !important;
  &:hover {
    background-color: #f9fafb !important;
  }
`;

export const StyledMessage = styled.div<StyledMessageProps>`
  background-color: ${(props) => props.color};
  color: #c92a2a;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 4px;
  margin: ${(props) => props.margin};
`;

export default Wrapper;
