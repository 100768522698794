import { Document } from '@react-pdf/renderer';
import InvoicePage, { InvoicePageProps } from './InvoicePage';
import BillingReportPage, { BillingReportPageProps } from './BillingReportPage';
import SummaryPage from './Summary';

export type InvoiceAndBillingReportPdfProps = {
  version?: number;
  invoice: InvoicePageProps;
  billingReport: BillingReportPageProps;
};

const InvoiceAndBillingReportPdf = ({
  invoice,
  billingReport,
  version,
}: InvoiceAndBillingReportPdfProps) => {
  return (
    <Document>
      <SummaryPage {...invoice} />
      <InvoicePage {...invoice} />
      <BillingReportPage {...billingReport} version={version} />
    </Document>
  );
};

export type { InvoicePageProps, BillingReportPageProps };
export default InvoiceAndBillingReportPdf;
