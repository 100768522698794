import { usePractice } from '@bluefox/contexts';
import { CSSProperties, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

type Props = {
  home?: string;
  path?: { text: string; to?: string }[];
  style?: CSSProperties;
};

const MainBreadcrumb = ({ home, path, style }: Props) => {
  const { handler: practiceHandler } = usePractice();
  return (
    <Breadcrumb size="massive" style={style}>
      <Breadcrumb.Section as={Link} to={home || `/${practiceHandler}/`}>
        Home
      </Breadcrumb.Section>
      {path?.map((l, i) => (
        <Fragment key={i}>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section
            as={l.to ? Link : undefined}
            to={l.to ? `/${practiceHandler}/${l.to}` : undefined}
          >
            {l.text}
          </Breadcrumb.Section>
        </Fragment>
      ))}
    </Breadcrumb>
  );
};

export default MainBreadcrumb;
