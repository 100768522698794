import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Accordion, Icon, Table } from 'semantic-ui-react';
import { TicketQuery } from '@bluefox/graphql/tickets';
import { Ticket } from '@bluefox/models/Tickets';
import PlacedOrdersRow from './PlacedOrdersRow';
import VaccineRequestsForm from './VaccineRequestsForm';
import { usePractice } from '@bluefox/contexts';
import { FormattedVaccineOrder } from './PlacedOrdersRow';

interface VaccineRequestsData {
  tickets: Ticket[];
}

const PlacedOrders = () => {
  const practice = usePractice();

  const [show, setShow] = useState(false);
  const [openVaccineRequestsForm, setOpenVaccineRequestsForm] = useState(false);
  const [formattedData, setFormattedData] = useState<FormattedVaccineOrder[]>();

  const { data, refetch } = useQuery<VaccineRequestsData>(TicketQuery, {
    variables: {
      practiceFilter: {
        _contains: {
          fields: [
            {
              detail: practice.handler,
            },
          ],
        },
      },
      type: 'Vaccine_order',
      status: 'requested',
    },
    onCompleted: (data) => {
      const newFormattedData = data?.tickets.map((item) =>
        item.detail.fields.reduce(
          (acc: any, { title, detail }) => {
            acc[title.toLowerCase()] = detail;
            return acc;
          },
          {
            id: item.id,
            status: item.status,
          }
        )
      );

      setFormattedData(newFormattedData);
    },
  });

  return (
    <Accordion fluid styled>
      <Accordion.Title
        onClick={() => setShow((prevValue) => !prevValue)}
        active={show}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Icon name="dropdown" />
            Placed orders{' '}
            {data?.tickets?.length ? `(${data.tickets.length})` : `(0)`}
          </div>

          <VaccineRequestsForm
            open={openVaccineRequestsForm}
            onOpen={(value) => setOpenVaccineRequestsForm(value)}
            onSave={refetch}
          />
        </div>
      </Accordion.Title>
      <Accordion.Content active={show}>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Vaccine</Table.HeaderCell>
              <Table.HeaderCell>Requested Doses</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell width={1}>Notes</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {formattedData?.map((item: any) => (
              <PlacedOrdersRow
                key={item.id}
                vaccineOrder={item}
                onRemove={refetch}
              />
            ))}
          </Table.Body>
        </Table>
      </Accordion.Content>
    </Accordion>
  );
};

export default PlacedOrders;
