import { useMemo, useState } from 'react';
import SearchForm, { SearchValuesProps } from './SearchForm';
import { useQuery } from '@apollo/client';
import { BillingInvoice } from '@bluefox/models/Billing';
import { InvoiceStatus } from '@bluefox/models/Invoice';
import { NewBillingInvoicesQuery } from '@bluefox/graphql/billing';
import { Message } from 'semantic-ui-react';
import InvoiceListTable from './InvoiceListTable';
import InvoiceListPagination from './InvoiceListTable/InvoiceListPagination';
import styled from 'styled-components';
import {
  BillingReportPageProps,
  InvoiceAndBillingReportPdfProps,
  InvoicePageProps,
} from '@bluefox/pdf/InvoiceAndBillingReportPdf';
import InvoicePdfModal from '@bluefox/ui/Billing/InvoicePdfModal';
import { addDays, formatDateToMMhDDhYYYY } from '@bluefox/lib/formatters';
import logoImg from '@assets/canid-invoice-logo.png';

interface InvoicesData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  invoices: BillingInvoice[];
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
}

const pageLimit = 20;

export const StyledPagination = styled.div({
  marginTop: '1rem',
  textAlign: 'center',
});

type Props = {
  practiceIds: string[];
  pageBlurred?: boolean;
};

const InvoiceList = ({ practiceIds, pageBlurred = false }: Props) => {
  const [searchFormValues, setSearchFormValues] = useState<SearchValuesProps>(
    {} as SearchValuesProps
  );
  const [activePage, setActivePage] = useState(1);
  const [pdfModalBillingInvoice, setPdfModalBillingInvoice] =
    useState<InvoiceAndBillingReportPdfProps>();

  const andCriteria = useMemo(() => {
    let criteria: Array<{ [key: string]: any }> = [
      {
        status: {
          _in: [
            InvoiceStatus.PENDING,
            InvoiceStatus.PROCESSING,
            InvoiceStatus.ERROR_STRIPE,
            InvoiceStatus.PAID,
          ],
        },
      },
      { pdfData: { _is_null: false } },
    ];
    if (practiceIds) {
      criteria = [
        ...criteria,
        {
          claimCptCodes: {
            claim: {
              practiceId: { _in: practiceIds },
            },
          },
        },
      ];
    }
    if (searchFormValues?.dateFrom || searchFormValues?.dateTo) {
      const date: Record<string, string> = {};
      if (searchFormValues?.dateFrom) {
        date['_gte'] = formatDateToMMhDDhYYYY(searchFormValues?.dateFrom);
      }
      if (searchFormValues?.dateTo) {
        date['_lt'] = formatDateToMMhDDhYYYY(
          addDays(searchFormValues?.dateTo, 1)
        );
      }

      criteria = [...criteria, { date }];
    }
    return criteria?.length > 0 ? { _and: [...criteria] } : {};
  }, [practiceIds, searchFormValues?.dateFrom, searchFormValues?.dateTo]);

  const { data, error, loading } = useQuery<InvoicesData>(
    NewBillingInvoicesQuery,
    {
      variables: {
        criteria: andCriteria,
        limit: pageLimit,
        offset: (activePage - 1) * pageLimit,
      },
      skip: !practiceIds || practiceIds.length === 0,
    }
  );

  return (
    <>
      <SearchForm
        searchValues={searchFormValues}
        setSearchValues={setSearchFormValues}
      />
      {error ? (
        <Message error>{error.message}</Message>
      ) : (
        <>
          <InvoiceListTable
            loading={loading}
            invoices={data?.invoices}
            onShowPDF={(billingInvoice: BillingInvoice) => {
              /**
               * Due to logoImg is an asset and package library don't
               * have access to assets, it is needed to read the logo
               * in Practice Portal and pass it as a parameter to the modal
               * which will show the PDF
               */
              const version = billingInvoice.pdfData?.version;
              const invoice = billingInvoice.pdfData?.invoice;
              const billingReport = billingInvoice.pdfData?.billingReport;
              setPdfModalBillingInvoice({
                version,
                invoice: {
                  ...invoice,
                  header: { ...invoice?.header, logoImg },
                } as InvoicePageProps,
                billingReport: {
                  ...billingReport,
                  footer: { ...billingReport?.footer, logoImg },
                } as BillingReportPageProps,
              });
            }}
          />
          <StyledPagination>
            <InvoiceListPagination
              activePage={activePage}
              setActivePage={setActivePage}
              totalPages={
                data?.invoices && data?.invoices?.length > 0
                  ? Math.ceil(data?.aggregating.aggregate.count / pageLimit)
                  : 1
              }
            />
          </StyledPagination>
          <InvoicePdfModal
            invoiceProps={pdfModalBillingInvoice}
            onClose={() => setPdfModalBillingInvoice(undefined)}
            pageBlurred={pageBlurred}
          />
        </>
      )}
    </>
  );
};

export default InvoiceList;
