import { useQuery } from '@apollo/client';
import {
  GetInventoryAdjustmentQuery,
  InventoryAdjustmentReasons,
} from '@bluefox/graphql/inventoryAdjustment';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import { DateFormats } from '@bluefox/models/Dates';
import { Inventory } from '@bluefox/models/Inventory';
import {
  AdjustmentReason,
  InventoryAdjustment,
  InventoryAdjustmentDetail,
} from '@bluefox/models/InventoryAdjustment';
import FullLayout from '@components/FullLayout';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  Container,
  Header,
  Icon,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import {
  renderInventoryAdjustmentStatus,
  renderInventoryAdjustmentUser,
} from './InventoryAdjustmentHistory';
import { buildNewInventoryAdjustment } from '@bluefox/ui/inventoryAdjustment';

const CONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION =
  'consistentInventoryAdjustmentDetails';
const INCONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION =
  'inconsistentInventoryAdjustmentDetails';

const InventoryAdjustmentHistoryItem = () => {
  const { adjustmentId } = useParams<{ adjustmentId: string }>();
  const [inventoryAdjustment, setInventoryAdjustment] =
    useState<InventoryAdjustment>();
  const [
    consistentInventoryAdjustmentDetails,
    setConsistentInventoryAdjustmentDetails,
  ] = useState<InventoryAdjustmentDetail[]>();
  const [
    inconsistentInventoryAdjustmentDetails,
    setInconsistentInventoryAdjustmentDetails,
  ] = useState<InventoryAdjustmentDetail[]>();
  const [activeAccordion, setActiveAccordion] = useState<
    Record<string, boolean>
  >({ [INCONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION]: true });

  const { loading, error } = useQuery<{
    inventory_adjustments: InventoryAdjustment[];
  }>(GetInventoryAdjustmentQuery, {
    variables: {
      inventoryAdjustmentDetailId: adjustmentId,
    },
    skip: !isStringNotEmpty(adjustmentId),
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const adjustment = data.inventory_adjustments?.[0];
      if (adjustment) {
        const newAdjustment = buildNewInventoryAdjustment(adjustment);
        setInventoryAdjustment(newAdjustment);
        const details = newAdjustment.details;
        setConsistentInventoryAdjustmentDetails(
          details?.filter((detail) => detail.newDoses === detail.currentDoses)
        );
        setInconsistentInventoryAdjustmentDetails(
          details?.filter((detail) => detail.newDoses !== detail.currentDoses)
        );
      } else {
        setInventoryAdjustment(undefined);
        setConsistentInventoryAdjustmentDetails(undefined);
        setInconsistentInventoryAdjustmentDetails(undefined);
      }
    },
    onError: (error) => {
      setInventoryAdjustment(undefined);
      setConsistentInventoryAdjustmentDetails(undefined);
      setInconsistentInventoryAdjustmentDetails(undefined);
    },
  });

  const { data: adjustmentReasonData } = useQuery<{
    adjustmentReasons: AdjustmentReason[];
  }>(InventoryAdjustmentReasons, {
    variables: {
      type: 'Administration',
    },
  });

  return (
    <FullLayout
      loading={loading}
      path={[
        { text: 'Inventory', to: 'inventory' },
        { text: 'Inventory Adjustment', to: 'inventory/adjustment' },
        { text: 'History', to: 'inventory/adjustment/history' },
        { text: 'Adjustment' },
      ]}
    >
      <Segment padded basic>
        <Header as="h1">
          <Icon name="list" />
          Inventory Adjustment Item
        </Header>
      </Segment>
      <Container>
        {error && <Message error>{error.message}</Message>}
        <>
          <Table celled structured selectable>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Inventory</Table.HeaderCell>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row key={inventoryAdjustment?.id} textAlign="center">
                <Table.Cell>
                  {moment(inventoryAdjustment?.createdAt).format(
                    DateFormats.DATE_WITH_TIME_SLASH
                  )}
                </Table.Cell>
                <Table.Cell>
                  {inventoryAdjustment?.vfc ? 'VFC' : 'Private'}
                </Table.Cell>
                <Table.Cell>
                  {renderInventoryAdjustmentUser(
                    inventoryAdjustment?.statusLog
                  )}
                </Table.Cell>
                <Table.Cell>
                  {renderInventoryAdjustmentStatus(inventoryAdjustment?.status)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Accordion styled fluid style={{ marginTop: '3rem' }}>
            <Accordion.Title
              active={
                activeAccordion?.[
                  CONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION
                ]
              }
              index={CONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION}
              onClick={() => {
                setActiveAccordion((prevVal) => ({
                  ...prevVal,
                  [CONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION]:
                    !prevVal[CONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION],
                }));
              }}
            >
              <Icon name="dropdown" />
              List of vaccines without inconsistencies
            </Accordion.Title>
            <Accordion.Content
              active={
                activeAccordion?.[
                  CONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION
                ]
              }
            >
              <Table celled structured selectable>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>Vaccine Name</Table.HeaderCell>
                    <Table.HeaderCell>Lot Number</Table.HeaderCell>
                    <Table.HeaderCell>Expiration Date</Table.HeaderCell>
                    <Table.HeaderCell>Doses</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {consistentInventoryAdjustmentDetails &&
                  consistentInventoryAdjustmentDetails.length > 0 ? (
                    consistentInventoryAdjustmentDetails.map(
                      (adjustmentDetail) => {
                        const inventory =
                          adjustmentDetail?.inventory as Inventory;
                        return (
                          <Table.Row
                            key={adjustmentDetail.id}
                            textAlign="center"
                          >
                            <Table.Cell>{inventory?.vaccine?.name}</Table.Cell>
                            <Table.Cell>{inventory?.lot}</Table.Cell>
                            <Table.Cell>
                              {inventory?.expiration
                                ? moment(inventory?.expiration).format(
                                    DateFormats.DATE
                                  )
                                : '-'}
                            </Table.Cell>
                            <Table.Cell>
                              {adjustmentDetail?.currentDoses}
                            </Table.Cell>
                          </Table.Row>
                        );
                      }
                    )
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={4}>
                        <Message>No adjustment found.</Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Accordion.Content>
          </Accordion>
          <Accordion styled fluid style={{ marginTop: '3rem' }}>
            <Accordion.Title
              active={
                activeAccordion?.[
                  INCONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION
                ]
              }
              index={INCONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION}
              onClick={() => {
                setActiveAccordion((prevVal) => ({
                  ...prevVal,
                  [INCONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION]:
                    !prevVal[
                      INCONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION
                    ],
                }));
              }}
            >
              <Icon name="dropdown" />
              List of vaccines with inconsistencies
            </Accordion.Title>
            <Accordion.Content
              active={
                activeAccordion?.[
                  INCONSISTENT_INVENTORY_ADJUSTMENT_DETAILS_ACCORDION
                ]
              }
            >
              <Table celled structured selectable>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>Vaccine Name</Table.HeaderCell>
                    <Table.HeaderCell>Lot Number</Table.HeaderCell>
                    <Table.HeaderCell>Expiration Date</Table.HeaderCell>
                    <Table.HeaderCell>Current Doses</Table.HeaderCell>
                    <Table.HeaderCell>Doses in the fridge</Table.HeaderCell>
                    <Table.HeaderCell>Difference</Table.HeaderCell>
                    <Table.HeaderCell>
                      Reason of the Difference
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {inconsistentInventoryAdjustmentDetails &&
                  inconsistentInventoryAdjustmentDetails.length > 0 ? (
                    inconsistentInventoryAdjustmentDetails.map(
                      (adjustmentDetail) => {
                        const inventory =
                          adjustmentDetail?.inventory as Inventory;
                        const reason =
                          adjustmentReasonData?.adjustmentReasons?.find(
                            (ar) => ar.value === adjustmentDetail?.reason
                          )?.text || adjustmentDetail?.reason;
                        return (
                          <Table.Row
                            key={adjustmentDetail.id}
                            textAlign="center"
                          >
                            <Table.Cell>{inventory?.vaccine?.name}</Table.Cell>
                            <Table.Cell>{inventory?.lot}</Table.Cell>
                            <Table.Cell>
                              {inventory?.expiration
                                ? moment(inventory?.expiration).format(
                                    DateFormats.DATE
                                  )
                                : '-'}
                            </Table.Cell>
                            <Table.Cell>
                              {adjustmentDetail?.currentDoses}
                            </Table.Cell>
                            <Table.Cell>
                              {adjustmentDetail?.newDoses}
                            </Table.Cell>
                            <Table.Cell>
                              {adjustmentDetail?.newDoses -
                                adjustmentDetail?.currentDoses}
                            </Table.Cell>
                            <Table.Cell>
                              {`[${reason}] ${adjustmentDetail?.comment || ''}`}
                            </Table.Cell>
                          </Table.Row>
                        );
                      }
                    )
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={7}>
                        <Message>No adjustment found.</Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Accordion.Content>
          </Accordion>
        </>
      </Container>
    </FullLayout>
  );
};

export default InventoryAdjustmentHistoryItem;
