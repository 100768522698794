import React, {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useCallback,
} from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import {
  InsurancesQuery,
  PrimaryInsurancesByPatientCount,
  InsertInsuranceMutation,
  UpdateInsuranceMutation,
  InsuranceByIdQuery,
  SetPracticePatientAsSelfPaid,
} from '@bluefox/graphql/insurances';
import { InsuranceMappingByCompanyId } from '@bluefox/graphql/mappings';
import {
  Insurance,
  InsuranceCompany,
  InsuranceInput,
  InsuranceRelationShips,
  VfcCriteria,
} from '@bluefox/models/Insurances';
import {
  Button,
  Form,
  Header,
  Icon,
  Modal,
  Popup,
  Grid,
  Radio,
  Confirm,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import PrimaryCareProviderPicker from '@bluefox/ui/PrimaryCareProviderPicker';

import { usePatientData } from '@bluefox/contexts/PatientData';
import {
  useApplicationState,
  usePractice,
  useSession,
} from '@bluefox/contexts';
import { getAge } from '@bluefox/lib/getAge';
import { usePracticePatient } from '@bluefox/contexts/PracticePatientProvider';
import { PracticePatientByIdQuery } from '@bluefox/graphql/patients';
import { PracticePatient } from '@bluefox/models/Patient';
import { Practice } from '@bluefox/models/Practice';

const VFC_OPTIONS = [
  { key: 'medicaid', value: 'medicaid', text: 'Medicaid' },
  { key: 'uninsured', value: 'uninsured', text: 'Uninsured' },
  { key: 'ai_an', value: 'ai_an', text: 'Native American / Alaska Native' },
  {
    key: 'underinsured',
    value: 'underinsured',
    text: 'Underinsured',
  },
  { key: 'unknown', value: 'unknown', text: 'Unknown' },
];
interface RouterParamsType {
  patientId?: string;
  practicePatientId?: string;
  insuranceId: string;
}

interface InsurancesFormProps {
  onSave?: () => void;
  onCancel?: () => void;
  onInsert?: (practicePatient: InsuranceInput) => void;
  onUpdate?: (patient: Insurance) => void;
  onVfcChange?: (vfc: boolean) => void;
  onModalClose?: () => void;
  propInsuranceId?: string;
  propPracticeId?: string;
  propPracticePatientId?: string;
  shouldMarkAsVfcConfirmed?: boolean;
  selectedVfcCriteria?: VfcCriteria;
  vfcChecked?: boolean;
}

interface InsuranceId {
  id: string;
}

interface InsurancesCount {
  insurances: InsuranceId[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface InsurancesData {
  insurancesData: {
    practice: Practice;
    insurances: Insurance[];
    patient: {
      practice: {
        id: string;
      };
    };
  };
}

interface InsuranceForBillingData {
  insurances: [{ insuranceCompanyForBilling: InsuranceCompany }];
}

const InsurancesForm = ({
  onSave,
  onVfcChange,
  propPracticePatientId,
  propPracticeId,
  propInsuranceId,
  onModalClose,
  shouldMarkAsVfcConfirmed,
  selectedVfcCriteria,
  vfcChecked,
}: InsurancesFormProps) => {
  const { isEmbedded } = useApplicationState();
  const history = useHistory();
  const currentRouteArr = history.location.pathname.split('/');
  const goBackRouteArr = currentRouteArr.slice(0, -1);
  const patientData = usePatientData();
  const session = useSession();
  const practice = usePractice();

  const insuranceId = useParams<RouterParamsType>().insuranceId
    ? useParams<RouterParamsType>().insuranceId
    : propInsuranceId;
  const practicePatientId = useParams<RouterParamsType>().practicePatientId
    ? useParams<RouterParamsType>().practicePatientId
    : propPracticePatientId;

  const pPatientId = patientData?.practicePatientId ?? practicePatientId;

  const [insurance, setInsurance] = useState<Insurance | undefined>();
  const [isActive, setIsActive] = useState(true);
  const companyReqRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [isSelfPay, setIsSelfPay] = useState(false);
  const [vfcEligible, setVfcEligible] = useState(vfcChecked ? true : false);
  const [vfcCriteria, setVfcCriteria] = useState<VfcCriteria | undefined>(
    selectedVfcCriteria ? selectedVfcCriteria : VfcCriteria.uninsured
  );
  const [pcp, setPcp] = useState<string | undefined>();
  const [insuranceNotApplicable, setInsuranceNotApplicable] = useState(false);
  const [
    insuranceNotRequiredButApplicable,
    setInsuranceNotRequiredButApplicable,
  ] = useState(false);
  const [insuranceCompanyId, setInsuranceCompanyId] = useState<
    string | undefined
  >();
  const [insuranceCompanyForBilling, setInsuranceCompanyForBilling] =
    useState('-');
  const [
    insuranceCompanyPayerIdForBilling,
    setInsuranceCompanyPayerIdForBilling,
  ] = useState<string | undefined>('-');
  const [relationship, setRelationship] = useState(InsuranceRelationShips.Self);
  const [memberId, setMemberId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [holderFirstName, setHolderFirstName] = useState('');
  const [holderLastName, setHolderLastName] = useState('');
  const [holderDob, setHolderDob] = useState<Date | null>(null);
  const [showCompanyRequiredMessage, setShowCompanyRequiredMessage] =
    useState(false);
  const [primary, setPrimary] = useState(false);
  const [
    shouldOpenDisableInsuranceConfirmation,
    setShouldOpenDisableInsuranceConfirmation,
  ] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(!!insuranceId);
  const [keepInsuranceInfo, setKeepInsuranceInfo] = useState(false);

  const { data, refetch } = useQuery<InsurancesData>(InsurancesQuery, {
    variables: {
      practicePatientId: pPatientId,
    },
  });

  const [
    getPrimaryInsurance,
    { data: insurancesDataPerUser, refetch: refetchCount },
  ] = useLazyQuery<InsurancesCount>(PrimaryInsurancesByPatientCount);

  useEffect(() => {
    if (!data) return;

    getPrimaryInsurance({
      variables: {
        practicePatientId: pPatientId,
      },
    });
  }, [data]);

  const primaryInsuranceId = insurancesDataPerUser?.insurances.map((i) => i.id);

  const { data: insuranceData } = useQuery<{ insurance: Insurance }>(
    InsuranceByIdQuery,
    {
      variables: {
        insuranceId,
      },
      skip: insuranceId === '_new' || insuranceId === undefined,
    }
  );

  const { data: practicePatientQueryResponse } = useQuery<{
    practicePatient: PracticePatient;
  }>(PracticePatientByIdQuery(false), {
    variables: { id: pPatientId },
    skip: patientData !== undefined,
  });

  const [
    getInsuranceCompanyForBilling,
    { data: insuranceCompanyForBillingData },
  ] = useLazyQuery<InsuranceForBillingData>(InsuranceMappingByCompanyId(false));

  const onCloseModalHandler = () => {
    if (propPracticePatientId) {
      setShouldOpenModal(false);
      if (onModalClose) {
        cleanAndClose();
        onModalClose();
      }
      return;
    }
    history.push(`${goBackRouteArr.join('/')}`);
  };

  const cleanAndClose = () => {
    setInsurance(undefined);
    setIsActive(true);
    setVfcEligible(false);
    setVfcCriteria(VfcCriteria.uninsured);
    setPcp(undefined);
    setInsuranceNotApplicable(false);
    setInsuranceCompanyId(undefined);
    setRelationship(InsuranceRelationShips.Self);
    setMemberId('');
    setGroupId('');
    setHolderFirstName('');
    setHolderLastName('');
    setHolderDob(null);
    setShowCompanyRequiredMessage(false);
    setPrimary(false);
    setShouldOpenModal(false);
    setInsuranceCompanyForBilling('-');
    setInsuranceCompanyPayerIdForBilling('-');
  };

  useEffect(() => {
    if (!insurancesDataPerUser) return;
    if (insurancesDataPerUser.aggregating.aggregate.count === 0)
      setPrimary(true);
  }, [insurancesDataPerUser]);

  useEffect(() => {
    if (!insuranceData || !insuranceData?.insurance) return;
    setInsurance(insuranceData?.insurance);
  }, [insuranceData]);

  useEffect(() => {
    if (!insurance) return;
    setIsActive(!!insurance.active);
    setVfcEligible(insurance.vfcEligible);
    setVfcCriteria(insurance.vfcCriteria || VfcCriteria.medicaid);
    setPcp(insurance.pcp || undefined);
    setInsuranceCompanyId(insurance?.insuranceCompanyId);
    setRelationship(
      (insurance.relationship as InsuranceRelationShips) ??
        InsuranceRelationShips.Self
    );
    setMemberId(insurance.memberId ?? '');
    setGroupId(insurance.groupId ?? '');
    setHolderFirstName(insurance.holderFirstName ?? '');
    setHolderLastName(insurance.holderLastName ?? '');
    setHolderDob(
      insurance.holderDob
        ? new Date(moment(insurance.holderDob).format('MM/DD/YYYY'))
        : null
    );
    if (!primary) setPrimary(insurance.primary ?? false);
  }, [insurance]);

  const [saveInsurance, { loading }] = useMutation<InsuranceInput | Insurance>(
    insurance?.id
      ? UpdateInsuranceMutation
      : InsertInsuranceMutation(
          data?.insurancesData.insurances.some((insurance) => insurance.primary)
        )
  );

  const [setPracticePatientAsSelfPay] = useMutation(
    SetPracticePatientAsSelfPaid
  );

  const hasActiveInsurances = (): boolean => {
    return (
      data?.insurancesData?.insurances?.some(
        (insurance: Insurance) => insurance.active
      ) || false
    );
  };

  const handleSubmitInsurance = async () => {
    const timeoutLength = 5000;

    if (!insuranceNotApplicable && !insuranceNotRequiredButApplicable) {
      if (
        insuranceCompanyId === undefined ||
        insuranceCompanyId === null ||
        insuranceCompanyId === ''
      ) {
        setShowCompanyRequiredMessage(true);
        setTimeout(() => {
          setShowCompanyRequiredMessage(false);
        }, timeoutLength);
        return;
      }
    }

    saveInsurance({
      variables: {
        practicePatientId: pPatientId ? pPatientId.trim() : pPatientId,
        vfcEligible,
        vfcCriteria: vfcEligible ? vfcCriteria : undefined,
        insuranceCompanyId:
          insuranceCompanyId && keepInsuranceInfo
            ? insuranceCompanyId.trim()
            : null,
        memberId: memberId && keepInsuranceInfo ? memberId.trim() : null,
        groupId: groupId && keepInsuranceInfo ? groupId.trim() : null,
        relationship: keepInsuranceInfo ? relationship : null,
        holderFirstName: holderFirstName
          ? holderFirstName.trim()
          : holderFirstName,
        holderLastName: holderLastName ? holderLastName.trim() : holderLastName,
        holderDob,
        pcp,
        active: isActive,
        ...(insurance?.id ? { id: insurance?.id } : {}),
        primary: isActive && primary,
        oldPrimaryId: primaryInsuranceId,
        //...(isActive && primary ? { primary } : { primary: false }),
      },
      refetchQueries: [
        {
          query: InsurancesQuery,
          variables: {
            practicePatientId: pPatientId,
          },
        },
      ],
    })
      .then((r) => {
        toast({
          title: `Insurance has been ${
            insurance?.id ? 'updated' : 'created'
          } successfully`,
          type: 'success',
          time: 1000,
        });
        refetch();
        refetchCount();
        if (onSave) onSave();
        if (propPracticePatientId) {
          cleanAndClose();
          return;
        }
        history.push(`${goBackRouteArr.join('/')}`);
      })
      .catch((e) => {
        toast({
          title: 'Error on saving insurance',
          description: e,
          type: 'error',
          time: 5000,
        });
      });
  };

  const updatePracticePatientAsSelfPaid = () => {
    setPracticePatientAsSelfPay({
      variables: {
        practicePatientId: pPatientId,
        isVfcConfirmed: shouldMarkAsVfcConfirmed,
      },
    })
      .then(() => {
        toast({
          title: 'Patient data has been updated successfully',
          type: 'success',
          time: 1000,
        });
        refetch();
        refetchCount();
        if (onSave) onSave();
        if (propPracticePatientId) {
          cleanAndClose();
          return;
        }
        history.push(`${goBackRouteArr.join('/')}`);
      })
      .catch((e) => {
        toast({
          title: 'Error on saving patient data',
          description: e,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleSubmitSelfPayPatient = () => {
    if (hasActiveInsurances()) {
      setShouldOpenDisableInsuranceConfirmation(true);
      return;
    }
    updatePracticePatientAsSelfPaid();
  };

  const handleSubmit = async () => {
    if (isSelfPay) {
      handleSubmitSelfPayPatient();
    } else {
      await handleSubmitInsurance();
    }
    if (onVfcChange) onVfcChange(vfcEligible);
  };

  const buildDisableInsuranceConfirmationMessage = (): string => {
    const activePrimaryInsurance = data?.insurancesData.insurances.find(
      (insurance) => insurance.primary
    );
    return `Are you sure you want to mark the patient as "Self Pay"?
     This will deactivate all active insurances for the patient, including the primary insurance ( Name: ${activePrimaryInsurance?.company?.name},   
    Member Id: ${activePrimaryInsurance?.memberId}). 
    `;
  };

  const handlePcpOnChange = useCallback(
    (pId) => setPcp(pId || undefined),
    [setPcp]
  );

  const shouldDisableInsuranceFormItems = (): boolean => {
    return (
      (insuranceNotApplicable && !insuranceNotRequiredButApplicable) ||
      isSelfPay
    );
  };

  const shouldDisableIsSelfPayCheckBox = (): boolean => {
    if ((!patientData && !practicePatientQueryResponse) || vfcEligible) {
      return true;
    }
    const rawBirthdate = patientData
      ? patientData?.birthdate
      : practicePatientQueryResponse?.practicePatient.patientData?.birthdate;
    const objectBirthdate = rawBirthdate ? new Date(rawBirthdate) : undefined;
    return getAge(objectBirthdate) < 19;
  };
  const handleHolderRelationshipOnchange = useCallback(
    (_, { value }) => {
      setRelationship(value as InsuranceRelationShips);
      if (relationship === InsuranceRelationShips.Self && patientData) {
        setHolderFirstName(patientData.firstName);
        setHolderLastName(patientData.lastName);
        setHolderDob(
          new Date(moment(patientData.birthdate).format('MM/DD/YYYY'))
        );
      }
    },
    [
      setRelationship,
      setHolderFirstName,
      setHolderLastName,
      setHolderDob,
      relationship,
      patientData,
    ]
  );

  useEffect(() => {
    setInsuranceNotApplicable(
      vfcEligible &&
        (vfcCriteria === VfcCriteria.uninsured ||
          vfcCriteria === VfcCriteria.unknown ||
          vfcCriteria === VfcCriteria.ai_an)
    );

    setInsuranceNotRequiredButApplicable(
      vfcEligible &&
        (vfcCriteria === VfcCriteria.unknown ||
          vfcCriteria === VfcCriteria.ai_an)
    );

    setKeepInsuranceInfo(
      (vfcEligible && vfcCriteria !== VfcCriteria.uninsured) || !vfcEligible
    );
  }, [vfcEligible, vfcCriteria]);

  useEffect(() => {
    if (!vfcChecked) return;
    setVfcEligible(vfcChecked);
  }, [vfcChecked]);

  useEffect(() => {
    if (!selectedVfcCriteria) return;
    setVfcCriteria(selectedVfcCriteria);
  }, [selectedVfcCriteria]);

  const isInsuranceRelationshipSelf =
    relationship === InsuranceRelationShips.Self;

  const disableActiveToggle =
    insuranceId === '_new' || (insurance && insurance.primary);

  useEffect(() => {
    if (!!insuranceId) {
      setShouldOpenModal(true);
    }
  }, [insuranceId]);

  useEffect(() => {
    if (session.account?.role !== 'staff') return;
    if (!insuranceCompanyId || !data) return;
    getInsuranceCompanyForBilling({
      variables: {
        insuranceCompanyId,
        practiceId: practice.id ? practice.id : propPracticeId,
      },
    });
  }, [insuranceCompanyId, data]);

  useEffect(() => {
    if (!insuranceCompanyForBillingData) return;

    if (
      insuranceCompanyForBillingData.insurances.length &&
      insuranceCompanyForBillingData.insurances[0].insuranceCompanyForBilling
    ) {
      setInsuranceCompanyForBilling(
        insuranceCompanyForBillingData.insurances[0].insuranceCompanyForBilling
          .name
      );
      setInsuranceCompanyPayerIdForBilling(
        `(Payer ID: ${insuranceCompanyForBillingData.insurances[0].insuranceCompanyForBilling.payerId})`
      );
    } else {
      setInsuranceCompanyForBilling('-');
      setInsuranceCompanyPayerIdForBilling('-');
    }
  }, [insuranceCompanyForBillingData]);

  return (
    <>
      <Modal closeIcon open={shouldOpenModal} onClose={onCloseModalHandler}>
        {!isEmbedded && (
          <StyledHeader>
            {insuranceId && insuranceId !== '_new'
              ? 'Edit Insurance'
              : 'Add Insurance'}
          </StyledHeader>
        )}
        <Modal.Content>
          <Form onSubmit={handleSubmit} data-automation-id="insurances-form">
            <Grid columns={2}>
              <Grid.Column>
                <Header as="h4" dividing>
                  Vaccine for Children
                </Header>
                <Form.Group inline style={{ padding: 0 }}>
                  <Form.Checkbox
                    width="five"
                    disabled={isSelfPay}
                    checked={vfcEligible}
                    onChange={(_, { checked }) => setVfcEligible(!!checked)}
                    label="VFC eligible"
                    data-automation-id="insurances-form-vfc"
                  />
                  <Form.Select
                    width="eleven"
                    style={{ width: '100%' }}
                    required
                    value={vfcCriteria}
                    onChange={(_, { value }) =>
                      setVfcCriteria(value as VfcCriteria)
                    }
                    disabled={!vfcEligible}
                    options={
                      //The first condition occurs when data comes from PP, while the second occurs when data comes from IT.
                      practice.profile.address?.state === 'NY' ||
                      data?.insurancesData.practice.profile.address?.state ===
                        'NY'
                        ? [
                            ...VFC_OPTIONS,
                            {
                              key: 'ch_plus_b',
                              value: 'ch_plus_b',
                              text: 'CHPlusB',
                            },
                          ]
                        : VFC_OPTIONS
                    }
                    data-automation-id="insurances-form-vfc-criteria"
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4" dividing>
                  Primary Care Provider
                </Header>
                <PrimaryCareProviderPicker
                  defaultValue={pcp || ''}
                  onChange={handlePcpOnChange}
                  data-automation-id="insurances-form-pcp"
                />
              </Grid.Column>
            </Grid>

            <Header as="h4" dividing>
              <Grid columns={'equal'}>
                <Grid.Column>Insurance details</Grid.Column>
                <Grid.Column
                  textAlign="right"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Form.Checkbox
                    width="five"
                    checked={isSelfPay}
                    disabled={shouldDisableIsSelfPayCheckBox()}
                    onChange={(_, { checked }) => setIsSelfPay(!!checked)}
                    label="Self Pay"
                    data-automation-id="insurances-form-isSelpay"
                  />
                </Grid.Column>
              </Grid>
            </Header>

            <Form.Group widths="two">
              <Form.Field
                disabled={
                  insuranceNotApplicable && !insuranceNotRequiredButApplicable
                }
                required={!insuranceNotRequiredButApplicable}
              >
                <label>Company</label>
                <div ref={companyReqRef}>
                  <InsuranceCompanyPicker
                    disabled={shouldDisableInsuranceFormItems()}
                    value={insuranceCompanyId}
                    onChange={(value) => {
                      setInsuranceCompanyId(value as string);
                    }}
                    data-automation-id="insurances-form-company"
                  />
                </div>
                <Popup
                  style={{ border: '1px solid gray' }}
                  context={companyReqRef}
                  content={() => {
                    return (
                      <div>
                        <Icon
                          size="small"
                          bordered
                          inverted
                          name="warning"
                          color="yellow"
                        />
                        Please fill out this field.
                      </div>
                    );
                  }}
                  position="right center"
                  open={showCompanyRequiredMessage}
                />
              </Form.Field>
              {session.account?.role === 'staff' && (
                <Form.Field>
                  <Form.Input
                    readOnly
                    value={`${insuranceCompanyForBilling} ${insuranceCompanyPayerIdForBilling}`}
                    label="Insurance For Billing"
                    placeholder="Insurance For Billing"
                    data-automation-id="insurances-form-company-for-billing"
                  />
                </Form.Field>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={5}
                value={memberId}
                onChange={(_, { value }) => setMemberId(value)}
                label="Member ID"
                placeholder="Member ID"
                required={!insuranceNotRequiredButApplicable}
                disabled={shouldDisableInsuranceFormItems()}
                data-automation-id="insurances-form-member-id"
              />
              <Form.Input
                width={5}
                value={groupId}
                onChange={(_, { value }) => setGroupId(value)}
                label="Group ID"
                placeholder="Group ID"
                disabled={shouldDisableInsuranceFormItems()}
                data-automation-id="insurances-form-group-id"
              />

              <Form.Dropdown
                width={6}
                fluid
                selection
                value={relationship}
                onChange={handleHolderRelationshipOnchange}
                label="Relationship with Holder"
                options={[
                  { text: 'Self', value: InsuranceRelationShips.Self },
                  { text: 'Spouse', value: InsuranceRelationShips.Spouse },
                  { text: 'Child', value: InsuranceRelationShips.Child },
                  { text: 'Other', value: InsuranceRelationShips.Other },
                ]}
                disabled={shouldDisableInsuranceFormItems()}
                required={!insuranceNotRequiredButApplicable}
                data-automation-id="insurances-form-relationship"
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={5}
                value={holderFirstName}
                onChange={(_, { value }) => setHolderFirstName(value)}
                label="Holder First Name"
                placeholder="Holder First Name"
                disabled={
                  isInsuranceRelationshipSelf ||
                  shouldDisableInsuranceFormItems()
                }
                required
                data-automation-id="insurances-form-firstname"
              />
              <Form.Input
                width={5}
                value={holderLastName}
                onChange={(_, { value }) => setHolderLastName(value)}
                label="Holder Last Name"
                placeholder="Holder Last Name"
                disabled={
                  isInsuranceRelationshipSelf ||
                  shouldDisableInsuranceFormItems()
                }
                required
                data-automation-id="insurances-form-lastname"
              />
              <Form.Field
                width={6}
                disabled={
                  isInsuranceRelationshipSelf ||
                  shouldDisableInsuranceFormItems()
                }
                required
              >
                <label>Holder Birthdate</label>
                <DateTimePicker
                  data-automation-id="insurances-form-holder-birthdate"
                  selected={holderDob}
                  onChange={(d) => setHolderDob(d as Date)}
                  maxDate={new Date()}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  disabled={
                    isInsuranceRelationshipSelf ||
                    shouldDisableInsuranceFormItems()
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            {/* INSURANCE VERIFICATION MESSAGES */}
            {/* {practice.isBillable && insurance?.id && !insuranceNotApplicable && (
            <VerifiedInsuranceCardComponent insurance={insurance} />
          )} */}
            <Form.Group
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Form.Field>
                <label>Active</label>
                <Radio
                  toggle
                  checked={isActive}
                  disabled={disableActiveToggle}
                  onChange={(_, { checked }) => setIsActive(!!checked)}
                />
              </Form.Field>
              <Button
                disabled={loading}
                loading={loading}
                primary
                type="submit"
                content="Save"
                icon="save"
                data-automation-id="insurances-form-button"
              />
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>

      <Confirm
        header="The Patient has active insurances"
        open={shouldOpenDisableInsuranceConfirmation}
        content={buildDisableInsuranceConfirmationMessage()}
        onCancel={() => setShouldOpenDisableInsuranceConfirmation(false)}
        onConfirm={() => {
          updatePracticePatientAsSelfPaid();
          setShouldOpenDisableInsuranceConfirmation(false);
        }}
      />
    </>
  );
};

export default InsurancesForm;

const StyledHeader = styled(Modal.Header)`
  font-size: 2rem !important;
`;
