import React, { useState } from 'react';
import { Card, Grid, Icon, Popup } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';
import { ImmunizationEntry } from '@bluefox/store/vaccinations';
import ImmunizationDetail from '@components/VaxHistory/ImmunizationDetail';
import { Vaccination } from '@bluefox/models/Vaccination';
import { parseToMMDDYYYY } from '@utils/dates';
import EditImmunizationModal from './EditImmunizationModal';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';

interface ImmunizationCardPops {
  visibleVaccination: Vaccination;
  selectVaccinationToDelete: (vaccinationId: string) => void;
  immunization: ImmunizationEntry;
  wasUploadedToEmr: boolean;
}

const ImmunizationCardWeb = ({
  visibleVaccination,
  selectVaccinationToDelete,
  immunization,
  wasUploadedToEmr,
}: ImmunizationCardPops) => {
  const [openEditModal, setOpenEditModal] = useState(false);

  const isRecentImmunization = () =>
    moment().diff(visibleVaccination.createdAt, 'days') === 0;

  const newImmunizationStyle = {
    backgroundColor: '#E5F9E7',
    color: '#4AC053',
    borderColor: '#1EBC30',
  };

  const shouldShowMorInfoButton =
    visibleVaccination?.lot || visibleVaccination?.inventory?.lot;
  let givenAt;

  if (visibleVaccination) {
    givenAt = moment(visibleVaccination.givenAt).format(DateFormats.DATE);
    if (visibleVaccination.historic) {
      givenAt = parseToMMDDYYYY(String(visibleVaccination.givenAt));
    }
  }

  const renderUploadedToEmrIcon = () => {
    if (visibleVaccination.historic) return;

    if (wasUploadedToEmr) {
      return (
        <Popup
          position="top center"
          content="Uploaded to EMR"
          trigger={<Icon name="caret square up outline" color="olive" />}
        />
      );
    } else {
      return (
        <Popup
          position="top center"
          content="Not uploaded to EMR"
          trigger={<Icon name="caret square up outline" color="orange" />}
        />
      );
    }
  };

  return (
    <>
      {visibleVaccination && (
        <Card color="olive">
          <Card.Header
            style={isRecentImmunization() ? newImmunizationStyle : null}
          >
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column verticalAlign="middle" floated="left">
                  {isRecentImmunization() && (
                    <span style={{ marginLeft: '0.5rem' }}>Recent</span>
                  )}
                </Grid.Column>
                <Grid.Column
                  style={{
                    display: 'flex',
                    margin: '0.5rem',
                    justifyContent: 'flex-end',
                  }}
                >
                  {isRecentImmunization() && (
                    <Icon
                      name="trash"
                      color="red"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        selectVaccinationToDelete(visibleVaccination.id || '')
                      }
                    />
                  )}

                  {renderUploadedToEmrIcon()}

                  {!visibleVaccination.historic && (
                    <Popup
                      position="top center"
                      trigger={
                        <Icon
                          inverted
                          name="edit"
                          color="blue"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setOpenEditModal(true)}
                        />
                      }
                      content="Edit"
                    />
                  )}

                  {visibleVaccination.historic ? (
                    <Popup
                      position="top center"
                      content="External"
                      trigger={<Icon name="info circle" color="teal" />}
                    />
                  ) : null}

                  {shouldShowMorInfoButton ? (
                    <Popup
                      content={
                        <ImmunizationDetail
                          visibleVaccination={visibleVaccination}
                        />
                      }
                      on="click"
                      trigger={
                        <Icon
                          style={{ cursor: 'pointer' }}
                          name="eye"
                          color="grey"
                        />
                      }
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Header>

          <Card.Content style={{ padding: '0.5rem 0.3rem' }}>
            <Card.Header
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '12px',
              }}
            >
              {immunization.vaccineType}
            </Card.Header>

            <Card.Meta>{visibleVaccination.vaccine?.name}</Card.Meta>
            <Card.Description style={{ margin: '0' }}>
              {formatDateToMMDDYYYYV2(String(givenAt))}
            </Card.Description>
          </Card.Content>
        </Card>
      )}
      <EditImmunizationModal
        immunization={visibleVaccination}
        open={openEditModal}
        onOpen={() => setOpenEditModal(true)}
        onClose={() => setOpenEditModal(false)}
      />
    </>
  );
};

export default ImmunizationCardWeb;
