import { useCallback, useMemo, useState } from 'react';
import {
  LSHANDLER_KEYS,
  useApplicationState,
  useLSHandler,
  usePractice,
} from '@bluefox/contexts';
import { getFirstObjectParamOrSecondObjectParamOrEmptyObject } from '@bluefox/lib/objectsSync';

import { toast } from 'react-semantic-toasts';
import {
  Accordion,
  Button,
  Dimmer,
  Icon,
  Loader,
  Menu,
  Message,
  Modal,
} from 'semantic-ui-react';
import InventoryAdjustmentTable from '@screens/inventory/InventoryTable';
import {
  Inventory,
  VaccineWithInventory,
  VaccinesStockData,
} from '@bluefox/models/Inventory';
import { useMutation, useQuery } from '@apollo/client';
import { ReceivedInventoryQuery } from '@bluefox/graphql/inventory';
import { useHistory } from 'react-router-dom';
import {
  AdjustmentKind,
  AdjustmentReasons,
  InventoryAdjustment as InventoryAdjustmentModel,
  InventoryAdjustmentData,
  InventoryAdjustmentDetail,
  InventoryAdjustmentDetailStatuses,
  InventoryAdjustmentStatuses,
  InventoryAdjustmentTypes,
} from '@bluefox/models/InventoryAdjustment';
import {
  GetNotAppliedAdjustmentsQuery,
  InventoryAdjustmentMutation,
} from '@bluefox/graphql/inventoryAdjustment';

type InventoryDataToSend = Partial<Inventory> & {
  lastAdjustmentDate: Date;
  expiration: string;
};

function replaceInventory(
  inventories: InventoryAdjustmentData[],
  newInventory: InventoryAdjustmentData
) {
  return inventories.map((inv) =>
    inv.id !== newInventory?.id ? inv : newInventory
  );
}

function buildAdjustmentData(
  vaccines: VaccineWithInventory[] | undefined,
  isPrivate: boolean,
  isExpired: boolean
): InventoryAdjustmentData[] {
  const allInventories = vaccines
    ?.map((vaccine) =>
      vaccine.inventory.map(
        (inventory) =>
          ({
            name: vaccine.name,
            isPrivate,
            isExpired,
            id: inventory.id,
            lot: inventory.lot,
            expiration: inventory.expiration,
            doses: inventory.doses,
            lastAdjustmentDate: inventory.lastAdjustmentDate,
            wasAdjusted: false,
            vaccineId: inventory.vaccineId,
          }) as unknown as InventoryAdjustmentData
      )
    )
    .flat();
  return allInventories || [];
}

function synLsAndData(
  data: InventoryAdjustmentData[],
  lsData: InventoryAdjustmentData[]
): InventoryAdjustmentData[] {
  /**
   * Map every data object with their corresponding data in  the
   * local storage. Override 'wasConfirmed', 'wasAdjusted',
   * 'newDoses', and so on values, from the local storage object
   * in case it exists.
   */
  return data?.map((srcData) => {
    const lsd = lsData?.find((lsd) => srcData.id === lsd.id);
    if (!lsd) return srcData;
    return {
      ...srcData,
      ...getFirstObjectParamOrSecondObjectParamOrEmptyObject(
        'wasConfirmed',
        lsd,
        srcData
      ),
      ...getFirstObjectParamOrSecondObjectParamOrEmptyObject(
        'wasAdjusted',
        lsd,
        srcData
      ),
      ...getFirstObjectParamOrSecondObjectParamOrEmptyObject(
        'newDoses',
        lsd,
        srcData
      ),
      ...getFirstObjectParamOrSecondObjectParamOrEmptyObject(
        'adjustmentReason',
        lsd,
        srcData
      ),
      ...getFirstObjectParamOrSecondObjectParamOrEmptyObject(
        'newAdjustmentDate',
        lsd,
        srcData
      ),
      ...getFirstObjectParamOrSecondObjectParamOrEmptyObject(
        'comment',
        lsd,
        srcData
      ),
    } as InventoryAdjustmentData;
  });
}

const EXPIRED_INVENTORY_ACCORDION_INDEX = 0;
const INVENTORY_ACCORDION_INDEX = 1;

interface InventoryAdjustmentProps {
  kind: AdjustmentKind;
}
const InventoryAdjustment = (props: InventoryAdjustmentProps) => {
  const { kind } = props;
  const { isPrivate, lsAdjustmentDataKey } = useMemo(() => {
    const isPrivate = kind === AdjustmentKind.PRIVATE;
    const lsAdjustmentDataKey = isPrivate
      ? LSHANDLER_KEYS.ADJUSTMENT_DATA_PRIVATE_LS_KEY
      : LSHANDLER_KEYS.ADJUSTMENT_DATA_VFC_LS_KEY;
    return {
      isPrivate,
      lsAdjustmentDataKey,
    };
  }, [kind]);

  const practice = usePractice();
  const lsHandler = useLSHandler();
  const history = useHistory();
  const { session } = useApplicationState();

  // STATES
  const [accordionsOpenIndexes, setAccordionsOpenIndexes] = useState<number[]>(
    []
  );
  const [inventory, setInventory] = useState<InventoryAdjustmentData[]>([]);
  const [expiredInventory, setExpiredInventory] = useState<
    InventoryAdjustmentData[]
  >([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showInventoryUpdatedModal, setShowInventoryUpdatedModal] =
    useState(false);
  const [showCancelProcessModal, setShowCancelProcessModal] = useState(false);
  // STATES

  const [adjustInventory] = useMutation(InventoryAdjustmentMutation);

  const adjustmentHandler = useCallback(
    (adjustmentData: InventoryAdjustmentData) => {
      let newInventory = inventory;
      let newExpiredInventory = expiredInventory;

      if (!adjustmentData.isExpired) {
        setInventory((prevValue) => {
          newInventory = replaceInventory(prevValue, adjustmentData);
          return newInventory;
        });
      }
      if (adjustmentData.isExpired) {
        setExpiredInventory((prevValue) => {
          newExpiredInventory = replaceInventory(prevValue, adjustmentData);
          return newExpiredInventory;
        });
      }
      lsHandler.setItem(
        lsAdjustmentDataKey,
        JSON.stringify({
          inventoryData: newInventory,
          expiredInventoryData: newExpiredInventory,
        })
      );
    },
    [expiredInventory, inventory, lsAdjustmentDataKey, lsHandler]
  );

  const validateProcess = useCallback((): boolean => {
    /**
     * The user doesn't need to validate all expired inventories to continue,
     * but they have to validate non-expired inventories.
     */
    const inventoryFinished =
      inventory.length === 0 ||
      inventory.every(
        (inventoryData) =>
          inventoryData.wasAdjusted || inventoryData.wasConfirmed
      );
    return inventoryFinished;
  }, [inventory]);

  const onClickTitleHandler = useCallback(
    (currentIndex: number) => {
      const isOpenAccordion = accordionsOpenIndexes.includes(currentIndex);
      if (isOpenAccordion) {
        setAccordionsOpenIndexes((prevValue) =>
          prevValue.filter(
            (accordionsOpenIndex) => accordionsOpenIndex !== currentIndex
          )
        );
        return;
      }
      setAccordionsOpenIndexes((prevValue) => [...prevValue, currentIndex]);
    },
    [accordionsOpenIndexes]
  );

  const setAdjustmentData = useCallback(
    (data: VaccinesStockData | undefined) => {
      const lsData = lsHandler.getItem(lsAdjustmentDataKey);
      const lsAdjustmentProcessData = lsData ? JSON.parse(lsData) : {};
      const lsInventoryData: InventoryAdjustmentData[] =
        lsAdjustmentProcessData?.inventoryData || [];
      const lsExpiredInventoryData: InventoryAdjustmentData[] =
        lsAdjustmentProcessData?.expiredInventoryData || [];

      const vaccines = data?.vaccines
        .map((v) => {
          return {
            ...v,
            inventory: v.inventory.filter((i) => (isPrivate ? !i.vfc : i.vfc)),
            stock: v.inventory
              .filter((i) => (isPrivate ? !i.vfc : i.vfc))
              .reduce((accum, inventory) => accum + inventory.doses, 0),
          } as VaccineWithInventory;
        })
        .filter((v) => v.inventory.length > 0);

      const expiredVaccines = data?.expiredVaccines
        .map((v) => {
          return {
            ...v,
            inventory: v.inventory.filter((i) => (isPrivate ? !i.vfc : i.vfc)),
            stock: v.inventory
              .filter((i) => (isPrivate ? !i.vfc : i.vfc))
              .reduce((accum, inventory) => accum + inventory.doses, 0),
          } as VaccineWithInventory;
        })
        .filter((v) => v.inventory.length > 0);

      const inventoryBuildAdjustmentData: InventoryAdjustmentData[] =
        buildAdjustmentData(vaccines, isPrivate, false);

      const expiredBuildAdjustmentData: InventoryAdjustmentData[] =
        buildAdjustmentData(expiredVaccines, isPrivate, false);

      const inventoryData: InventoryAdjustmentData[] = synLsAndData(
        inventoryBuildAdjustmentData,
        lsInventoryData
      );

      const expiredInventoryData: InventoryAdjustmentData[] = synLsAndData(
        expiredBuildAdjustmentData,
        lsExpiredInventoryData
      );

      lsHandler.setItem(
        lsAdjustmentDataKey,
        JSON.stringify({ inventoryData, expiredInventoryData })
      );
      setInventory(inventoryData);
      setExpiredInventory(expiredInventoryData);
    },
    [isPrivate, lsAdjustmentDataKey, lsHandler]
  );

  const onClickRestartProcessButton = useCallback(
    (data?: VaccinesStockData) => {
      lsHandler.removeItem(lsAdjustmentDataKey);
      setShowCancelProcessModal(false);
      setAdjustmentData(data);
    },
    [lsAdjustmentDataKey, lsHandler, setAdjustmentData]
  );

  const onClickCancelProcessButton = useCallback(() => {
    lsHandler.removeItem(lsAdjustmentDataKey);
    setShowCancelProcessModal(false);
    history.push(`/${practice.handler}/inventory`);
  }, [history, lsAdjustmentDataKey, lsHandler, practice.handler]);

  const onClickFinishButton = useCallback(() => {
    if (!validateProcess()) {
      toast({
        title: 'Please check all Inventory Items',
        type: 'error',
        time: 2000,
      });
      return;
    }
    setShowConfirmationModal(true);
  }, [validateProcess]);

  const updateInventory = useCallback(async () => {
    setShowConfirmationModal(false);
    const nowTime = new Date();
    const expiredInventoryUpdated = expiredInventory.filter(
      (i) => i.wasAdjusted || i.wasConfirmed
    );

    const inventoriesToUpdate = [...inventory, ...expiredInventoryUpdated]
      .filter((inv) => !inv.wasConfirmed)
      .map(
        (inv) =>
          ({
            id: inv.id,
            doses: inv.newDoses ?? inv.doses,
            lastAdjustmentDate: inv.newAdjustmentDate || nowTime,
            lastAdjustmentReason: inv.adjustmentReason || '',
            lot: inv.lot,
            practiceId: practice.id,
            vaccineId: inv.vaccineId,
            expiration: inv.expiration,
          }) as InventoryDataToSend
      );

    const detailsToUpdate = [...inventory, ...expiredInventoryUpdated].map(
      (inv) => {
        let type: InventoryAdjustmentTypes;
        type =
          inv.adjustmentReason !== AdjustmentReasons.EXPIRED
            ? InventoryAdjustmentTypes.ADJUSTMENT
            : InventoryAdjustmentTypes.REMOVAL;
        if (inv.wasConfirmed) {
          type = InventoryAdjustmentTypes.CONFIRMATION;
        }
        return {
          newDoses: inv.newDoses || 0,
          currentDoses: inv.doses,
          reason: inv.adjustmentReason || AdjustmentReasons.MISSING_VACCINE,
          type,
          comment: inv.comment,
          status: InventoryAdjustmentDetailStatuses.PENDING,
          inventoryId: inv.id || '',
          statusLog: [
            {
              account: session?.account,
              status: InventoryAdjustmentDetailStatuses.PENDING,
              updatedAt: nowTime,
              inventoryId: inv.id || '',
              newDoses: inv.newDoses || 0,
              currentDoses: inv.doses,
            },
          ],
        } as InventoryAdjustmentDetail;
      }
    );

    const inventoryAdjustment: InventoryAdjustmentModel = {
      practiceId: practice.id,
      vfc: kind === AdjustmentKind.VFC,
      status: InventoryAdjustmentStatuses.PENDING,
      inventoryAdjustmentDetails: { data: detailsToUpdate },
      statusLog: [
        {
          account: session?.account,
          status: InventoryAdjustmentDetailStatuses.PENDING,
          updatedAt: nowTime,
        },
      ],
    };

    try {
      adjustInventory({
        variables: { data: inventoriesToUpdate, inventoryAdjustment },
        refetchQueries: [
          {
            query: ReceivedInventoryQuery,
            variables: {
              likeQuery: `%%`,
              typesFilter: {},
            },
          },
          {
            query: GetNotAppliedAdjustmentsQuery,
            variables: {
              id: practice.id,
            },
          },
        ],
      });
      lsHandler.removeItem(lsAdjustmentDataKey);
      setShowInventoryUpdatedModal(true);
    } catch (e) {
      toast({
        title: 'Error trying to adjust inventory',
        type: 'error',
        time: 1000,
      });
    }
  }, [
    adjustInventory,
    expiredInventory,
    inventory,
    kind,
    lsAdjustmentDataKey,
    lsHandler,
    practice.id,
    session?.account,
  ]);

  const { loading, error, data } = useQuery<VaccinesStockData>(
    ReceivedInventoryQuery,
    {
      variables: {
        likeQuery: `%%`,
        typesFilter: {},
      },
      onCompleted: (data) => setAdjustmentData(data),
    }
  );

  return (
    <>
      {loading && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
      {error && (
        <Message error>
          Failed to get Adjusment Information. Error: {error?.message}
        </Message>
      )}
      {data && (
        <>
          <Menu borderless>
            <Menu.Item position="right">
              <Button
                content="Finish"
                color="olive"
                icon="check"
                onClick={onClickFinishButton}
              />
            </Menu.Item>
            <Menu.Menu>
              <Menu.Item position="right">
                <Button
                  negative
                  onClick={() => {
                    setShowCancelProcessModal(true);
                    lsHandler.removeItem(lsAdjustmentDataKey);
                  }}
                >
                  {' '}
                  Cancel
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          <Accordion exclusive={false} fluid styled>
            {expiredInventory?.length > 0 && (
              <>
                <Accordion.Title
                  onClick={() =>
                    onClickTitleHandler(EXPIRED_INVENTORY_ACCORDION_INDEX)
                  }
                  active={accordionsOpenIndexes.includes(
                    EXPIRED_INVENTORY_ACCORDION_INDEX
                  )}
                >
                  <Icon name="dropdown" /> Expired Inventory
                </Accordion.Title>

                <Accordion.Content
                  active={accordionsOpenIndexes.includes(
                    EXPIRED_INVENTORY_ACCORDION_INDEX
                  )}
                >
                  <InventoryAdjustmentTable
                    makeAdjustment={adjustmentHandler}
                    vaccines={expiredInventory}
                  />
                </Accordion.Content>
              </>
            )}

            {inventory?.length > 0 && (
              <>
                <Accordion.Title
                  onClick={() => onClickTitleHandler(INVENTORY_ACCORDION_INDEX)}
                  active={accordionsOpenIndexes.includes(
                    INVENTORY_ACCORDION_INDEX
                  )}
                >
                  <Icon name="dropdown" />
                  Inventory
                </Accordion.Title>
                <Accordion.Content
                  active={accordionsOpenIndexes.includes(
                    INVENTORY_ACCORDION_INDEX
                  )}
                >
                  <InventoryAdjustmentTable
                    makeAdjustment={adjustmentHandler}
                    vaccines={inventory}
                  />
                </Accordion.Content>
              </>
            )}
          </Accordion>
        </>
      )}

      <Modal
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        open={showConfirmationModal}
        size="large"
        closeIcon
      >
        <Modal.Header>Manual Inventory Adjustment Warning</Modal.Header>
        <Modal.Content>
          A manual inventory adjustment involves directly modifying product
          quantities. This will affect the recorded stock levels in the system.
          By performing this adjustment, you are taking responsibility for the
          accuracy of the entered information. Make sure you have the proper
          authorization to carry out this change.
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => {
              setShowConfirmationModal(false);
            }}
          />
          <Button
            primary
            type="submit"
            content="Confirm"
            onClick={updateInventory}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => {
          lsHandler.removeItem(lsAdjustmentDataKey);
          setShowInventoryUpdatedModal(false);
          history.push(`/${practice.handler}/inventory`);
        }}
        open={showInventoryUpdatedModal}
        size="mini"
        closeIcon
      >
        <Modal.Header>Inventory Updated</Modal.Header>
        <Modal.Actions>
          <Button
            type="button"
            content="Continue to Inventory Page"
            onClick={() => {
              lsHandler.removeItem(lsAdjustmentDataKey);
              setShowInventoryUpdatedModal(false);
              history.push(`/${practice.handler}/inventory`);
            }}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => {
          setShowCancelProcessModal(false);
        }}
        open={showCancelProcessModal}
        size="large"
        closeIcon
      >
        <Modal.Header>Cancel Inventory Adjustment?</Modal.Header>
        <Modal.Content>
          <p>
            <b>Cancel and Go Back:</b>
          </p>
          Choosing this option will cancel the adjustment process and navigate
          you back to the inventory page. Any changes you've made so far will
          not be saved.
          <p>
            <b>Restart Process:</b>
          </p>
          Selecting this option will restart the adjustment process from the
          beginning while keeping you on this page. Any changes you've made so
          far will be discarded.
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancel and continue to Inventory Page"
            onClick={onClickCancelProcessButton}
          />
          <Button
            type="button"
            content="Restart Process"
            onClick={() => onClickRestartProcessButton(data)}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default InventoryAdjustment;
