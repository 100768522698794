import { useMutation } from '@apollo/client';
import { DeleteTicketMutation } from '@bluefox/graphql/tickets';
import { humanizeText } from '@bluefox/lib/humanize';
import { toast } from 'react-semantic-toasts';
import { Button, Label, Popup, Table } from 'semantic-ui-react';

export type FormattedVaccineOrder = {
  doses: string;
  handler: string;
  id: string;
  ndc: string;
  practice: string;
  status: string;
  type: string;
  vaccine: string;
  vaccineid: string;
  notes: string;
};

type PlacedOrdersRowProps = {
  vaccineOrder: FormattedVaccineOrder;
  onRemove: () => void;
};

const PlacedOrdersRow = ({ vaccineOrder, onRemove }: PlacedOrdersRowProps) => {
  const [removeTicket] = useMutation(DeleteTicketMutation);

  const handleRemove = async () => {
    try {
      await removeTicket({
        variables: {
          ticketId: vaccineOrder.id,
        },
      });

      toast({
        title: 'Order has been deleted.',
        type: 'success',
        time: 2000,
      });
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    } finally {
      onRemove();
    }
  };

  return (
    <Table.Row key={vaccineOrder.id}>
      <Table.Cell>{vaccineOrder.vaccine}</Table.Cell>
      <Table.Cell>{vaccineOrder.doses}</Table.Cell>
      <Table.Cell>{vaccineOrder.type}</Table.Cell>
      <Table.Cell>
        <Label
          basic
          content={humanizeText(vaccineOrder.status, {
            capitalize: 'first',
          })}
        />
      </Table.Cell>
      <Table.Cell>
        {vaccineOrder.notes && (
          <Popup
            on="click"
            position="top right"
            trigger={<Button size="mini" content="Notes" />}
            content={vaccineOrder.notes}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex' }}>
          <Popup
            content="Remove"
            trigger={
              <Button basic size="small" icon="trash" onClick={handleRemove} />
            }
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default PlacedOrdersRow;
