import { Page, StyleSheet } from '@react-pdf/renderer';
import Body from './Body';
import { PracticeClaimsTableProps } from './PracticeClaimsTable';
import Header from './Header';
import Footer, { FooterProps } from './Footer';

const pageStyles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'black',
    padding: '40 0',
    paddingBottom: 92, // Leave enought space for the footer
  },
});

export type BillingReportPageProps = {
  claimTables: PracticeClaimsTableProps[];
  footer: FooterProps;
};

const BillingReportPage = ({
  claimTables,
  footer,
  version,
}: BillingReportPageProps & { version?: number }) => {
  return (
    <Page size="A4" style={pageStyles.page} wrap>
      <Header />
      <Body claimTables={claimTables} version={version} />
      <Footer {...footer} />
    </Page>
  );
};

export default BillingReportPage;
