import { useQuery } from '@apollo/client';
import { usePractice } from '@bluefox/contexts';
import { PP_InventoryAdjustmentsQuery } from '@bluefox/graphql/inventoryAdjustment';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';
import { DateFormats } from '@bluefox/models/Dates';
import {
  InventoryAdjustment,
  InventoryAdjustmentStatuses,
} from '@bluefox/models/InventoryAdjustment';
import FullLayout from '@components/FullLayout';
import moment from 'moment-timezone';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Header,
  Icon,
  Message,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react';

export function renderInventoryAdjustmentUser(
  statusLog: Array<any> | undefined
) {
  const firstLog = statusLog?.filter(
    (s) => s.status === InventoryAdjustmentStatuses.PENDING
  )?.[0];
  return firstLog
    ? `${firstLog.account?.firstName} ${firstLog.account?.lastName}`
    : '-';
}

export function renderInventoryAdjustmentStatus(
  status?: InventoryAdjustmentStatuses
) {
  return status === InventoryAdjustmentStatuses.APPLIED
    ? 'Finalized'
    : 'Under Review';
}

const ENTRIES_PER_PAGE = 10;

const InventoryAdjustmentHistory = () => {
  const practice = usePractice();
  const { id: practiceId } = practice;
  const [activePage, setActivePage] = useState(1);
  const { loading, data, error } = useQuery<{
    adjustments: InventoryAdjustment[];
    adjustmentsAgregate: { aggregate: { count: number } };
  }>(PP_InventoryAdjustmentsQuery, {
    variables: {
      where: {
        practiceId: { _eq: practiceId },
      },
      limit: ENTRIES_PER_PAGE,
      offset: ENTRIES_PER_PAGE * (activePage - 1),
    },
    fetchPolicy: 'network-only',
    skip: !isStringNotEmpty(practiceId),
  });

  return (
    <FullLayout
      loading={loading}
      path={[
        { text: 'Inventory', to: 'inventory' },
        { text: 'Inventory Adjustment', to: 'inventory/adjustment' },
        { text: 'History' },
      ]}
    >
      <Segment padded basic>
        <Header as="h1">
          <Icon name="list" />
          Inventory Adjustment History
        </Header>
      </Segment>
      <Container>
        {error && <Message error>{error.message}</Message>}
        {data && (
          <Table celled structured selectable>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Inventory</Table.HeaderCell>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Details</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data?.adjustments && data.adjustments.length > 0 ? (
                <>
                  {data?.adjustments.map((adjustment) => {
                    return (
                      <Table.Row key={adjustment.id} textAlign="center">
                        <Table.Cell>
                          {moment(adjustment.createdAt).format(
                            DateFormats.DATE_WITH_TIME_SLASH
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {adjustment.vfc ? 'VFC' : 'Private'}
                        </Table.Cell>
                        <Table.Cell>
                          {renderInventoryAdjustmentUser(adjustment.statusLog)}
                        </Table.Cell>
                        <Table.Cell>
                          {renderInventoryAdjustmentStatus(adjustment.status)}
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            to={`/${practice.handler}/inventory/adjustment/${adjustment.id}/`}
                          >
                            See Details
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                  <Table.Row>
                    <Table.Cell colSpan={5} textAlign="center">
                      <Pagination
                        totalPages={Math.ceil(
                          (data?.adjustmentsAgregate?.aggregate?.count || 0) /
                            ENTRIES_PER_PAGE
                        )}
                        activePage={activePage}
                        onPageChange={(_, { activePage }) =>
                          setActivePage(activePage as number)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                </>
              ) : (
                <Table.Cell colSpan={5}>
                  <Message>No adjustment found.</Message>
                </Table.Cell>
              )}
            </Table.Body>
          </Table>
        )}
      </Container>
    </FullLayout>
  );
};

export default InventoryAdjustmentHistory;
