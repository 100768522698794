import React, { useMemo, useState } from 'react';
import { Button, Card, Form, Modal, Popup, Table } from 'semantic-ui-react';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { Vaccine } from '@bluefox/models/Vaccine';
import { InsertVaccineRequestsMutation } from '@bluefox/graphql/tickets';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { usePractice } from '@bluefox/contexts';
import { InventoryStatus } from '@bluefox/models/Inventory';

type FormattedData = {
  doses: string;
  handler: string;
  id: string;
  ndc: string;
  practice: string;
  status: string;
  type: string;
  vaccine: string;
  vaccineid: string;
  notes: string;
};

type VaccineRequestsFormProps = {
  open: boolean;
  onOpen: (value: boolean) => void;
  onSave: () => void;
};

const onlyIntegers = /^[0-9]+$/;

const VaccineRequestsForm = ({
  open,
  onOpen,
  onSave,
}: VaccineRequestsFormProps) => {
  const practice = usePractice();

  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();
  const [doses, setDoses] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [vaccineRequests, setVaccineRequests] = useState<Record<string, any>[]>(
    []
  );

  const [saveVaccineRequests] = useMutation(InsertVaccineRequestsMutation);

  const formattedData = useMemo(
    () =>
      vaccineRequests.map((item) =>
        item.detail.fields.reduce(
          (acc: any, { title, detail }: { title: string; detail: string }) => {
            acc[title.toLocaleLowerCase()] = detail;
            return acc;
          },
          {
            id: item.id,
            status: item.status,
            notes:
              item.detail.fields.find(
                (field: any) => field.title.toLowerCase() === 'notes'
              )?.detail || '',
          }
        )
      ),
    [vaccineRequests]
  );

  const cleanAndClose = () => {
    onOpen(false);
    setSelectedVaccine(undefined);
    setDoses('');
    setVaccineRequests([]);
    setNotes(undefined);
  };

  const handleRemoveVaccineRequest = (index: number) => {
    const _vaccineRequests = [
      ...vaccineRequests.slice(0, index),
      ...vaccineRequests.slice(index + 1),
    ];
    setVaccineRequests(_vaccineRequests);
  };

  const handleAddOrder = () => {
    if (!doses || parseInt(doses) <= 0) {
      return toast({
        title: "Selected quantity of doses can't be 0",
        type: 'error',
        time: 5000,
      });
    }

    setVaccineRequests((prev) => [
      ...prev,
      {
        practiceId: practice.id,
        status: 'requested',
        type: 'Vaccine_order',
        detail: {
          fields: [
            {
              title: 'Practice',
              detail: practice.name,
            },
            {
              title: 'Handler',
              detail: practice.handler,
            },
            {
              title: 'VaccineId',
              detail: selectedVaccine?.id,
            },
            {
              title: 'Vaccine',
              detail: selectedVaccine?.name,
            },
            {
              title: 'NDC',
              detail: selectedVaccine?.saleNdc,
            },
            {
              title: 'Doses',
              detail: doses,
            },
            {
              title: 'Type',
              detail: 'Private',
            },
            {
              title: 'Notes',
              detail: notes?.trim(),
            },
          ],
        },
      },
    ]);
  };

  const handleSubmit = async () => {
    try {
      await saveVaccineRequests({
        variables: {
          objects: vaccineRequests,
        },
      });

      toast({
        title: 'Vaccine(s) order saved successfully',
        type: 'success',
        time: 2000,
      });

      try {
        await onSave();
      } catch (error) {
        toast({
          title: `Failed to fetch vaccine(s) order. Error: ${error}`,
          type: 'error',
          time: 5000,
        });
      } finally {
        cleanAndClose();
      }
    } catch (error) {
      toast({
        title: `Failed to save vaccine(s) order. Error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  };

  return (
    <Modal
      onClose={cleanAndClose}
      onOpen={() => onOpen(true)}
      open={open}
      trigger={
        <Button
          primary
          size="small"
          icon="cart"
          content="Place order"
          onClick={(e) => {
            //stopPropagation for the "Place Order" button. So when it's clicked, the "Placed orders" accordion desn't open.
            e.stopPropagation();
          }}
        />
      }
      onClick={(e: any) => {
        //stopPropagation for the "Place Order Form Modal". So when it's clicked, the "Placed orders" accordion desn't open or close.
        e.stopPropagation();
      }}
    >
      <Modal.Header>Create private vaccine(s) order</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form key="vaccine-request-form" widths="equal">
            <Form.Group>
              <Form.Field required>
                <label>Vaccine</label>
                <VaccinePicker
                  dropdownProps={{
                    placeholder: 'Vaccine',
                  }}
                  onChange={({ vaccine }) => setSelectedVaccine(vaccine)}
                  value={selectedVaccine?.id}
                  extraFilters={{
                    inventories: {
                      status: { _eq: InventoryStatus.received },
                      vfc: { _eq: false },
                    },
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  required
                  label="Doses"
                  placeholder="Doses"
                  onKeyPress={(event: React.KeyboardEvent) => {
                    if (!onlyIntegers.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={doses}
                  onChange={(_, { value }) => {
                    setDoses(value);
                  }}
                />
              </Form.Field>

              <Popup
                content="Add to order"
                trigger={
                  <Button
                    type="button"
                    primary
                    icon="plus"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1.6rem',
                    }}
                    onClick={handleAddOrder}
                    disabled={!selectedVaccine || !doses}
                  />
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <Form.TextArea
                  label="Notes"
                  value={notes}
                  onChange={(_, { value }) => {
                    setNotes(value as string);
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          {!!formattedData.length && (
            <Card fluid>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Vaccine</Table.HeaderCell>
                    <Table.HeaderCell>NDC</Table.HeaderCell>
                    <Table.HeaderCell>Requested Doses</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {formattedData.map((item: FormattedData, index: number) => {
                    return (
                      <VaccineRequestsFormRow
                        key={index}
                        index={index}
                        removeVaccine={() => handleRemoveVaccineRequest(index)}
                        item={item}
                      />
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={cleanAndClose}>
          Cancel
        </Button>
        <Button
          primary
          form="vaccine-request-form"
          type="submit"
          content="Save"
          icon="save"
          onClick={handleSubmit}
          disabled={!vaccineRequests.length}
        />
      </Modal.Actions>
    </Modal>
  );
};

type VaccineRequestsFormRowProps = {
  index: number;
  removeVaccine: () => void;
  item: FormattedData;
};

const VaccineRequestsFormRow = ({
  index,
  removeVaccine,
  item,
}: VaccineRequestsFormRowProps) => {
  return (
    <Table.Row key={item.id}>
      <Table.Cell>{item.vaccine}</Table.Cell>
      <Table.Cell>{item.ndc}</Table.Cell>
      <Table.Cell>{item.doses}</Table.Cell>
      <Table.Cell>
        {item.notes && (
          <Popup
            on="click"
            position="top right"
            trigger={<Button size="mini" content="Notes" />}
            content={item.notes}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <Popup
          content="Remove"
          trigger={
            <Button
              size="mini"
              icon="trash"
              color="red"
              onClick={() => removeVaccine()}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default VaccineRequestsForm;
