import { Vaccination } from './Vaccination';
import { Practice, PracticeProfile } from '@bluefox/models/Practice';
import { PracticePatient } from '@bluefox/models/Patient';
import { VfcCriteria } from './Insurances';
import { PracticeAccount } from './Account';
import { Invoice, InvoiceStatus } from './Invoice';
import { Inventory } from './Inventory';
import { Address } from './Address';
import { InvoiceAndBillingReportPdfProps } from './pdf/InvoiceAndBillingReportPdf';
import { VaccinesPricing } from './VaccinePricing';

export enum BillingClaimStatus {
  SUBMITTED = 'submitted',
  ERROR = 'error',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CORRECTED = 'corrected',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  DENIED = 'denied',
  IN_MEDIATION = 'in_mediation',
  DISMISSED = 'dismissed',
  BILLED = 'billed',
  PENDING = 'pending',
  CLOSED = 'closed',
  TO_RESUBMIT = 'toresubmit',
  UNCLAIMED = 'unclaimed',
  ACKNOWLEDGED = 'acknowledged',
  TO_APPEAL = 'to_appeal',
  TO_CORRECT = 'to_correct',
  ALREADY_SUBMITTED = 'already_submitted',
  PARTIALLY_BILLED = 'partially_billed',
  TO_ANALYZE = 'to_analyze',
  READY_TO_REPORT = 'ready_to_report',
  CONFIRM_PAYMENT = 'confirm_payment',
  BORROWING = 'borrowing',
  DIRECT_CHARGE = 'direct_charge',
  WAITING_INFO = 'waiting_info',
  READY_TO_SUBMIT = 'ready_to_submit',
  GENERATED_837p = 'generated_837p',
  TO_CONFIRM = 'to_confirm', // When the notification is marked as resolved or reject, move the claim to “confirmed” status.
  CONFIRMED = 'confirmed', // When the notification is marked as resolved or reject, move the claim to “confirmed” status.
}

export enum UnclaimedReasons {
  INSURANCE_ERROR = 'insurance_error',
  MISSING_VACCINATION = 'missing_vaccination',
}

export interface CptCode {
  id?: string;
  claimId?: string;
  claim?: {
    vaccination: Vaccination;
  };
  cptCode: string;
  claimAmount: number;
  paidAmount: number;
  units: number;
  count?: number;
  note: {
    message?: string;
    unpaidReason?: {
      value: string;
      text: string;
    };
  };
  status?: string | null;
  compensationAmount?: number | null;
  inMediationAmount?: number | null;
  inMediationDueDate?: Date | null;
  resolution?: string | null;
  dismissedReason?: string | null;
  invoiceId?: string;
  invoice?: string;
  invoices?: {
    invoiceNo: string;
  };
  vaccinePricing?: VaccinesPricing;
}

export interface vaccinationCptCode {
  chargeAmount: number;
  count: number;
  cptCode: string;
  type: string;
}

export interface vaccinationCptCodeView {
  invoiceId?: string;
  id: string;
  claimId: string;
  claimAmount: number;
  compensationAmount: number;
  chargeAmount: number;
  count: number;
  cptCode: string;
  dismissedReason: string;
  inMediationAmount: number;
  inMediationDueDate: Date;
  note: {
    message: string;
    unpaidReason: {
      text: string;
      value: string;
    };
  };
  paidAmount: number;
  resolution: string;
  status: string;
  units: number;
  vaccinePricingCentsPerDose?: number;
}

interface NoteView {
  url: string;
  note: string;
}

interface MetadataView {
  insurance: {
    insuranceName: string;
    memberId: string;
    payerID: string;
    vfcCriteria: string;
    vfcEligible: boolean;
  };
  insuranceForPracticePortal?: {
    insuranceName: string;
    memberId: string;
    payerID: string;
    vfcCriteria: string;
    vfcEligible: boolean;
  };
  alreadySubmittedData?: string[];
}

interface ResponseError {
  code?: string;
  field?: string;
  location?: string;
  value?: string;
  description?: string;
}

export interface BillingClaimView {
  birthdate: string;
  checkDate: Date;
  checkNumber: string;
  claimControlNumber: string;
  claimId: string;
  dismissedReason: string;
  firstName: string;
  fullName: string;
  inMediationDueDate: Date;
  inventoryVfcCount: number;
  inventoryPrivateCount: number;
  lastName: string;
  note: NoteView;
  eobStatus: string;
  appealMethod: string;
  practiceId: string;
  practiceName: string;
  practiceNpi: string;
  practicePatientId: string;
  practiceProfile: PracticeProfile;
  prescriberFirstName: string;
  prescriberLastName: string;
  insuranceId: string;
  insuranceCompanyId: string;
  insuranceName: string;
  insuranceMemberId: string;
  insurancePayerId: string;
  insuranceVfcCriteria: string;
  insuranceVfcEligible: boolean;
  providerFirstName: string;
  providerLastName: string;
  rhcClaimNumber: string;
  status: BillingClaimStatus;
  totalClaimAmount: number;
  unclaimedReason: string;
  updateInsuranceMetadata: MetadataView;
  updateInsuranceSource: string;
  updateInsuranceStatus: string;
  updateLastNpi: string;
  updateLastEmployerId: string;
  updatedAt: Date;
  givenAt: Date;
  vaccinationsCptCodes: vaccinationCptCodeView[];
  inMediationReviewedAt: Date;
  inMediationDueDateTo: Date;
  inMediationDueDateFrom: Date;
  uploadedToEmrAt: Date;
  paymentReportedToEmrAt: Date;
  emrClaimNumber: string;
  practiceHandler: string;
  selfPay: boolean;
  revisionStatus: string;
  fieldToCorrect: string;
  prescriberId: string;
  tags: string[];
  updatedStatusApiAt: Date;
  borrowingStatus: string;
  directChargeStatus: string;
  directChargeReason: string;
  errors: ResponseError[];
  claim: BillingClaim;
  invoices: string[];
}

interface VaccinesView {
  name: string;
}

export interface PracticePortalBillingClaim {
  birthdate: Date;
  claimId: string;
  dismissedReason: string;
  firstName: string;
  fullName: string;
  inventoryVfcCount: number;
  inventoryPrivateCount: number;
  lastName: string;
  note: NoteView;
  practiceId: string;
  practiceName: string;
  practicePatientId: string;
  insuranceId: string;
  insuranceCompanyId: string;
  insuranceName: string;
  insuranceMemberId: string;
  insurancePayerId: string;
  insuranceVfcCriteria: string;
  insuranceVfcEligible: boolean;
  status: BillingClaimStatus;
  totalClaimAmount: number;
  unclaimedReason: string;
  createdAt: Date;
  updatedAt: Date;
  givenAt: Date;
  vaccinationsCptCodes: vaccinationCptCodeView[];
  revisionStatus: string;
  vaccines: VaccinesView[];
}

export interface BillingClaim {
  id: string;
  cptCodes: CptCode[];
  totalClaimAmount: number;
  controlNumber: string;
  claimControlNumber?: string;
  rhcClaimNumber?: string;
  status: BillingClaimStatus;
  note?: {
    note: string;
    url: string;
  };
  practice: Practice;
  vaccinations: Vaccination[];
  inMediationDueDate?: Date;
  inMediationReviewedAt?: Date;
  checkDate?: Date;
  updatedAt?: Date;
  //todo updates type
  updates?: any;
  lastUpdateFromApi?: any;
  updateNotes?: any;
  lastSubmittedUpdate?: any;
  lastUpdateWithInsurance?: any;
  reviewedAt?: Date;
  checkNumber?: string;
  practicePatient: PracticePatient;
  vaccinationsCptCodes?: vaccinationCptCode[];
  dismissedReason?: string;
  insuranceCompanyName?: string;
  insuranceVfcEligible?: boolean;
  revisionStatus?: ClaimRevisionStatus;
  selfPay?: boolean;
  patientAndInsuranceInfoReviewedAt?: Date;
  givenAt: Date;
  tags?: string[];
  eobStatus?: string;
  appealMethod?: string;
  fieldToCorrect?: string;
  borrowingStatus?: string;
  directChargeStatus?: string;
  directChargeReason?: string;
  uploadedToEmrAt?: Date;
  paymentReportedToEmrAt?: Date;
  emrClaimNumber: string;
  insurance?: [
    {
      insuranceCompanyName: string;
      memberId: string;
    },
  ];
}
export interface VfcClaim {
  id: string;
  cptCode: string;
  paidAmount: number;
  claim: {
    practicePatient: PracticePatient;
    updates: ClaimUpdate[];
    vaccinations: Vaccination[];
    status: string;
    selfPay?: boolean;
  };
}

export interface BillingInvoiceMessage {
  error: string;
}

export interface BillingInvoice {
  id: string;
  practice: Practice;
  date: Date;
  amount: number;
  billMethod: string;
  status: InvoiceStatus;
  message?: BillingInvoiceMessage;
  paymentDate: Date;
  dueDate: Date;
  description: string;
  claimCptCodes: CptCode[];
  metadata?: any;
  practicesData?: Partial<Practice>[];
  pdfData?: InvoiceAndBillingReportPdfProps;
}
export interface ClaimsForInvoice {
  claims: InvoiceClaim[];
  practices: Practice[];
  invoices: Invoice[];
}

export interface InvoiceClaim {
  amount: number;
  cptCode: string;
  quantity: number;
  vaccineId: string;
  vaccineName: string;
}

export interface ClaimUpdateMetadata {
  insurance?: {
    insuranceCompanyId?: string;
    memberId: string;
    insuranceName: string;
    payerID: string;
    cpid?: string;
    vfcEligible?: boolean;
    vfcCriteria?: VfcCriteria;
  };
  insuranceForPracticePortal?: {
    insuranceName: string;
    memberId: string;
    payerID: string;
    vfcCriteria: string;
    vfcEligible: boolean;
  };
  alreadySubmittedData?: string[];
  note?: {
    url: string;
    note: string;
  };
  dismissedReason?: string;
}

export interface ClaimUpdate {
  id: string;
  claimId: string;
  createdAt: Date;
  status: string;
  source: string;
  metadata?: ClaimUpdateMetadata;
  request?: object;
  response?: any;
  practiceAccount?: PracticeAccount;
}

export interface BillingClaimStatusMenuOption {
  key: string;
  text: string;
  value: BillingClaimStatus;
}

export const billingClaimStatusMenuOptions: BillingClaimStatusMenuOption[] = [
  {
    key: 'submitted',
    text: 'Submitted',
    value: BillingClaimStatus.SUBMITTED,
  },
  {
    key: 'error',
    text: 'Error',
    value: BillingClaimStatus.ERROR,
  },
  {
    key: 'toresubmit',
    text: 'To Resubmit',
    value: BillingClaimStatus.TO_RESUBMIT,
  },
  {
    key: 'accepted',
    text: 'Accepted CH',
    value: BillingClaimStatus.ACCEPTED,
  },
  {
    key: 'rejected',
    text: 'Rejected CH',
    value: BillingClaimStatus.REJECTED,
  },
  {
    key: 'corrected',
    text: 'Corrected CH',
    value: BillingClaimStatus.CORRECTED,
  },
  {
    key: 'paid',
    text: 'Paid',
    value: BillingClaimStatus.PAID,
  },
  {
    key: 'partially_paid',
    text: 'Partially Paid',
    value: BillingClaimStatus.PARTIALLY_PAID,
  },
  {
    key: 'denied',
    text: 'Denied',
    value: BillingClaimStatus.DENIED,
  },
  {
    key: 'inMediation',
    text: 'In mediation',
    value: BillingClaimStatus.IN_MEDIATION,
  },
  {
    key: 'dismissed',
    text: 'Dismissed',
    value: BillingClaimStatus.DISMISSED,
  },
  {
    key: 'billed',
    text: 'Billed',
    value: BillingClaimStatus.BILLED,
  },
  {
    key: 'pending',
    text: 'Pending',
    value: BillingClaimStatus.PENDING,
  },
  {
    key: 'closed',
    text: 'Closed',
    value: BillingClaimStatus.CLOSED,
  },
  {
    key: 'unclaimed',
    text: 'Unclaimed',
    value: BillingClaimStatus.UNCLAIMED,
  },
  {
    key: 'acknowledged',
    text: 'Acknowledged',
    value: BillingClaimStatus.ACKNOWLEDGED,
  },
  {
    key: 'toAppeal',
    text: 'To Appeal',
    value: BillingClaimStatus.TO_APPEAL,
  },
  {
    key: 'toCorrect',
    text: 'To Correct',
    value: BillingClaimStatus.TO_CORRECT,
  },
  {
    key: 'already_submitted',
    text: 'Already Submitted',
    value: BillingClaimStatus.ALREADY_SUBMITTED,
  },
  {
    key: 'partially_billed',
    text: 'Partially Billed',
    value: BillingClaimStatus.PARTIALLY_BILLED,
  },
  {
    key: 'toAnalyze',
    text: 'To Analyze',
    value: BillingClaimStatus.TO_ANALYZE,
  },
  {
    key: 'ready_to_report',
    text: 'Ready To Report',
    value: BillingClaimStatus.READY_TO_REPORT,
  },
  {
    key: 'confirm_payment',
    text: 'Confirm Payment',
    value: BillingClaimStatus.CONFIRM_PAYMENT,
  },
  {
    key: 'borrowing',
    text: 'Borrowing',
    value: BillingClaimStatus.BORROWING,
  },
  {
    key: 'direct_charge',
    text: 'Direct Charge',
    value: BillingClaimStatus.DIRECT_CHARGE,
  },
  {
    key: 'waiting_info',
    text: 'Waiting Info',
    value: BillingClaimStatus.WAITING_INFO,
  },
  {
    key: 'ready_to_submit',
    text: 'Ready To Submit',
    value: BillingClaimStatus.READY_TO_SUBMIT,
  },
  {
    key: 'generated_837p',
    text: 'Generated 837p',
    value: BillingClaimStatus.GENERATED_837p,
  },
];

export enum CptCodeUnpaidReasons {
  CHARGES_COVERED_CAPITATION_AGREE = 'charges_covered_capitation_agree',
  EXACT_DUPLICATE = 'exact_duplicate',
  CHARGE_EXCEEDS_FEE = 'charge_exceeds_fee',
  EXPENSES_INCURRED = 'expenses_incurred',
  NON_COVERED_CHARGES = 'non_covered_charges',
  CLAIM_NOT_COVERED = 'claim_not_covered',
  INFORMATION_INSUFFICIENT = 'information_insufficient',
  SERVICE_NOT_COVERED = 'service_not_covered',
  BENEFIT_INCLUDED = 'benefit_included',
  CLAIM_LACKS_INFORMATION = 'claim_lacks_information',
  PROVIDER_NOT_CONTRACTED = 'provider_not_contracted',
  PROCEDURE_CODE_REBUNDLED = 'procedure_code_rebundled',
  NOT_PARTICIPATING_PROVIDER_RULES = 'not_participating_provider_rules',
  SERVICE_NOT_IDENTIFIED = 'service_not_identified',
  OTHER = 'other',
  BLANK = '',
}

export const cptCodeUnpaidReasonOptions = [
  {
    key: 'blank',
    text: '',
    value: CptCodeUnpaidReasons.BLANK,
  },
  {
    key: 'chargesCoveredCapitationAgree',
    text: 'Charges are covered under a capitation agreement',
    value: CptCodeUnpaidReasons.CHARGES_COVERED_CAPITATION_AGREE,
  },
  {
    key: 'exactDuplicate',
    text: 'Exact duplicate claim/service',
    value: CptCodeUnpaidReasons.EXACT_DUPLICATE,
  },
  {
    key: 'chargeExceedsFee',
    text: 'Charge exceeds fee schedule/maximum allowable or contracted/legislated fee arrangement',
    value: CptCodeUnpaidReasons.CHARGE_EXCEEDS_FEE,
  },
  {
    key: 'expensesIncurred',
    text: 'Expenses incurred after coverage terminated',
    value: CptCodeUnpaidReasons.EXPENSES_INCURRED,
  },
  {
    key: 'nonCoveredCharges',
    text: 'Non-covered charge(s)',
    value: CptCodeUnpaidReasons.NON_COVERED_CHARGES,
  },
  {
    key: 'claimNotCovered',
    text: 'Claim/service not covered by this payer/contractor',
    value: CptCodeUnpaidReasons.CLAIM_NOT_COVERED,
  },
  {
    key: 'informationInsufficient',
    text: 'Information requested from the patient/insured/responsible party was insufficient',
    value: CptCodeUnpaidReasons.INFORMATION_INSUFFICIENT,
  },
  {
    key: 'serviceNotCovered',
    text: "This service/equipment/drug is not covered under the patient's current benefit plan",
    value: CptCodeUnpaidReasons.SERVICE_NOT_COVERED,
  },
  {
    key: 'benefitIncluded',
    text: 'The benefit for this service is included in the payment/allowance for another service/procedure',
    value: CptCodeUnpaidReasons.BENEFIT_INCLUDED,
  },
  {
    key: 'claimLacksInformation',
    text: 'Claim/service lacks information or has submission/billing error(s)',
    value: CptCodeUnpaidReasons.CLAIM_LACKS_INFORMATION,
  },
  {
    key: 'providerNotContracted',
    text: 'Provider is not contracted for your plan. The services were not pre certified, therefore not payment will be made',
    value: CptCodeUnpaidReasons.PROVIDER_NOT_CONTRACTED,
  },
  {
    key: 'procedureCodeRebundled',
    text: 'Procedure code rebundled',
    value: CptCodeUnpaidReasons.PROCEDURE_CODE_REBUNDLED,
  },
  {
    key: 'notParticipatingProviderRules',
    text: 'Not participating provider rules were used to process this claim',
    value: CptCodeUnpaidReasons.NOT_PARTICIPATING_PROVIDER_RULES,
  },
  {
    key: 'serviceNotIdentified',
    text: 'The related or qualifying claim/service was not identified on this claim',
    value: CptCodeUnpaidReasons.SERVICE_NOT_IDENTIFIED,
  },
  {
    key: 'other',
    text: 'Other',
    value: CptCodeUnpaidReasons.OTHER,
  },
];

export enum CptCodeStatus {
  PAID = 'paid',
  COMPENSATED = 'compensated',
  IN_MEDIATION = 'in_mediation',
  COMPENSATED_AND_IN_MEDIATION = 'compensated_and_in_mediation',
  DISMISSED = 'dismissed',
}

export enum CptResolutionStatus {
  NONE = 'none',
  PAID = 'paid',
  DENIED = 'denied',
}

export const cptResolutionStatusOptions = [
  {
    key: 'none',
    text: 'None',
    value: CptResolutionStatus.NONE,
  },
  {
    key: 'paid',
    text: 'Paid',
    value: CptResolutionStatus.PAID,
  },
  {
    key: 'denied',
    text: 'Denied',
    value: CptResolutionStatus.DENIED,
  },
];

export const claimDismissedOptions = [
  {
    key: 'all',
    text: 'All',
    value: 'all',
  },
  {
    key: 'properly_denied',
    text: 'Properly Denied',
    value: 'properly_denied',
  },
  {
    key: 'based_on_member_confirmed',
    text: 'Based On Member (confirmed)',
    value: 'based_on_member_confirmed',
  },
  {
    key: 'based_on_member_recovered',
    text: 'Based On Member (recovered)',
    value: 'based_on_member_recovered',
  },
  {
    key: 'based_on_member_to_review',
    text: 'Based On Member (to review)',
    value: 'based_on_member_to_review',
  },
  {
    key: 'uncorrectable_error',
    text: 'Uncorrectable Error',
    value: 'uncorrectable_error',
  },
  {
    key: 'business_decision',
    text: 'Business Decision',
    value: 'business_decision',
  },
  {
    key: 'duplication',
    text: 'Duplication',
    value: 'duplication',
  },
  {
    key: 'rebundled_code',
    text: 'Rebundled Code',
    value: 'rebundled_code',
  },
  {
    key: 'not_enrollment',
    text: 'Not Enrollment',
    value: 'not_enrollment',
  },
  {
    key: 'unspecified_reason',
    text: 'Unspecified Reason',
    value: 'unspecified_reason',
  },
];

export enum ClaimRevisionStatus {
  NEW = 'new',
  BILLABLE = 'billable',
  NOT_BILLABLE = 'not_billable',
}

export const claimRevisionStatusOptions = [
  {
    key: 'new',
    text: 'New',
    value: ClaimRevisionStatus.NEW,
  },
  {
    key: 'billable',
    text: 'Billable',
    value: ClaimRevisionStatus.BILLABLE,
  },
  {
    key: 'not_billable',
    text: 'Not Billable',
    value: ClaimRevisionStatus.NOT_BILLABLE,
  },
];

export enum ClaimReviewedStatus {
  REVIEWED = 'reviewed',
  NOT_REVIEWED = 'not_reviewed',
}

export const claimReviewedStatusOptions = [
  {
    key: ClaimReviewedStatus.REVIEWED,
    value: ClaimReviewedStatus.REVIEWED,
    text: 'Reviewed',
  },
  {
    key: ClaimReviewedStatus.NOT_REVIEWED,
    value: ClaimReviewedStatus.NOT_REVIEWED,
    text: 'Not Reviewed',
  },
];

export enum EobStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  DOWNLOADED = 'downloaded',
  NOT_FOUND = 'not_found',
  INCORRECTLY_DOWNLOADED = 'incorrectly_downloaded',
  WAITING_PORTAL = 'waiting_portal',
  NEW_SUBMITTED = 'new_submitted',
  ARCHIVED = 'archived',
}

export const eobStatusOptions = [
  {
    key: 'pending',
    text: 'Pending',
    value: EobStatus.PENDING,
  },
  {
    key: 'in_progress',
    text: 'In Progress',
    value: EobStatus.IN_PROGRESS,
  },
  {
    key: 'downloaded',
    text: 'Downloaded',
    value: EobStatus.DOWNLOADED,
  },
  {
    key: 'not_found',
    text: 'Not Found',
    value: EobStatus.NOT_FOUND,
  },
  {
    key: 'incorrectly_downloaded',
    text: 'Incorrectly downloaded',
    value: EobStatus.INCORRECTLY_DOWNLOADED,
  },
  {
    key: 'new_submitted',
    text: 'New Submitted',
    value: EobStatus.NEW_SUBMITTED,
  },
  {
    key: 'waiting_portal',
    text: 'Waiting Portal',
    value: EobStatus.WAITING_PORTAL,
  },
  {
    key: 'archived',
    text: 'Archived',
    value: EobStatus.ARCHIVED,
  },
];

export const eobStatusFilterOptions = [
  {
    key: 'all',
    text: 'All',
    value: 'all',
  },
  {
    key: 'pending',
    text: 'Pending',
    value: EobStatus.PENDING,
  },
  {
    key: 'in_progress',
    text: 'In Progress',
    value: EobStatus.IN_PROGRESS,
  },
  {
    key: 'downloaded',
    text: 'Downloaded',
    value: EobStatus.DOWNLOADED,
  },
  {
    key: 'not_found',
    text: 'Not Found',
    value: EobStatus.NOT_FOUND,
  },
  {
    key: 'incorrectly_downloaded',
    text: 'Incorrectly downloaded',
    value: EobStatus.INCORRECTLY_DOWNLOADED,
  },
  {
    key: 'new_submitted',
    text: 'New Submitted',
    value: EobStatus.NEW_SUBMITTED,
  },
  {
    key: 'waiting_portal',
    text: 'Waiting Portal',
    value: EobStatus.WAITING_PORTAL,
  },
  {
    key: 'archived',
    text: 'Archived',
    value: EobStatus.ARCHIVED,
  },
];

export const inProgressArray = [
  BillingClaimStatus.PENDING,
  BillingClaimStatus.SUBMITTED,
  BillingClaimStatus.ACCEPTED,
  BillingClaimStatus.ACKNOWLEDGED,
  BillingClaimStatus.CORRECTED,
  BillingClaimStatus.REJECTED,
  BillingClaimStatus.ALREADY_SUBMITTED,
  BillingClaimStatus.READY_TO_SUBMIT,
  BillingClaimStatus.GENERATED_837p,
];

export const inMediationArray = [
  BillingClaimStatus.IN_MEDIATION,
  BillingClaimStatus.TO_APPEAL,
  BillingClaimStatus.TO_CORRECT,
  BillingClaimStatus.TO_RESUBMIT,
  BillingClaimStatus.DENIED,
];

export const partiallyPaidAndInProgressArray = [
  BillingClaimStatus.PARTIALLY_PAID,
  BillingClaimStatus.PARTIALLY_BILLED,
];

export const paidArray = [
  BillingClaimStatus.PAID,
  BillingClaimStatus.BILLED,
  BillingClaimStatus.CLOSED,
];

export const deniedArray = [BillingClaimStatus.DISMISSED];

export const claimStatusesGroups = new Map<string, string[]>([
  ['inProgress', inProgressArray],
  ['inMediation', inMediationArray],
  ['partiallyPaidAndInProgress', partiallyPaidAndInProgressArray],
  ['paid', paidArray],
  ['denied', deniedArray],
]);

export const simplifiedClaimStatuses = new Map<string, string>([
  [BillingClaimStatus.PENDING, 'In Progress'],
  [BillingClaimStatus.SUBMITTED, 'In Progress'],
  [BillingClaimStatus.ACCEPTED, 'In Progress'],
  [BillingClaimStatus.ACKNOWLEDGED, 'In Progress'],
  [BillingClaimStatus.CORRECTED, 'In Progress'],
  [BillingClaimStatus.REJECTED, 'In Progress'],
  [BillingClaimStatus.IN_MEDIATION, 'In Mediation'],
  [BillingClaimStatus.TO_APPEAL, 'In Mediation'],
  [BillingClaimStatus.TO_CORRECT, 'In Mediation'],
  [BillingClaimStatus.TO_RESUBMIT, 'In Mediation'],
  [BillingClaimStatus.DENIED, 'In Mediation'],
  [BillingClaimStatus.PARTIALLY_PAID, 'Partially Paid and In Progress'],
  [BillingClaimStatus.PARTIALLY_BILLED, 'Partially Paid and In Progress'],
  [BillingClaimStatus.PAID, 'Paid'],
  [BillingClaimStatus.BILLED, 'Paid'],
  [BillingClaimStatus.CLOSED, 'Paid'],
  [BillingClaimStatus.DISMISSED, 'Denied'],
  [BillingClaimStatus.ALREADY_SUBMITTED, 'In Progress'],
  [BillingClaimStatus.READY_TO_SUBMIT, 'In Progress'],
  [BillingClaimStatus.GENERATED_837p, 'In Progress'],
]);

export const simplifiedClaimStatusesFunction = (
  status: string,
  vfcCondition: boolean,
  revisionStatus: string
) => {
  if (status === BillingClaimStatus.BILLED) {
    return 'Invoiced';
  }
  if (
    vfcCondition &&
    (status === BillingClaimStatus.PAID || status === BillingClaimStatus.CLOSED)
  ) {
    return 'Paid';
  } else if (
    !vfcCondition &&
    (status === BillingClaimStatus.BILLED ||
      (status === BillingClaimStatus.PAID &&
        revisionStatus === ClaimRevisionStatus.BILLABLE))
  ) {
    return 'Paid';
  } else if (
    !vfcCondition &&
    status === BillingClaimStatus.PAID &&
    revisionStatus === ClaimRevisionStatus.NEW
  ) {
    return 'In Progress';
  } else {
    return simplifiedClaimStatuses.get(status);
  }
};

export const simplifiedClaimStatusOptions = [
  { text: 'All status', value: 'allStatus' },
  { text: 'In Progress', value: 'inProgress' },
  { text: 'In Mediation', value: 'inMediation' },
  {
    text: 'Partially Paid and In Progress',
    value: 'partiallyPaidAndInProgress',
  },
  { text: 'Paid', value: 'paid' },
  { text: 'Denied', value: 'denied' },
];

export const invoiceStatusMenuOptions = [
  { key: 'pending', text: 'Pending', value: InvoiceStatus.PENDING },
  { key: 'processing', text: 'Processing', value: InvoiceStatus.PROCESSING },
  { key: 'error', text: 'Error', value: InvoiceStatus.ERROR },
  {
    key: 'error_stripe',
    text: 'Error Stripe',
    value: InvoiceStatus.ERROR_STRIPE,
  },
  { key: 'paid', text: 'Paid', value: InvoiceStatus.PAID },
  { key: 'discarded', text: 'Discarded', value: InvoiceStatus.DISCARDED },
];

export const invoiceStatusOptions = (status?: string) => {
  return [
    {
      key: 'pending',
      text: 'Pending',
      value: InvoiceStatus.PENDING,
      disabled:
        status === InvoiceStatus.DISCARDED ||
        status === InvoiceStatus.PROCESSING ||
        status === InvoiceStatus.ERROR_STRIPE,
    },
    {
      key: 'processing',
      text: 'Processing',
      value: InvoiceStatus.PROCESSING,
      disabled:
        status !== InvoiceStatus.PROCESSING && status !== InvoiceStatus.ERROR,
    },
    {
      key: 'paid',
      text: 'Paid',
      value: InvoiceStatus.PAID,
      disabled: status === InvoiceStatus.DISCARDED,
    },
    {
      key: 'error',
      text: 'Error',
      value: InvoiceStatus.ERROR,
      disabled:
        status !== InvoiceStatus.ERROR && status !== InvoiceStatus.PROCESSING,
    },
    {
      key: 'error_stripe',
      text: 'Error Stripe',
      value: InvoiceStatus.ERROR_STRIPE,
      disabled: true,
    },
    {
      key: 'discarded',
      text: 'Discarded',
      value: InvoiceStatus.DISCARDED,
      disabled:
        status === InvoiceStatus.PAID ||
        status === InvoiceStatus.PROCESSING ||
        status === InvoiceStatus.ERROR_STRIPE,
    },
  ];
};
interface IncomingPaymentUpdate {
  vfcEligible: boolean;
}

export interface IncomingPayment {
  id: string;
  status: string;
  vaccinations: Vaccination[];
  administrationCptCodes: CptCode[];
  inventoryCptCodes: CptCode[];
  updates: IncomingPaymentUpdate[];
}

export interface PrivateIncomingPayments {
  practiceId: string;
  patientCount: number;
  vaccCount: number;
  paidAmountAdm: number;
  paidAmountInv: number;
}

export interface VfcIncomingPayments {
  patientCount: number;
  vaccinationCount: number;
  paidAmountAdministration: number;
  paidAmountInventory: number;
}

export const toAppealOptions = [
  {
    key: 'by_call',
    text: 'By Call',
    value: 'by_call',
  },
  {
    key: 'by_portal',
    text: 'By Portal',
    value: 'by_portal',
  },
  {
    key: 'by_fax',
    text: 'By Fax',
    value: 'by_fax',
  },
  {
    key: 'send_more_info',
    text: 'Send More Info',
    value: 'send_more_info',
  },
];

export const toCorrectOptions = [
  {
    key: 'cpt_codes',
    text: 'CPT Codes',
    value: 'cpt_codes',
  },
  {
    key: 'member_id',
    text: 'Member ID',
    value: 'member_id',
  },
  {
    key: 'insuances',
    text: 'Insurances',
    value: 'insuances',
  },
  {
    key: 'to_identify',
    text: 'To Identify',
    value: 'to_identify',
  },
];

export const borrowingStatusOptions = [
  {
    key: 'All',
    text: 'All',
    value: 'all',
  },
  {
    key: 'to_confirm',
    text: 'To Confirm',
    value: 'to_confirm',
  },
  {
    key: 'confirmed',
    text: 'Confirmed',
    value: 'confirmed',
  },
  {
    key: 'returned',
    text: 'Returned',
    value: 'returned',
  },
];

export const directChargeStatusOptions = [
  {
    key: 'All',
    text: 'All',
    value: 'all',
  },
  {
    key: 'to_confirm',
    text: 'To Confirm',
    value: 'to_confirm',
  },
  {
    key: 'confirmed',
    text: 'Confirmed',
    value: 'confirmed',
  },
  {
    key: 'charged',
    text: 'Charged',
    value: 'charged',
  },
];

export enum CPTType {
  INVENTORY = 'Inventory',
  ADMINISTRATION = 'Administration',
}

export enum OTHER_REASONS_ENUM {
  'Missing Vaccines' = 'missing_vaccines',
  'Others' = 'others',
}

export type BillingInvoiceExtraItem = {
  id: string;
  practiceId: string;
  practice: Practice;
  reason: OTHER_REASONS_ENUM;
  inventoryId?: string;
  inventory?: Inventory;
  description?: string;
  invoiceId?: string;
  invoice?: Invoice;
  quantity: number;
  unitPrice: number;
  reviewedAt?: Date;
};
export interface BillingControl {
  practiceId: string;
  practiceName: string;
  practiceAddress: Address;
  claimId: string;
  status: BillingClaimStatus;
  eobStatus: EobStatus;
  givenAt: Date;
  updatedAt: Date;
  rhcClaimNumber: string;
  claimControlNumber: string;
  checkNumber: string;
  totalClaimAmount: number;
  practicePatientId: string;
  patientFirstName: string;
  patientLastName: string;
  fullName: string;
  patientBirthdate: string;
  insuranceName: string;
  insuranceCompanyId: string;
  payerId: string;
  vfcEligible: boolean;
  vfcCriteria: string;
  insuranceMemberId: string;
  cptCodes: CptCode[];
  providerFirstName: string;
  providerLastName: string;
  prescriberFirstName: string;
  prescriberLastName: string;
  prescriberNpi: string;
  npi: string;
  taxId: string;
  errors: ResponseError[];
  notes: NoteView;
  tags: string[];
}

export interface BillingClaimReportingView {
  name: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  givenAt: Date;
  totalClaimAmount: number;
  claimId: string;
}

export enum BillingClaimsMappingType {
  NPI_TAXID = 'npi_taxid',
  TAXONOMY = 'taxonomy',
  RENDERING = 'rendering',
  CLAIM_FILLING_CODE = 'claim_filling_code',
  BOX_31 = 'box_31',
  NDC_DOSSAGE = 'ndc_dossage',
  VFC_WITH_INVENTORY_AMOUNT = 'vfc_with_inventory_amount',
  DECOUPLED_CPTS = 'decoupled_cpts',
  INDIVIDUAL_BILLING_PROVIDER = 'individual_billing_provider',
  INDIVIDUAL_RENDERING_PROVIDER = 'individual_rendering_provider',
}
