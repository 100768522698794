import {
  InventoryAdjustment,
  InventoryAdjustmentDetail,
} from '@bluefox/models/InventoryAdjustment';

export function buildNewInventoryAdjustmentDetail(
  detail: InventoryAdjustmentDetail
) {
  const lastestStatusLog = detail?.statusLog
    ? detail?.statusLog?.[0]
    : undefined;
  const firstStatusLog =
    detail?.statusLog && detail?.statusLog.length > 0
      ? detail?.statusLog?.[detail?.statusLog.length - 1]
      : undefined;
  const originalNewDoses =
    firstStatusLog?.newDoses !== undefined
      ? firstStatusLog?.newDoses
      : detail.newDoses;
  const newDetail = {
    ...detail,
    // originalNewDoses: Only to revert some amend or rejection
    originalNewDoses,
    operatorAmmend: lastestStatusLog?.operatorAmmend
      ? lastestStatusLog?.operatorAmmend
      : undefined,
    operatorRejection: lastestStatusLog?.operatorRejection
      ? lastestStatusLog?.operatorRejection
      : undefined,
    newDoses: lastestStatusLog?.operatorAmmend
      ? lastestStatusLog?.operatorAmmend.newDoses
      : lastestStatusLog?.operatorRejection
      ? lastestStatusLog?.operatorRejection?.newDoses
      : detail.newDoses,
  } as InventoryAdjustmentDetail;
  return newDetail;
}

export function buildNewInventoryAdjustment(adjustment: InventoryAdjustment) {
  const newAdjustments = {
    ...adjustment,
    details: adjustment.details?.map((detail) =>
      buildNewInventoryAdjustmentDetail(detail)
    ),
  };
  return newAdjustments;
}
