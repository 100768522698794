import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import AppointmentStatusPicker from '@components/AppointmentStatusPicker';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import FullLayout from '@components/FullLayout';
import SexIconComponent from '@components/SexIcon';
import { usePractice } from '@bluefox/contexts/Practice';
import {
  AllAppointmentsByDateQuery,
  AppointmentsPerDateQuery,
  UpdateAppointmentStateMutation,
} from '@bluefox/graphql/appointments';

import {
  PendingNotificationsCountDefaultPollInterval,
  PendingNotificationsCountQuery,
  PendingNotificationsCountResponse,
} from '@bluefox/graphql/notifications';
import { Appointment, AppointmentStatus } from '@bluefox/models/Appointment';
import { RoutineTypes, VACCINE_TYPES } from '@bluefox/store/vaccines';
import 'moment-timezone';
import { useCallback, useEffect, useState, useRef } from 'react';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  Button,
  Container,
  Header,
  Icon,
  Input,
  Label,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  TableHeaderCell,
  Transition,
  Popup,
  List,
  Card,
} from 'semantic-ui-react';
import { getOrdinal } from '@utils/maths';
import { useApplicationState } from '@bluefox/contexts';
import { differenceInMonths } from '@utils/validations/inRange';
import { getImmunization } from '@bluefox/store/vaccinations';
import { PatientData } from '@bluefox/models/Patient';
import { Vaccination } from '@bluefox/models/Vaccination';
import { DateFormats } from '@bluefox/models/Dates';
import { notifyPendingNotificationCountToExtension } from '@messaging/extension';

export type PendingNotificationPayload = {
  notificationsCount: number;
  action: string;
};

interface AppointmentDate {
  date: Date;
}

interface AppointmentsDates {
  appointments: AppointmentDate[];
}

interface AppointmentData {
  appointments: Appointment[];
}

const AppointmentsScreen = () => {
  const { isEmbedded } = useApplicationState();
  const practice = usePractice();
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState('');

  const [date, setDate] = useState<Date>(new Date());
  const [datePickerRange, setDatePickerRange] = useState({
    year: date.getFullYear(),
    month: date.getMonth(),
  });
  const [appointmentsDates, setAppointmentsDates] = useState<Date[]>();

  const [statuses, setStatuses] = useState<string[]>([]);

  const [timeRange, setTimeRange] = useState<object>({});
  const [calendarStartDate, setCalendarStartDate] = useState<Date>(new Date());

  useEffect(() => {
    setTimeRange({
      from: moment(date).startOf('day').utc().format(),
      to: moment(date).endOf('day').utc().format(),
    });

    setCalendarStartDate(moment(date).subtract(60, 'days').toDate());
  }, [date]);

  const { data, loading, error, refetch } = useQuery<AppointmentData>(
    AllAppointmentsByDateQuery,
    {
      variables: {
        statuses: statuses.length ? statuses : Object.keys(AppointmentStatus),
        ...timeRange,
        searchQuery: `%${searchQuery}%`,
        types: VACCINE_TYPES,
      },
      skip: Object.keys(timeRange).length === 0,
      pollInterval: 60 * 1000,
      fetchPolicy: 'cache-and-network',
    }
  );

  const scheduleVaccines = (
    vaccinations: Vaccination[],
    patientData: PatientData
  ) => {
    return patientData
      ? getImmunization(RoutineTypes, vaccinations || [], patientData)
      : [];
  };

  const [getAppointmentsDates] = useLazyQuery<AppointmentsDates>(
    AppointmentsPerDateQuery,
    {
      onCompleted: (data) => {
        setAppointmentsDates(buildAppointmentsDatesRange(data.appointments));
      },
    }
  );

  const buildAppointmentsDatesRange = (data: AppointmentDate[]): Date[] => {
    const givenAtArray = data?.map((a) => {
      return new Date(`${a.date} 12:00:00`);
    });

    return [
      new Date(datePickerRange.year, datePickerRange.month - 1, 1),
      ...givenAtArray,
      new Date(datePickerRange.year, datePickerRange.month + 1, 15),
    ];
  };

  const handleDatePickerRangeChange = (date: Date) => {
    getAppointmentsDates({
      variables: {
        practiceId: practice.id,
        fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
        toDate: new Date(date.getFullYear(), date.getMonth() + 1, 1),
      },
    });

    setDatePickerRange({
      year: date.getFullYear(),
      month: date.getMonth(),
    });
  };

  const [saveStatusMutation] = useMutation<Appointment>(
    UpdateAppointmentStateMutation,
    {
      onCompleted: refetch,
    }
  );

  const saveStatus = useCallback(
    (id: string, status: string) => {
      saveStatusMutation({
        variables: {
          id,
          status,
        },
      });
    },
    [saveStatusMutation]
  );

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    pageStyle: '',
    documentTitle: 'CANID - Appointments List',
    content: () => componentRef.current,
  });

  const handleNotifyPendingNotificationCountToExtension = useCallback(
    async (data: PendingNotificationsCountResponse) => {
      notifyPendingNotificationCountToExtension(
        data.pendingNotifications.aggregate.count
      );
    },
    []
  );

  useQuery(PendingNotificationsCountQuery, {
    skip: !isEmbedded,
    pollInterval: PendingNotificationsCountDefaultPollInterval,
    onCompleted: handleNotifyPendingNotificationCountToExtension,
  });

  return (
    <FullLayout>
      <Container fluid>
        {!isEmbedded && (
          <Segment padded basic>
            <Header as="h1">
              <Icon name="calendar" />
              Appointments
            </Header>
          </Segment>
        )}

        {isEmbedded ? (
          <Menu
            borderless
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '5rem',
              marginRight: '0.1rem',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '1rem',
              }}
            >
              <Header style={{ marginLeft: '1.2rem' }}>Appointments</Header>
              <Label
                style={{ marginRight: '1.2rem' }}
                color="teal"
                size="large"
              >
                {data?.appointments.length}
                &nbsp;Appointments found
              </Label>
            </div>

            <Menu.Menu>
              <Menu.Item>
                <DateTimePicker
                  tz={practice.timezone}
                  data-automation-id={`apointments-date`}
                  isClearable={false}
                  selected={date}
                  onChange={(value) =>
                    value instanceof Date ? setDate(value) : null
                  }
                  absolute
                  includeDates={
                    appointmentsDates && appointmentsDates.length > 0
                      ? appointmentsDates
                      : [new Date('1900-01-01')] //invalid date to disable all dates if includeDates is empty or undefined
                  }
                  dateFormat={DateFormats.DATE}
                  scrollableYearDropdown
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  onYearChange={handleDatePickerRangeChange}
                  onMonthChange={handleDatePickerRangeChange}
                  onCalendarOpen={() => {
                    setDatePickerRange({
                      year: date.getFullYear(),
                      month: date.getMonth(),
                    });

                    getAppointmentsDates({
                      variables: {
                        practiceId: practice.id,
                        fromDate: date
                          ? new Date(date.getFullYear(), date.getMonth(), 1)
                          : new Date(),
                        toDate: new Date(
                          date.getFullYear(),
                          date.getMonth() + 1,
                          1
                        ),
                      },
                    });
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                <AppointmentStatusPicker
                  data-automation-id={`apointments-status`}
                  multiple
                  onSelectionChange={(value) => setStatuses(value as string[])}
                  text="Show All"
                  prefixText="Show only "
                  icon="filter"
                  labeled
                />
              </Menu.Item>

              <Menu.Item>
                <Input
                  style={{ maxWidth: '15rem' }}
                  data-automation-id={`apointments-search`}
                  value={searchQuery}
                  onChange={(_, { value }) => setSearchQuery(value)}
                  icon="search"
                  placeholder="Search..."
                  input={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                  }}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        ) : (
          <Menu
            borderless
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Menu.Item>
              <DateTimePicker
                tz={practice.timezone}
                data-automation-id={`apointments-date`}
                isClearable={false}
                selected={date}
                onChange={(value) =>
                  value instanceof Date ? setDate(value) : null
                }
                absolute
                includeDates={
                  appointmentsDates && appointmentsDates.length > 0
                    ? appointmentsDates
                    : [new Date('1900-01-01')] //invalid date to disable all dates if includeDates is empty or undefined
                }
                dateFormat={DateFormats.DATE}
                scrollableYearDropdown
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                onYearChange={handleDatePickerRangeChange}
                onMonthChange={handleDatePickerRangeChange}
                onCalendarOpen={() => {
                  setDatePickerRange({
                    year: date.getFullYear(),
                    month: date.getMonth(),
                  });

                  getAppointmentsDates({
                    variables: {
                      practiceId: practice.id,
                      fromDate: date
                        ? new Date(date.getFullYear(), date.getMonth(), 1)
                        : new Date(),
                      toDate: new Date(
                        date.getFullYear(),
                        date.getMonth() + 1,
                        1
                      ),
                    },
                  });
                }}
              />
            </Menu.Item>
            <Menu.Item>
              <AppointmentStatusPicker
                data-automation-id={`apointments-status`}
                multiple
                onSelectionChange={(value) => setStatuses(value as string[])}
                text="Show All"
                prefixText="Show only "
                icon="filter"
                labeled
              />
            </Menu.Item>
            <Menu.Item fitted>
              <Label color="teal" size="big">
                {data?.appointments.length}
                &nbsp;Appointments found
              </Label>
            </Menu.Item>

            <Menu.Menu position="right">
              <Menu.Item>
                <Input
                  data-automation-id={`apointments-search`}
                  value={searchQuery}
                  onChange={(_, { value }) => setSearchQuery(value)}
                  icon="search"
                  placeholder="Search..."
                  input={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                  }}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        )}

        {error ? (
          <Message error>{error.message}</Message>
        ) : (
          <div ref={componentRef}>
            <Table striped selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Time</Table.HeaderCell>
                  <Table.HeaderCell>Patient</Table.HeaderCell>
                  <Table.HeaderCell>Date of Birth / Age</Table.HeaderCell>
                  <Table.HeaderCell>Sex</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  {/* INSURANCE VERIFICATION MESSAGES */}
                  {/* <Table.HeaderCell>Insurance Status</Table.HeaderCell> */}
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {loading && data === undefined ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan={8}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : (
                <Transition.Group as={Table.Body} duration={500}>
                  {data?.appointments.map((appointment) => {
                    const routineVaccines = scheduleVaccines(
                      appointment.patient.vaccinations || [],
                      appointment.patient.patientData
                    );

                    const patientAgeInMonths = appointment.patient.patientData
                      ?.birthdate
                      ? differenceInMonths(
                          appointment.patient.patientData.birthdate,
                          new Date()
                        )
                      : 0;

                    const dueVaccines = routineVaccines.filter(
                      (immunization) => {
                        const isEligible =
                          immunization?.ranges.length === 0 ||
                          immunization?.ranges[0]?.start <= patientAgeInMonths
                            ? differenceInMonths(
                                appointment.patient.patientData?.birthdate,
                                new Date()
                              )
                            : 0;

                        return (
                          isEligible &&
                          immunization.givenDosesCount <
                            immunization.totalRecommentationsCount
                        );
                      }
                    );

                    return (
                      <Table.Row
                        key={appointment.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          history.push(
                            `/${practice.handler}/patients/${appointment.patient.id}`
                          )
                        }
                      >
                        <Table.Cell>
                          {moment(appointment.time).format(DateFormats.TIME)}
                        </Table.Cell>
                        <Table.Cell>
                          {appointment.patient.patientData.firstName}{' '}
                          {appointment.patient.patientData.lastName}
                        </Table.Cell>
                        <Table.Cell>
                          {moment(
                            appointment.patient.patientData.birthdate
                          ).format(DateFormats.DATE)}
                          {` / `}
                          <Moment
                            durationFromNow
                            date={appointment.patient.patientData.birthdate}
                            trim
                            format="y [years], M [months], d [days]"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <SexIconComponent
                            sex={appointment.patient.patientData.sex}
                          />
                          {appointment.patient.patientData.sex}
                        </Table.Cell>
                        <Table.Cell>
                          {appointment.metadata?.vaccine?.name && (
                            <Label color="teal">
                              {appointment.metadata.vaccine.name}{' '}
                              {appointment.metadata.vaccine?.dose
                                ? getOrdinal(appointment.metadata.vaccine.dose)
                                : null}
                            </Label>
                          )}
                        </Table.Cell>
                        {/* INSURANCE VERIFICATION MESSAGES */}
                        {/* <Table.Cell>
                        {appointment.patient.insurances.length > 0 ? (
                          <Label
                            icon={
                              appointment.patient.insurances[0]
                                .verificationStatus === "valid"
                                ? "check"
                                : null
                            }
                            basic
                            content={
                              appointment.patient.insurances[0]
                                .verificationStatus === "valid"
                                ? "Insurance Verified"
                                : "Insurance Not Verified"
                            }
                            color={
                              appointment.patient.insurances[0]
                                .verificationStatus === "valid"
                                ? "olive"
                                : "red"
                            }
                          />
                        ) : (
                          <Label content="No Insurance" />
                        )}
                      </Table.Cell> */}
                        <Table.Cell>
                          <AppointmentStatusPicker
                            data-automation-id={`apointments-status`}
                            onSelectionChange={(s) =>
                              saveStatus(appointment.id, s as string)
                            }
                            defaultValue={appointment.status}
                            basic
                            labeled
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <div style={{ display: 'flex' }}>
                            {dueVaccines.length > 0 && (
                              <Popup
                                position={
                                  isEmbedded ? 'top right' : 'left center'
                                }
                                trigger={
                                  <Icon
                                    name="warning sign"
                                    color="orange"
                                    size="large"
                                  />
                                }
                                content={
                                  <Card>
                                    <Card.Content>
                                      <Card.Header as="h3">
                                        Due Vaccinations
                                      </Card.Header>
                                      <Card.Description>
                                        <List bulleted>
                                          {dueVaccines.map((v) => (
                                            <List.Item>
                                              {v.vaccineType}
                                            </List.Item>
                                          ))}
                                        </List>
                                      </Card.Description>
                                    </Card.Content>
                                  </Card>
                                }
                              />
                            )}
                            {!!appointment.patient.todaysVaccinations
                              ?.length && (
                              <Popup
                                key={appointment.id}
                                trigger={
                                  <Icon
                                    name="syringe"
                                    circular
                                    color="blue"
                                    size="small"
                                    inverted
                                  />
                                }
                                content={
                                  <List>
                                    {appointment.patient.todaysVaccinations?.map(
                                      (v) => (
                                        <List.Item
                                          style={{
                                            display: 'flex',
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginRight: '0.4rem',
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {v.vaccine?.name}
                                          </div>
                                          <div>
                                            <Label
                                              basic
                                              size="tiny"
                                              content={`Lot: ${
                                                v.inventory
                                                  ? v.inventory.lot
                                                  : '-'
                                              }`}
                                            />
                                          </div>
                                        </List.Item>
                                      )
                                    )}
                                  </List>
                                }
                              />
                            )}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Transition.Group>
              )}
              <Table.Footer>
                <Table.Row>
                  <TableHeaderCell colSpan={8} textAlign="right">
                    <Label>{data?.appointments.length}</Label>&nbsp;Appointments
                    found
                  </TableHeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
        )}
        {!isEmbedded && (
          <Button
            data-automation-id={`apointments-print`}
            style={{ marginTop: '10px' }}
            onClick={handlePrint}
            floated="right"
            icon="print"
            content="Print / Save as PDF"
          />
        )}
      </Container>
    </FullLayout>
  );
};

export default AppointmentsScreen;
