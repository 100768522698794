import React, { useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { tsXLXS } from 'ts-xlsx-export';
import { toast } from 'react-semantic-toasts';
import moment from 'moment-timezone';
import { humanizeText } from '@bluefox/lib/humanize';
import { Modal, Button, Progress, Icon } from 'semantic-ui-react';
import { DateFormats } from '@bluefox/models/Dates';
import { VaccinationsPerDay } from '@bluefox/graphql/vaccinations';
import { Vaccination } from '@bluefox/models/Vaccination';
import { Inventory } from '@bluefox/models/Inventory';
import { vaccinationSiteParser } from '@bluefox/lib/vaccinationSiteParser';

interface ExcelData {
  Insurance: string;
  Inventory: string;
  'Lot Number': string;
  'Member ID': string;
  NDC: string;
  Patient: string;
  Prescriber: string;
  Provider: string;
  Route: string;
  'Service Date': string;
  Site: string;
  'VFC Eligibility': string;
  Vaccine: string | undefined;
}

interface VaccinationsData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  vaccinations: Vaccination[];
}

interface VaccinationListExcelExportModalProps {
  criteria?: object;
}

const VaccinationListExcelExportModal = ({
  criteria,
}: VaccinationListExcelExportModalProps) => {
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState<ExcelData[] | undefined>([]);

  const [getVaccinations, { loading, error }] =
    useLazyQuery<VaccinationsData>(VaccinationsPerDay);

  const handleLoadXlsx = useCallback(() => {
    if (!excelData) return;
    tsXLXS()
      .exportAsExcelFile(excelData)
      .saveAsExcelFile(
        `Canid-Vaccinations-List-${moment().format(
          DateFormats.DATE_WITH_TIME_SECONDS
        )}`
      );
    setOpen(false);
  }, [excelData]);

  const showInventoryUsed = (inventory: Inventory | undefined) => {
    if (inventory && inventory.vfc) {
      return 'VFC';
    } else if (inventory && !inventory.vfc) {
      return 'Private';
    } else {
      return '-';
    }
  };

  const onClickHandler = useCallback(async () => {
    try {
      const response = await getVaccinations({
        variables: {
          criteria,
        },
      });

      const vaccinationsList = response.data?.vaccinations.map(
        (vaccination) => {
          return {
            Patient: vaccination.practicePatient?.patientData.fullName || '-',
            'Date of Birth':
              vaccination.practicePatient?.patientData.birthdate || '-',
            MRN: vaccination.practicePatient?.mrn || '-',
            'Service Date':
              moment(vaccination.givenAt).format(DateFormats.DATE) || '-',
            Insurance:
              vaccination.practicePatient?.insurances[0]?.company?.name || '-',
            'Member ID':
              vaccination.practicePatient?.insurances[0]?.memberId || '-',
            'VFC Eligibility': vaccination.practicePatient?.insurances[0]
              ?.vfcEligible
              ? 'VFC'
              : 'Private',
            Vaccine: vaccination.vaccine?.name,
            Route: vaccination.route || '-',
            Site: vaccination.site
              ? (vaccinationSiteParser(vaccination.site) as string)
              : '-',
            Prescriber:
              `${vaccination.prescriber?.account.firstName} ${vaccination.prescriber?.account.lastName}` ||
              '-',
            Provider:
              `${vaccination.provider?.account.firstName} ${vaccination.provider?.account.lastName}` ||
              '-',
            NDC: vaccination.vaccine?.saleNdc || '-',
            'Lot Number': vaccination.inventory?.lot || '-',
            Inventory: showInventoryUsed(vaccination.inventory),
          };
        }
      );

      setExcelData(vaccinationsList);
    } catch (error) {
      toast({
        title: `Callback error: ${error}`,
        type: 'error',
        time: 5000,
      });
    }
  }, [criteria, getVaccinations]);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={
        <Button
          style={{ marginTop: '10px' }}
          onClick={onClickHandler}
          floated="right"
          icon="file excel outline"
          content="Export Excel file"
        />
      }
    >
      <Modal.Header>Export as Excel file?</Modal.Header>
      <Modal.Content>
        {loading ? (
          <div>
            Loading Excel data...
            <Progress size="small" color="teal" indicating percent={100} />
          </div>
        ) : error ? (
          <div>
            <Icon name="warning sign" color="red" />
            There was an error trying to load Excel data
          </div>
        ) : (
          <div>
            Excel data is ready to be exported{' '}
            <Icon name="check" color="olive" />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>No</Button>
        <Button
          primary
          content="Yes"
          onClick={() => handleLoadXlsx()}
          disabled={loading || !excelData}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default VaccinationListExcelExportModal;
