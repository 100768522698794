export type DatePickerDateTypes = Date | undefined | null;

export enum DateFormats {
  DATE_WITH_TIME = 'MM-DD-YYYY h:mm a',
  DATE_WITH_TIME_SECONDS = 'MM-DD-YY-H:m:s',
  DATE = 'MM/DD/YYYY',
  TIME = 'h:mm a',
  MONTH_FULL_NAME = 'MMMM',
  DATE_WITH_TIME_SLASH = 'MM/DD/YYYY h:mm a',
}
