import { gql } from '@apollo/client';

export const InsertInventoryOrderMutation = gql`
  mutation InsertInventoryOrderMutation(
    $practiceId: uuid!
    $amount: numeric!
    $date: timestamptz!
    $doses: Int!
    $inventoryExpiration: date
    $invoiceDue: date
    $orderNumber: String
    $trackingNumber: String
    $lot: String
    $packagesAmount: Int
    $paid: Boolean
    $paidAt: timestamptz
    $presentationUnit: String
    $purchasePlatform: String
    $status: order_statuses_enum!
    $statusLog: jsonb!
    $vaccineId: uuid!
    $vfc: Boolean!
    $estimatedShippingDate: timestamptz
    $statementDate: date
    $notes: String
    $invoiceNumber: String
    $invoiceLink: String
    $creditCardId: uuid
  ) {
    insert_inventory_orders_one(
      object: {
        practiceId: $practiceId
        amount: $amount
        date: $date
        doses: $doses
        inventoryExpiration: $inventoryExpiration
        invoiceNumber: $invoiceNumber
        invoiceDue: $invoiceDue
        invoiceLink: $invoiceLink
        orderNumber: $orderNumber
        trackingNumber: $trackingNumber
        lot: $lot
        packagesAmount: $packagesAmount
        paid: $paid
        paidAt: $paidAt
        presentationUnit: $presentationUnit
        purchasePlatform: $purchasePlatform
        status: $status
        statusLog: $statusLog
        vaccineId: $vaccineId
        vfc: $vfc
        estimatedShippingDate: $estimatedShippingDate
        statementDate: $statementDate
        notes: $notes
        creditCardId: $creditCardId
      }
    ) {
      id
    }
  }
`;

export const PracticeInventoryOrdersQuery = (
  withCreditCardInfo: boolean = false
) => gql`
  query PracticeInventoryOrdersQuery(
    $practiceId: uuid!
    $criteria: [inventory_orders_bool_exp!]
  ) {
    inventory_orders(
      where: { practiceId: { _eq: $practiceId }, _and: $criteria }
      order_by: { date: desc }
    ) {
      practice {
        timezone
      }
      id
      practiceId
      vaccineId
      vaccine {
        id
        name
        manufacturer
        saleNdc
      }
      inventoryExpiration
      date
      purchasePlatform
      presentationUnit
      doses
      amount
      invoiceNumber
      invoiceDue
      invoiceLink
      orderNumber
      trackingNumber
      status
      paidAt
      paid
      packagesAmount
      lot
      vfc
      statusLog
      estimatedShippingDate
      statementDate
      notes
      ${withCreditCardInfo ? 'creditCardId' : ''}
    }
  }
`;

export const PurchasePlatformsQuery = gql`
  query purchasePlatformsQuery {
    purchasePlatforms: purchase_platforms {
      value
      comment
    }
  }
`;

export const UpdateInventoryOrderMutation = gql`
  mutation UpdateInventoryOrder(
    $orderId: uuid!
    $date: timestamptz
    $doses: Int
    $amount: numeric
    $inventoryExpiration: date
    $invoiceNumber: String
    $invoiceDue: date
    $invoiceLink: String
    $orderNumber: String
    $trackingNumber: String
    $lot: String
    $packagesAmount: Int
    $paid: Boolean
    $paidAt: timestamptz
    $purchasePlatform: String
    $status: order_statuses_enum
    $vfc: Boolean
    $statusLog: jsonb!
    $estimatedShippingDate: timestamptz
    $statementDate: date
    $notes: String
    $creditCardId: uuid
  ) {
    update_inventory_orders_by_pk(
      pk_columns: { id: $orderId }
      _set: {
        date: $date
        doses: $doses
        amount: $amount
        inventoryExpiration: $inventoryExpiration
        invoiceNumber: $invoiceNumber
        invoiceDue: $invoiceDue
        invoiceLink: $invoiceLink
        orderNumber: $orderNumber
        trackingNumber: $trackingNumber
        lot: $lot
        packagesAmount: $packagesAmount
        paid: $paid
        paidAt: $paidAt
        purchasePlatform: $purchasePlatform
        status: $status
        vfc: $vfc
        estimatedShippingDate: $estimatedShippingDate
        statementDate: $statementDate
        notes: $notes
        creditCardId: $creditCardId
      }
      _append: { statusLog: $statusLog }
    ) {
      id
    }
  }
`;

export const OrdersToTrackQuery = gql`
  query OrdersToTrackQuery(
    $criteria: [inventory_orders_bool_exp!]
    $limit: Int
    $offset: Int
  ) {
    aggregating: inventory_orders_aggregate(
      where: { status: { _neq: closed }, _and: $criteria }
    ) {
      aggregate {
        count
      }
    }
    orders: inventory_orders(
      where: { status: { _neq: closed }, _and: $criteria }
      order_by: { date: desc, practice: { name: asc } }
      limit: $limit
      offset: $offset
    ) {
      id
      estimatedShippingDate
      practiceId
      practice {
        id
        name
        timezone
      }
      vaccineId
      vaccine {
        id
        name
        manufacturer
        saleNdc
      }
      inventoryExpiration
      date
      purchasePlatform
      doses
      amount
      invoiceDue
      orderNumber
      status
      paidAt
      paid
      packagesAmount
      lot
      vfc
      statementDate
      statusLog
      notes
      trackingNumber
      invoiceLink
      invoiceNumber
      creditCardId
    }
  }
`;

export const InsertInventoryOrderWithInventoryMutation = gql`
  mutation InsertInventoryOrderWithInventoryMutation(
    $practiceId: uuid!
    $amount: numeric!
    $date: timestamptz!
    $doses: Int!
    $inventoryExpiration: date
    $invoiceDue: date
    $orderNumber: String
    $lot: String
    $packagesAmount: Int
    $paid: Boolean
    $paidAt: timestamptz
    $presentationUnit: String
    $purchasePlatform: String
    $statusOrder: order_statuses_enum!
    $statusInventory: inventory_statuses_enum!
    $statusLog: jsonb!
    $vaccineId: uuid!
    $vfc: Boolean!
    $statementDate: date
    $alternativeLotNumber: jsonb
  ) {
    insert_inventory_orders_one(
      object: {
        practiceId: $practiceId
        amount: $amount
        date: $date
        doses: $doses
        inventoryExpiration: $inventoryExpiration
        invoiceDue: $invoiceDue
        orderNumber: $orderNumber
        lot: $lot
        packagesAmount: $packagesAmount
        paid: $paid
        paidAt: $paidAt
        presentationUnit: $presentationUnit
        purchasePlatform: $purchasePlatform
        status: $statusOrder
        statusLog: $statusLog
        vaccineId: $vaccineId
        vfc: $vfc
        statementDate: $statementDate
        inventory: {
          data: {
            practiceId: $practiceId
            vaccineId: $vaccineId
            lot: $lot
            expiration: $inventoryExpiration
            status: $statusInventory
            doses: $doses
            vfc: $vfc
            alternativeLotNumber: $alternativeLotNumber
          }
        }
      }
    ) {
      id
    }
  }
`;

export const OrdersPracticeProfile = gql`
  query OrdersPracticeProfile(
    $criteria: [inventory_orders_bool_exp!]
    $limit: Int
    $offset: Int
  ) {
    aggregating: inventory_orders_aggregate(
      where: { status: { _neq: closed }, _and: $criteria }
    ) {
      aggregate {
        count
      }
    }
    orders: inventory_orders(
      where: { status: { _neq: closed }, _and: $criteria }
      order_by: { date: desc, practice: { name: asc } }
      limit: $limit
      offset: $offset
    ) {
      id
      estimatedShippingDate
      vaccineId
      vaccine {
        id
        name
        manufacturer
        saleNdc
      }
      inventoryExpiration
      date
      doses
      orderNumber
      packagesAmount
      lot
      vfc
      trackingNumber
    }
  }
`;

export const VfcAndNonVFCInventoryCountQuery = gql`
  query VfcAndNonVFCInventoryCount($practiceId: uuid!) {
    vfcInventoryCount: inventory_orders_aggregate(
      where: {
        practiceId: { _eq: $practiceId }
        vfc: { _eq: true }
        inventory: { status: { _in: [received, expired] } }
      }
    ) {
      aggregate {
        count
      }
    }
    nonVfcInventoryCount: inventory_orders_aggregate(
      where: {
        practiceId: { _eq: $practiceId }
        vfc: { _eq: false }
        inventory: { status: { _in: [received, expired] } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
