import { useMemo, useState } from 'react';
import { LSHANDLER_KEYS, useLSHandler, usePractice } from '@bluefox/contexts';
import {
  Button,
  Container,
  Dimmer,
  Header,
  Icon,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import FullLayout from '@components/FullLayout';
import { AdjustmentKind } from '@bluefox/models/InventoryAdjustment';
import { GetNotAppliedAdjustmentsQuery } from '@bluefox/graphql/inventoryAdjustment';
import InventoryAdjustment from '@screens/inventory/InventoryAdjustment';
import { Link } from 'react-router-dom';
import { VfcAndNonVFCInventoryCountQuery } from '@bluefox/graphql/inventoryOrders';

const AdjustmentSelector = () => {
  const practice = usePractice();
  const lsHandler = useLSHandler();

  const [hasVFCPendingAdjustments, setHasVFCPendingAdjustments] =
    useState(false);
  const [hasPrivatePendingAdjustments, setHasPrivatePendingAdjustments] =
    useState(false);

  const [hasVFCInventory, setHasVFCInventory] = useState(false);
  const [hasPrivateInventory, setHasPrivateInventory] = useState(false);

  const [selectedAdjustmentKind, setSelectedAdjustmentKind] =
    useState<string>('');

  const {
    data,
    loading: notAppliedAdjustmentLoading,
    error: notAppliedAdjustmentError,
  } = useQuery(GetNotAppliedAdjustmentsQuery, {
    variables: {
      id: practice.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { notAppliedVFCAdjustments, notAppliedPrivateAdjustments } = data;

      setHasPrivatePendingAdjustments(
        notAppliedPrivateAdjustments?.length !== 0
      );
      setHasVFCPendingAdjustments(notAppliedVFCAdjustments?.length !== 0);
    },
  });

  const {
    loading: vfcPrivateInventoryCountLoading,
    error: vfcPrivateInventoryCountError,
  } = useQuery<{
    vfcInventoryCount: {
      aggregate: { count: number };
    };
    nonVfcInventoryCount: { aggregate: { count: number } };
  }>(VfcAndNonVFCInventoryCountQuery, {
    variables: {
      practiceId: practice.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const _hasPrivateInventory =
        data?.nonVfcInventoryCount?.aggregate?.count > 0;
      if (!_hasPrivateInventory) {
        lsHandler.removeItem(LSHANDLER_KEYS.ADJUSTMENT_DATA_PRIVATE_LS_KEY);
      }
      setHasPrivateInventory(_hasPrivateInventory);

      const _hasVFCInventory = data?.vfcInventoryCount?.aggregate?.count > 0;
      if (!_hasVFCInventory) {
        lsHandler.removeItem(LSHANDLER_KEYS.ADJUSTMENT_DATA_VFC_LS_KEY);
      }
      setHasVFCInventory(_hasVFCInventory);
    },
  });

  const { hasVFCAdjustmentsStarted, hasPrivateAdjustmentsStarted } =
    useMemo(() => {
      return {
        hasVFCAdjustmentsStarted:
          !!lsHandler.getItem(LSHANDLER_KEYS.ADJUSTMENT_DATA_VFC_LS_KEY) &&
          hasVFCInventory,
        hasPrivateAdjustmentsStarted:
          !!lsHandler.getItem(LSHANDLER_KEYS.ADJUSTMENT_DATA_PRIVATE_LS_KEY) &&
          hasPrivateInventory,
      };
    }, [hasPrivateInventory, hasVFCInventory, lsHandler]);

  const { loading } = useMemo(() => {
    return {
      loading: notAppliedAdjustmentLoading || vfcPrivateInventoryCountLoading,
    };
  }, [notAppliedAdjustmentLoading, vfcPrivateInventoryCountLoading]);

  return (
    <>
      <FullLayout
        path={[
          { text: 'Inventory', to: 'inventory' },
          { text: 'Inventory Adjustment' },
        ]}
      >
        <Segment padded basic>
          <Header as="h1">
            <Icon name="boxes" />
            Inventory Adjustment
          </Header>
        </Segment>
        <Container>
          {loading && (
            <Dimmer active>
              <Loader />
            </Dimmer>
          )}
          {notAppliedAdjustmentError && (
            <Message error>
              Failed to get Adjustment Information. Error:{' '}
              {notAppliedAdjustmentError?.message}
            </Message>
          )}
          {vfcPrivateInventoryCountError && (
            <Message error>
              Failed to get Inventoy Information. Error:{' '}
              {vfcPrivateInventoryCountError?.message}
            </Message>
          )}
          {data && (
            <>
              {selectedAdjustmentKind === '' && (
                <>
                  {hasPrivateInventory && (
                    <>
                      {!hasPrivatePendingAdjustments ? (
                        <Button
                          secondary={!hasPrivateAdjustmentsStarted}
                          negative={hasPrivateAdjustmentsStarted}
                          content={
                            !hasPrivateAdjustmentsStarted
                              ? 'Private Inventory'
                              : 'Continue Private Inventory Adjustment'
                          }
                          onClick={() => {
                            setSelectedAdjustmentKind(AdjustmentKind.PRIVATE);
                          }}
                          basic
                        />
                      ) : (
                        <Button
                          disabled
                          negative
                          content="Private Inventory Adjustment pending approval"
                          basic
                        />
                      )}
                    </>
                  )}
                  {hasVFCInventory && (
                    <>
                      {!hasVFCPendingAdjustments ? (
                        <Button
                          secondary={!hasVFCAdjustmentsStarted}
                          negative={hasVFCAdjustmentsStarted}
                          content={
                            !hasVFCAdjustmentsStarted
                              ? 'VFC Inventory'
                              : 'Continue VFC Inventory Adjustment'
                          }
                          onClick={() => {
                            setSelectedAdjustmentKind(AdjustmentKind.VFC);
                          }}
                          basic
                        />
                      ) : (
                        <Button
                          disabled
                          negative
                          content="VFC Inventory Adjustment pending approval"
                          basic
                        />
                      )}
                    </>
                  )}
                </>
              )}
              <Button
                as={Link}
                secondary
                to={`/${practice.handler}/inventory/adjustment/history`}
                content="Adjustment History"
                basic
              />
              {selectedAdjustmentKind !== '' && (
                <InventoryAdjustment
                  kind={selectedAdjustmentKind as AdjustmentKind}
                />
              )}
            </>
          )}
        </Container>
      </FullLayout>
    </>
  );
};

export default AdjustmentSelector;
