import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';
import {
  PracticeClaimsTableProps,
  TableItemProps as OldTableItemProps,
  tableTitleStyles,
  tableStyles,
} from './PracticeClaimsTable';
import { Style } from '@react-pdf/types';
import { usDollarCurrency } from '@bluefox/lib/formatters';

export enum CptCodeType {
  ADMINISTRATION = 'administration',
  INVENTORY = 'inventory',
}

export type CPTCodesV2 = {
  code: string;
  type: CptCodeType;
  paidAmount: number;
};

const newTableStyles = StyleSheet.create({
  patientCol: {
    width: '16%',
    textAlign: 'left',
  },
  insuranceCol: {
    width: '25%',
    textAlign: 'left',
  },
  cptPaidContainerCol: {
    width: '39%',
  },
  cptPaidContainerColCell: {
    margin: '6 0 0',
  },
  cptPaidContainerColRowLightBackground: {
    backgroundColor: '#F9FAFC',
  },
  row: {
    flexDirection: 'row',
  },
  cptPaidCodeSubCol: {
    width: '33%',
    textAlign: 'left',
  },
  cptPaidPractAdminFeeSubCol: {
    width: '33%',
    textAlign: 'left',
  },
  cptPaidPaidAmountSubCol: {
    width: '33%',
    textAlign: 'left',
  },
  cptPaidPaidAmountSubColCell: {
    padding: '5 4',
  },
  practiceAdminFeeCol: {
    width: '15%',
    textAlign: 'left',
  },
});

const tableStylesV2 = {
  ...tableStyles,
  ...newTableStyles,
};

export type TableItemProps = Omit<OldTableItemProps, 'cptCodes'> & {
  cptCodes: CPTCodesV2[];
};

export type PracticeClaimsTablePropsV2 = Omit<
  PracticeClaimsTableProps,
  'table'
> & {
  table: TableItemProps[];
};

const PracticeClaimsTableV2 = ({
  practice,
  table,
  totalAmount,
  style,
}: PracticeClaimsTablePropsV2 & { style?: Style | Style[] }) => (
  <View style={style}>
    <View style={tableTitleStyles.section}>
      <Text style={tableTitleStyles.practiceTitle}>Practice: </Text>
      <Text style={tableTitleStyles.practiceName}>{practice}</Text>
    </View>
    <View style={tableStylesV2.table}>
      <View style={tableStylesV2.headRow} wrap={false}>
        <View style={tableStylesV2.patientCol}>
          <Text style={tableStylesV2.cell}>Patient</Text>
        </View>
        <View style={tableStylesV2.insuranceCol}>
          <Text style={tableStylesV2.cell}>Insurance</Text>
        </View>
        <View style={tableStylesV2.dateOfServiceCol}>
          <Text style={tableStylesV2.cell}>Date of Service</Text>
        </View>
        <View
          style={{ ...tableStylesV2.cptPaidContainerCol, ...tableStylesV2.row }}
        >
          <View style={tableStylesV2.cptPaidCodeSubCol}>
            <Text style={tableStylesV2.cell}>CPT Codes</Text>
          </View>
          <View style={tableStylesV2.cptPaidPractAdminFeeSubCol}>
            <Text style={tableStylesV2.cell}>Practice Admin Fee</Text>
          </View>
          <View style={tableStylesV2.cptPaidPaidAmountSubCol}>
            <Text style={tableStylesV2.cell}>Paid Amount</Text>
          </View>
        </View>
        <View style={tableStylesV2.eobCol}>
          <Text style={tableStylesV2.cell}>EOB</Text>
        </View>
      </View>
      {table.map(
        ({ key, patient, insurance, dateOfService, cptCodes, eobLink }) => (
          <View style={tableStylesV2.bodyRow} key={key} wrap={false}>
            <View style={tableStylesV2.patientCol}>
              <View style={tableStylesV2.cell}>
                <Text>{patient.name}</Text>
                <Text>({patient.dateOfBirth})</Text>
              </View>
            </View>
            <View style={tableStylesV2.insuranceCol}>
              <View style={tableStylesV2.cell}>
                <Text>{insurance.name}</Text>
                {insurance.memberId && (
                  <Text>Member ID:{insurance.memberId} </Text>
                )}
              </View>
            </View>
            <View style={tableStylesV2.dateOfServiceCol}>
              <Text style={tableStylesV2.cell}>{dateOfService}</Text>
            </View>
            <View
              style={{
                ...tableStylesV2.cptPaidContainerCol,
                ...tableStylesV2.cptPaidContainerColCell,
              }}
            >
              {cptCodes.map((cptCode, i) => (
                <View
                  key={cptCode.code}
                  style={{
                    ...tableStylesV2.row,
                    ...(i % 2 === 1
                      ? tableStylesV2.cptPaidContainerColRowLightBackground
                      : {}),
                  }}
                >
                  <View style={tableStylesV2.cptPaidCodeSubCol}>
                    <Text
                      style={{
                        ...tableStylesV2.cptPaidPaidAmountSubColCell,
                      }}
                    >
                      {cptCode.code}
                    </Text>
                  </View>
                  <View style={tableStylesV2.cptPaidPractAdminFeeSubCol}>
                    <Text style={tableStylesV2.cptPaidPaidAmountSubColCell}>
                      {cptCode.type === CptCodeType.ADMINISTRATION
                        ? usDollarCurrency(cptCode.paidAmount)
                        : ''}
                    </Text>
                  </View>
                  <View style={tableStylesV2.cptPaidPaidAmountSubCol}>
                    <Text
                      style={{
                        ...tableStylesV2.cptPaidPaidAmountSubColCell,
                      }}
                    >
                      {cptCode.type === CptCodeType.INVENTORY
                        ? usDollarCurrency(cptCode.paidAmount)
                        : ''}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={tableStylesV2.eobCol}>
              {eobLink && (
                <Link
                  src={eobLink}
                  style={{ ...tableStylesV2.cell, ...tableStylesV2.eobLink }}
                >
                  EOB
                </Link>
              )}
            </View>
          </View>
        )
      )}
    </View>
    <View>
      <Text style={tableStylesV2.total} wrap={false}>
        Total: {usDollarCurrency(totalAmount)}
      </Text>
    </View>
  </View>
);

export default PracticeClaimsTableV2;
