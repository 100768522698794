export interface HumanizeOptions {
  delimiter?: string;
  capitalize?: 'first' | 'all';
  camelCase?: boolean;
  deCamelCase?: boolean;
}

const _capitalize = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1);

export const humanizeText = (input: string, options: HumanizeOptions) => {
  const { capitalize, delimiter, camelCase, deCamelCase } = options;
  let output = input;

  // Handle camelCase transformation to normal text (deCamelCase)
  if (deCamelCase) {
    // Insert spaces before uppercase letters
    output = output.replace(/([a-z])([A-Z])/g, '$1 $2');
    // Capitalize the first letter of each word for human readability
    output = output
      .split(' ')
      .map((word) => _capitalize(word))
      .join(' ');
  }

  // Handle camelCase conversion (if camelCase option is true)
  if (camelCase) {
    // Detect if input is already camel case (starts with lowercase and contains no spaces/underscores)
    if (/^[a-z][a-zA-Z0-9]*$/.test(input)) {
      return input; // Already in camelCase, return as is
    }

    const words = input
      .replace(/[_\s]+/g, ' ') // Replace underscores or multiple spaces with a single space
      .split(' ') // Split the string into words
      .map((word, index) => {
        // Capitalize all words except the first one
        if (index === 0) return word.toLowerCase();
        return _capitalize(word);
      })
      .join('');

    return words;
  }

  // Handle delimiter replacement
  if (delimiter) {
    output = output.replaceAll(delimiter, ' ');
  }

  // Capitalization handling (if capitalize is provided)
  if (capitalize) {
    if (capitalize === 'all') {
      output = output.split(' ').map(_capitalize).join(' ');
    } else {
      output = _capitalize(output);
    }
  }
  return output;
};
