import { VaccinationSites } from '@bluefox/models/Vaccination';

export const vaccinationSiteParser = (
  site: VaccinationSites | null | undefined
) => {
  switch (site) {
    case 'right_arm':
      return 'Right Arm';
    case 'left_arm':
      return 'Left Arm';
    case 'right_arm_deltoid':
      return 'Right Arm Deltoid';
    case 'left_arm_deltoid':
      return 'Left Arm Deltoid';
    case 'right_arm_upper':
      return 'Right Upper Arm';
    case 'left_arm_upper':
      return 'Left Upper Arm';
    case 'right_thigh':
      return 'Right Thigh';
    case 'left_thigh':
      return 'Left Thigh';
    case 'right_thigh_anterolateral':
      return 'Anterolateral Right Thigh';
    case 'left_thigh_anterolateral':
      return 'Anterolateral Left Thigh';
    case 'right_thigh_vastuslateralis':
      return 'Vastus Lateralis Right Thigh';
    case 'left_thigh_vastuslateralis':
      return 'Vastus Lateralis Left Thigh';
  }
};
