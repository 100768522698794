import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { Modal, Button, Form } from 'semantic-ui-react';
import {
  UpdateVacciantion,
  VaccinationsByTypeQuery,
} from '@bluefox/graphql/vaccinations';
import {
  Vaccination,
  VaccinationRoutes,
  VaccinationSites,
} from '@bluefox/models/Vaccination';
import { VACCINE_TYPES } from '@bluefox/store/vaccines';
import VaccinationRoutePicker from '@components/VaccinationRoutePicker';
import VaccinationSitePicker from '@components/VaccinationSitePicker';
import ProviderPicker from '@components/ProviderPicker';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { usePractice } from '@bluefox/contexts';
import { twentyFourHoursFromDate } from '@bluefox/lib/maths/calculate24HoursFromDate';

type EditImmunizationModalProps = {
  immunization: Vaccination;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const EditImmunizationModal = ({
  immunization,
  open,
  onOpen,
  onClose,
}: EditImmunizationModalProps) => {
  const practice = usePractice();

  const [prescriberId, setPrescriberId] = useState<string>();
  const [providerId, setProviderId] = useState<string>();
  const [serviceDate, setServiceDate] = useState<Date | undefined>(undefined);
  const [visDate, setVisDate] = useState<Date | undefined>(undefined);
  const [route, setRoute] = useState<VaccinationRoutes>(VaccinationRoutes.IM);
  const [site, setSite] = useState<VaccinationSites>(VaccinationSites.rightArm);

  const routeIsNotINorPO =
    route !== VaccinationRoutes.IN && route !== VaccinationRoutes.PO;

  const [updateVaccination] = useMutation(UpdateVacciantion);

  const validateVaccinationChanges = useCallback((): boolean => {
    const givenAt = new Date(immunization?.givenAt);
    const visPublicationDate = new Date(immunization?.visDate);

    const conditions =
      prescriberId === immunization?.prescriber?.id &&
      providerId === immunization?.provider?.id &&
      serviceDate?.toDateString() === givenAt.toDateString() &&
      visDate?.toDateString() === visPublicationDate.toDateString() &&
      route === immunization?.route &&
      site === immunization?.site;

    return conditions;
  }, [
    immunization?.givenAt,
    immunization?.prescriber?.id,
    immunization?.provider?.id,
    immunization?.route,
    immunization?.site,
    immunization?.visDate,
    prescriberId,
    providerId,
    route,
    serviceDate,
    site,
    visDate,
  ]);

  const handleUpdateVaccination = () => {
    updateVaccination({
      variables: {
        vaccinationId: immunization.id,
        object: {
          site:
            route === VaccinationRoutes.PO || route === VaccinationRoutes.IN
              ? null
              : site,
          route,
          prescribedBy: prescriberId,
          providerId: providerId,
          givenAt: serviceDate,
          visDate: visDate,
          modified: true,
        },
      },
      refetchQueries: [
        {
          query: VaccinationsByTypeQuery,
          variables: {
            practicePatientId: immunization.practicePatient?.id,
            types: VACCINE_TYPES,
          },
        },
      ],
    })
      .then(() => {
        toast({
          title: 'Immunization updated successfully',
          type: 'success',
          time: 3000,
        });
        onClose();
        resetForm();
      })
      .catch((e) => {
        toast({
          title:
            !providerId || !prescriberId
              ? `Please select ${!providerId ? 'provider' : 'prescriber'}`
              : `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const resetForm = useCallback(() => {
    setPrescriberId(immunization?.prescriber?.id);
    setProviderId(immunization?.provider?.id);
    setRoute(immunization?.route as VaccinationRoutes);
    setSite(immunization?.site as VaccinationSites);
    setServiceDate(new Date(immunization?.givenAt));
    setVisDate(new Date(immunization.visDate));
  }, [
    immunization?.givenAt,
    immunization?.prescriber?.id,
    immunization?.provider?.id,
    immunization?.route,
    immunization?.site,
    immunization.visDate,
  ]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Modal
      onOpen={onOpen}
      onClose={() => {
        onClose();
        resetForm();
      }}
      open={open}
    >
      <Modal.Header>Edit vaccination</Modal.Header>
      <Modal.Content>
        <Form widths="equal">
          <Form.Field>
            <label>
              <b>Given by</b>
            </label>
            <ProviderPicker
              defaultValue={providerId}
              onChange={(value) => setProviderId(value)}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field>
              <label>
                <b>Prescribed by</b>
              </label>
              <ProviderPicker
                prescriber
                defaultValue={prescriberId}
                onChange={(value) => setPrescriberId(value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>
                <b>Service date</b>
              </label>
              <DateTimePicker
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                tz={practice.timezone}
                selected={serviceDate}
                onChange={(value) =>
                  setServiceDate(value ? (value as Date) : undefined)
                }
                maxDate={new Date()}
                required
                disabled={
                  immunization?.createdAt
                    ? twentyFourHoursFromDate(immunization?.createdAt)
                    : false
                }
              />
            </Form.Field>
            <Form.Field>
              <label>
                <b>VIS date</b>
              </label>
              <DateTimePicker
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                dropdownMode="select"
                tz={practice.timezone}
                selected={visDate}
                onChange={(value) => {
                  setVisDate(value ? (value as Date) : undefined);
                }}
                maxDate={new Date()}
                required
                disabled={
                  immunization?.createdAt
                    ? twentyFourHoursFromDate(immunization?.createdAt)
                    : false
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>
                <b>Route</b>
              </label>
              <VaccinationRoutePicker
                routes={
                  immunization?.vaccine?.routes?.length
                    ? immunization.vaccine.routes
                    : undefined
                }
                defaultValue={route}
                onChange={(route) => setRoute(route)}
              />
            </Form.Field>
            {routeIsNotINorPO ? (
              <Form.Field>
                <label>
                  <b>Site</b>
                </label>
                <VaccinationSitePicker
                  defaultValue={site}
                  onChange={(value) => setSite(value)}
                />
              </Form.Field>
            ) : (
              ''
            )}
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          content="Save"
          icon="save"
          disabled={validateVaccinationChanges()}
          onClick={() => {
            handleUpdateVaccination();
            resetForm();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditImmunizationModal;
